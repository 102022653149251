import React, { useEffect } from 'react';

import {
    LoadingOutlined
} from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';
import './index.scss';

import logo from '@assets/logo.svg';

function GuidePage() {
    const navigate = useNavigate();

    let navFunc = () => {
        setTimeout(() => {
            navigate("/data", { replace: true });
        }, 1000);
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navFunc();
        } else {
            navigate("/login", { replace: true });
        }
    }, []);

    return (
        <div className='first-load'>
            <img src={logo} alt="logo" />
            <h2>南海区劳动争议调解分单管理平台</h2>
            <LoadingOutlined style={{ fontSize: 40, color: '#FF5252', marginTop: '10vh' }} />
        </div>
    );
}

export default GuidePage;