import { AxiosProgressEvent } from "axios";
import { axiosInstance, CommonResponse } from ".";
import { ICompany } from "./company";

export interface Pager {
    page: number,
    size: number
}

/**
 * 字典
 */
export type IDictName = keyof IDictList

export interface IDictDataOption {
    name: string,
    value: number
}

export interface IDictData {
    type_name: string,
    options: IDictDataOption[]
}

export interface IDictList {
    global_time_type?: IDictData, // 时间段
    worksheet_question_type?: IDictData, // 问题类别
    worksheet_lawsuit_type?: IDictData, // 投诉类别
    worksheet_event_level?: IDictData, //事件等级
    worksheet_form_type?: IDictData, // 表单类型
    telent_attachment_type?: IDictData, // 认证附件集合
    worksheet_source?: IDictData, // 来源
    pre_worksheet_business?: IDictData, // 行业分类
    pre_worksheet_unit_property?: IDictData, // 单位性质
    pre_worksheet_handling_phase?: IDictData, // 承办环节
    pre_worksheet_type?: IDictData, // 分办意见
    pre_worksheet_case_category?: IDictData, // 案件类别
    mediate_attachment_tag?: IDictData, // 材料名称
    pre_worksheet_form_type?: IDictData, // 当事人
    worksheet_status?: IDictData, // 工单状态
    pre_worksheet_source_type?: IDictData, // 来源方式
    global_sex?: IDictData, // 性别
    sys_nation?: IDictData, // 民族
    order_approval_status?: IDictData, // 审核状态
    pre_worksheet_status?: IDictData, // 工单状态
    pre_worksheet_dispute_type?: IDictData, // 争议类型
    pre_worksheet_company_type?: IDictData, // 企业类型
}

/**
 * @description: 获取服务器字典列表
 * @returns {Promise}
 */
export const getDictList = (types: IDictName[]): Promise<CommonResponse<IDictList>> => {
    return axiosInstance.post('/api/shares/options', { types }).then(res => res.data);
};

export interface IWxConfig {
    appId: string,
    nonceStr: string,
    timestamp: number,
    signature: string
}

/**
 * @description: 获取微信配置
 * @returns {Promise}
 */
export const getWxConfig = (): Promise<CommonResponse<IWxConfig>> => {
    return axiosInstance.get('/api/wechat/jssdk_config').then(res => res.data);
}

export interface RegionListData {
    id: number,
    name: string,
    initial: string
}

interface RegionList {
    list: RegionListData[]
}

/**
 * @description: 获取省市区字典列表
 * @returns {Promise}
 */
export const getRegionByPid = (params: { pid?: number }): Promise<CommonResponse<RegionList>> => {
    return axiosInstance.get('/api/shares/region', { params }).then(res => res.data);
}

/**
 * 上传文件类型
 */
export type uploadTypeValues =
    'default' | // 默认
    'blacklist' | // 黑名单
    'license' | // 营业执照
    'school' | // 学校
    'idcard' | // 身份证
    'staff_import' | // 导入员工
    'bank_card' | // 银行卡
    'work_order' | // 工作工单
    'work_plan' | // 工作计划
    'customer' | // 客户
    'work_record' | // 工作汇报
    'tracking' | // 轨迹追踪
    'company_logo' | // 企业logo
    'environment' | // 企业环境
    'salary' | // 薪资
    'salary_accounting_deduct' | // 薪资扣除
    'approval' | // 审批
    'tax_sheet_undeclare' | // 未申报薪资列表
    'staff_holiday' | // 年假
    'adviser_logo' |// 头像
    'order' | // 业务订单
    'pre_worksheet' | // 附件
    'adviser_qualification'; // 资质

export interface FileUpload {
    full_link: string,
    link: string,
    file_id: number,
    origin_name?: string,
    local_path?: string
}

/**
 * @description: 上传文件
 * @returns {Promise}
 */
export const uploadFile = ({ file, type, uploadProgressCallback }: { file: Blob, type: uploadTypeValues, uploadProgressCallback?: (progressEvent: AxiosProgressEvent) => void }): Promise<CommonResponse<FileUpload>> => {
    let form = new FormData();
    form.append('file', file);
    form.append('type', type);
    return axiosInstance.post('/api/shares/file_upload', form, { onUploadProgress: uploadProgressCallback }).then(res => res.data);
}

export interface IdcardOcr {
    name?: string,
    sex?: number,
    sex_code?: number,
    nation?: string,
    nation_dict?: number,
    birth?: string,
    address?: string,
    idcard?: string,
    authority?: string,
    valid_date_start?: string,
    valid_date_end?: string
    is_idcard_long_time: false,
    age?: number
}

/**
 * 身份证识别
 * @param params 
 * @returns 
 */
export const getIdcardOcrData = (data: { fid: number }): Promise<CommonResponse<IdcardOcr>> => {
    return axiosInstance.post('/api/ocr/scan', {
        file_id: data.fid,
        type: 'idCard'
    }).then(res => res.data);
}


interface IBizLicenseOcr {
    social_no: string,
    name: string,
    registered_capital: string,
    legal_person: string,
    address: string,
    business_scope: string,
    enterprise_type: string,
    business_term: string,
    register_date: string
}

/**
 * 营业执照识别
 * @param params 
 * @returns 
 */
export const getBizLicenseData = (data: { fid: number }): Promise<CommonResponse<IBizLicenseOcr>> => {
    return axiosInstance.post('/api/ocr/scan', { file_id: data.fid, type: 'bizLicense' }).then(res => res.data);
}

export interface BankCardOcr {
    bank_no: string,
    bank_name: string
}

/**
 * 银行卡识别
 * @param params 
 * @returns 
 */
export const getBankCardOcrData = (params: { fid: number }): Promise<CommonResponse<BankCardOcr>> => {
    return axiosInstance.get('/manage/ocr/scan',
        {
            params: {
                ...params,
                type: "bankCard"
            }
        }).then(res => res.data);
}

export interface IAuthNode {
    id: number,
    pid: number,
    name: string,
    sign: string,
    children: IAuthNode[]
}

export interface IAuthData {
    role: {
        id: number,
        name: string
    },
    node: IAuthNode[]
}

export interface IAuthRes {
    list: IAuthData
}

/**
 * 用户权限
 * @returns 
 */
export const getAuthData = (): Promise<CommonResponse<IAuthRes>> => {
    return axiosInstance.get('/order/perm/auth').then(res => res.data);
}

