import { Button, Grid, List, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { downloadPreArbitrationTable, getPreArbitrationTables, IPreArbitrationTablesData } from '../../../apis/preArbitration';
import LaborDisputeHandlingTableFormModal from './laborDisputeHandlingTable';
import SocialIssuesRegistryTableFormModal from './socialIssuesRegistryTable';

const { useBreakpoint } = Grid;

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function PreArbitrationTablesModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<IPreArbitrationTablesData[]>([]);

    useEffect(() => {
        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            setLoading(true);
            getPreArbitrationTables({ pre_worksheet_id }).then(res => {
                setList(res.data.list);
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [data, searchParams]);

    // 跳转
    const toTable = (type: number) => {
        switch (type) {
            case 1:
                toEditFileIndex();
                break;
            case 2:
                toEditCover();
                break;
            case 3:
                toEditLaborDisputeHandlingTable();
                break;
            case 4:
                toEditLaborDisputeRecord();
                break;
            case 5:
                toEditLaborDisputeMediationApplication();
                break;
            case 6:
                toEditLaborDisputeMediationAgreement();
                break;
            case 7:
                toEditPowerOfAttorney();
                break;
            case 8:
                break;
            case 9:
                toEditCaseBackCover();
                break;
            case 10:
                toEditProofOfDelivery();
                break;
            case 11:
                toEditSocialIssuesRegistry();
                break;
        }
    }

    const toDownload = (type: number) => {
        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        downloadPreArbitrationTable({ pre_worksheet_id, form_type: type }).then(res => {
            window.open(res.data.full_link, '_blank');
        });
    }

    // 案卷封面
    const [toCoverModal, setToCoverModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toEditCover = () => {
        let id = data?.id ?? Number(searchParams.get('id'));
        if (screens.xs) {
            navigate(`/preArbitration/caseFrontCover?id=${id}`);
        } else {
            setToCoverModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onCoverClose = () => {
        setToCoverModal({
            ...toCoverModal!,
            isModalVisible: false
        })
    };

    // 劳动人事争议调解登记处理表
    const [toLaborDisputeHandlingTableModal, setToLaborDisputeHandlingTableModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toEditLaborDisputeHandlingTable = () => {
        let id = data?.id ?? Number(searchParams.get('id'));
        if (screens.xs) {
            navigate(`/preArbitration/laborDisputeHandling?id=${id}`);
        } else {
            setToLaborDisputeHandlingTableModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onLaborDisputeHandlingTableClose = () => {
        setToLaborDisputeHandlingTableModal({
            ...toLaborDisputeHandlingTableModal!,
            isModalVisible: false
        })
    };

    // 卷内目录
    const [toFileIndexModal, setToFileIndexModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toEditFileIndex = () => {
        let id = data?.id ?? Number(searchParams.get('id'));
        if (screens.xs) {
            navigate(`/preArbitration/fileIndex?id=${id}`);
        } else {
            setToFileIndexModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onFileIndexClose = () => {
        setToFileIndexModal({
            ...toFileIndexModal!,
            isModalVisible: false
        })
    };


    // 劳动人事争议调解情况记录（双面）
    const [toLaborDisputeRecordTableModal, setToLaborDisputeRecordTableModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toEditLaborDisputeRecord = () => {
        let id = data?.id ?? Number(searchParams.get('id'));
        if (screens.xs) {
            navigate(`/preArbitration/laborDisputeRecord?id=${id}`);
        } else {
            setToLaborDisputeRecordTableModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onLaborDisputeRecordClose = () => {
        setToLaborDisputeRecordTableModal({
            ...toLaborDisputeRecordTableModal!,
            isModalVisible: false
        })
    };

    // 劳动人事争议调解申请书
    const [toLaborDisputeMediationApplicationTableModal, setToLaborDisputeMediationApplicationTableModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toEditLaborDisputeMediationApplication = () => {
        let id = data?.id ?? Number(searchParams.get('id'));
        if (screens.xs) {
            navigate(`/preArbitration/laborDisputeMediationApplication?id=${id}`);
        } else {
            setToLaborDisputeMediationApplicationTableModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onLaborDisputeMediationApplicationClose = () => {
        setToLaborDisputeMediationApplicationTableModal({
            ...toLaborDisputeMediationApplicationTableModal!,
            isModalVisible: false
        })
    };

    // 劳动人事争议调解协议书
    const [toLaborDisputeMediationAgreementTableModal, setToLaborDisputeMediationAgreementTableModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toEditLaborDisputeMediationAgreement = () => {
        let id = data?.id ?? Number(searchParams.get('id'));
        if (screens.xs) {
            navigate(`/preArbitration/laborDisputeMediationAgreement?id=${id}`);
        } else {
            setToLaborDisputeMediationAgreementTableModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onLaborDisputeMediationAgreementClose = () => {
        setToLaborDisputeMediationAgreementTableModal({
            ...toLaborDisputeMediationAgreementTableModal!,
            isModalVisible: false
        })
    };

    // 授权委托书
    const [toPowerOfAttorneyTableModal, setToPowerOfAttorneyTableModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toEditPowerOfAttorney = () => {
        let id = data?.id ?? Number(searchParams.get('id'));
        if (screens.xs) {
            navigate(`/preArbitration/powerOfAttorney?id=${id}`);
        } else {
            setToPowerOfAttorneyTableModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onPowerOfAttorneyClose = () => {
        setToPowerOfAttorneyTableModal({
            ...toPowerOfAttorneyTableModal!,
            isModalVisible: false
        })
    };

    // 案卷封底
    const [toCaseBackCoverTableModal, setToCaseBackCoverTableModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toEditCaseBackCover = () => {
        let id = data?.id ?? Number(searchParams.get('id'));
        if (screens.xs) {
            navigate(`/preArbitration/caseBackCover?id=${id}`);
        } else {
            setToCaseBackCoverTableModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onCaseBackCoverClose = () => {
        setToCaseBackCoverTableModal({
            ...toCaseBackCoverTableModal!,
            isModalVisible: false
        })
    };

    // 送达回证
    const [toProofOfDeliveryTableModal, setToProofOfDeliveryTableModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toEditProofOfDelivery = () => {
        let id = data?.id ?? Number(searchParams.get('id'));
        if (screens.xs) {
            navigate(`/preArbitration/proofOfDelivery?id=${id}`);
        } else {
            setToProofOfDeliveryTableModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onProofOfDeliveryClose = () => {
        setToProofOfDeliveryTableModal({
            ...toProofOfDeliveryTableModal!,
            isModalVisible: false
        })
    };

    // 涉人社领域问题登记表
    const [toSocialIssuesRegistryTableModal, setToSocialIssuesRegistryTableModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toEditSocialIssuesRegistry = () => {
        let id = data?.id ?? Number(searchParams.get('id'));
        if (screens.xs) {
            navigate(`/preArbitration/socialIssuesRegistry?id=${id}`);
        } else {
            setToSocialIssuesRegistryTableModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onSocialIssuesRegistryClose = () => {
        setToSocialIssuesRegistryTableModal({
            ...toSocialIssuesRegistryTableModal!,
            isModalVisible: false
        })
    };

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={list}
                renderItem={item => {
                    let bottoms = [
                        <Button type='link' size='small' onClick={() => toDownload(item.type)}>下载</Button>,
                    ]

                    if ([3, 11].includes(item.type)) {
                        bottoms.unshift(
                            <Button type='link' size='small' onClick={() => toTable(item.type)}>编辑</Button>,
                        );
                    }

                    return (
                        <List.Item
                            actions={bottoms}
                        >
                            <List.Item.Meta title={item.title} />
                        </List.Item>
                    );
                }} />

            {/* 劳动人事争议调解登记处理表 */}
            {
                toLaborDisputeHandlingTableModal ? <Modal
                    open={toLaborDisputeHandlingTableModal.isModalVisible}
                    title={`劳动人事争议调解登记处理表`}
                    width={1200}
                    maskClosable={false}
                    onCancel={onLaborDisputeHandlingTableClose}
                    footer={null}
                >
                    <LaborDisputeHandlingTableFormModal data={toLaborDisputeHandlingTableModal.data} onFinish={onLaborDisputeHandlingTableClose}></LaborDisputeHandlingTableFormModal>
                </Modal> : null
            }

            {/* 涉人社领域问题登记表 */}
            {
                toSocialIssuesRegistryTableModal ? <Modal
                    open={toSocialIssuesRegistryTableModal.isModalVisible}
                    title={`涉人社领域问题登记表`}
                    width={1200}
                    maskClosable={false}
                    onCancel={onSocialIssuesRegistryClose}
                    footer={null}
                >
                    <SocialIssuesRegistryTableFormModal
                        data={toSocialIssuesRegistryTableModal.data}
                        onFinish={onSocialIssuesRegistryClose}
                    ></SocialIssuesRegistryTableFormModal>
                </Modal> : null
            }
        </div>
    );
};

export default PreArbitrationTablesModal;