import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalCountDownSec } from '../../utils/countdown';

// apis
import { changePassword, codeType, IResetPasswordForm, sendVCode } from '../../apis/user';

// antd
import { Button, Form, message, Space, Input, Modal, Row, Col } from 'antd';


function ChangePasswordFormPage() {
    // 路由
    const navigate = useNavigate();

    const [form] = Form.useForm<IResetPasswordForm>();

    // 修改密码
    const [submitLoading, setSubmitLoading] = useState(false);
    const submit = async () => {
        await form.validateFields();

        let rawForm = form.getFieldsValue(true);
        try {
            setSubmitLoading(true);
            let res = await changePassword(rawForm);
            message.success(res.msg);
            localStorage.removeItem('token');
            localStorage.removeItem('account');
            navigate("/login", { replace: true });
            Modal.info({
                title: '提示',
                content: '修改密码后需要重新登录',
            });
        } catch (e) {
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }

    }

    return (
        <div className='menu inner-page'>
            <div className='menu__title'>
                <div>修改密码</div>
            </div>

            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Row>
                    <Col sm={24} md={11}>
                        <Form.Item
                            name="old_password"
                            label="旧密码"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入旧密码'
                                },
                            ]}
                        >
                            <Input.Password maxLength={20} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="新密码"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入新密码'
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || value.length >= 6) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('密码长度不得小于6位'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password maxLength={20} />
                        </Form.Item>

                        <Form.Item
                            name="confirm_password"
                            label="再次输入密码"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: '请再次输入密码',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('两次输入的密码不同'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Space>
                                <Button type='primary' onClick={submit} loading={submitLoading}>提交表单</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div >
    );
}


export default ChangePasswordFormPage;