import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

// antd
import { Grid, Button, Form, Input, message, Select, Skeleton, Descriptions, Row, Col, Space, Divider, InputRef, Card } from 'antd';
import { DatePicker as DatePickerMobile } from 'antd-mobile';
import DatePicker from '../../../components/customAntd/DatePicker';
import { PickerDate } from 'antd-mobile/es/components/date-picker/util';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { getPreArbitrationTableContent, IFileIndexTableForm, IFileIndexTableListData, ILaborDisputeRecordTableData, ILaborDisputeRecordTableForm, IPreArbitrationCoverTableData, IPreArbitrationCoverTableForm, IProofOfDeliveryTableData, IProofOfDeliveryTableForm, IProofOfDeliveryTableFormDeliveryData, ISocialIssuesRegistryTableData, ISocialIssuesRegistryTableForm, saveFileIndexTable, saveLaborDisputeRecordTable, savePreArbitrationCoverTable, saveProofOfDeliveryTable, saveSocialIssuesRegistryTable } from '../../../apis/preArbitration';
import { getDictList, IDictData } from '../../../apis/common';
import TextArea from 'antd/lib/input/TextArea';

const { useBreakpoint } = Grid;
const { Option } = Select;

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function SocialIssuesRegistryTableFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<ISocialIssuesRegistryTableForm>();

    // 收到日期
    const [receiveAtVisible, setReceiveAtVisible] = useState(false);

    // 初始化加载
    const [loading, setLoading] = useState(true);

    const [sex, setSex] = useState<IDictData>();
    useEffect(() => {
        getDictList(['global_sex']).then(res => {
            setSex(res.data.global_sex);
        });
    }, []);

    useEffect(() => {
        form.resetFields();

        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        if (pre_worksheet_id) {
            form.setFieldValue('pre_worksheet_id', pre_worksheet_id);
            setLoading(true);

            getPreArbitrationTableContent({
                pre_worksheet_id,
                form_type: 11,
            }).then(res => {
                console.log(res);
                let data: ISocialIssuesRegistryTableData = res.data.detail!;

                form.setFieldsValue({
                    ...data,
                    register_date: data.register_date ? dayjs(data.register_date) as any : undefined,
                });
            }).finally(() => {
                setLoading(false);
            })
        }

    }, [data, searchParams]);

    // 提交
    const submit = async () => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }

        setConfirmLoading(true);
        let _form = form.getFieldsValue(true);
        try {
            let data = JSON.parse(JSON.stringify(_form));
            data.people_number = data.people_data.length;
            if (data.register_date) data.register_date = dayjs(data.register_date).format('YYYY-MM-DD');

            let res = await saveSocialIssuesRegistryTable(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate(-1);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            {
                screens.xs ?
                    <Form.Item
                        label="登记日期"
                        shouldUpdate={(prevValues, curValues) => prevValues.register_date !== curValues.register_date}
                    >
                        <DatePickerMobile
                            visible={receiveAtVisible}
                            onClose={() => {
                                setReceiveAtVisible(false)
                            }}
                            value={(form.getFieldValue("register_date") as Dayjs)?.toDate()}
                            onConfirm={(value: PickerDate) => {
                                form.setFieldValue("register_date", dayjs(value));
                            }}
                        >
                            {value =>
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => {
                                            setReceiveAtVisible(true)
                                        }}
                                    >选择</Button>
                                    {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                </>
                            }
                        </DatePickerMobile>
                    </Form.Item>
                    : <Form.Item
                        label="登记日期"
                        name="register_date"
                    >
                        <DatePicker
                            format='YYYY年MM月DD日'
                        />
                    </Form.Item>
            }

            <Form.List
                name="people_data"
                rules={[
                    {
                        validator: async (_, names) => {
                            if (!names || names.length < 1) {
                                return Promise.reject(new Error('请至少添加一项'));
                            }
                        },
                    },
                ]}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Card>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3>({index + 1})</h3>
                                    <Button
                                        type='text'
                                        size='small'
                                        danger
                                        onClick={() => remove(name)}
                                    >删除</Button>
                                </div>
                                <Row gutter={gutter}>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="姓名"
                                            name={[name, 'real_name']}
                                        >
                                            <Input maxLength={100} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="性别"
                                            name={[name, 'sex']}
                                        >
                                            <Select showArrow showSearch>
                                                {sex?.options.map(option => (
                                                    <Option key={option.value} value={option.name}>{option.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="身份证号码"
                                            name={[name, 'idcard']}
                                        >
                                            <Input maxLength={18} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="联系电话"
                                            name={[name, 'mobile']}
                                        >
                                            <Input maxLength={20} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            {...restField}
                                            label="法定文书送达地址"
                                            name={[name, 'delivery_address']}
                                        >
                                            <Input maxLength={100} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加记录
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Divider>主要问题及诉求</Divider>
            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="用人单位"
                        name='company_name'
                    >
                        <Input maxLength={100} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="单位负责人"
                        name='legal_person'
                    >
                        <Input maxLength={10} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="单位地址"
                        name='address'
                    >
                        <Input maxLength={100} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="联系电话"
                        name='mobile'
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="主要问题及诉求"
                        name="reason"
                    >
                        <TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
            </Form.Item>
        </Form>
    )
    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : <>
                        {FormItem}
                    </>
            }
        </div>
    );
}

export default SocialIssuesRegistryTableFormModal;