import { useNavigate } from 'react-router';

// antd
import { Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

// apis
// import { useRevokeEntryApplyMutation } from '../../../store/apis/user';

// const { confirm } = Modal;

/**
 * 注册员工页
 */
function RegisterStaffPending() {
    // 路由
    const navigate = useNavigate();

    // 提交撤销
    // const [submitTrigger, submitResult] = useRevokeEntryApplyMutation();
    // useEffect(() => {
    //     if (submitResult.isSuccess) {
    //         message.success(submitResult.data.msg);
    //         navigate('/login', { replace: true });
    //     } else if (submitResult.isError) {
    //         navigate('/login', { replace: true });
    //     }
    // }, [submitResult]);
    // const onSubmit = () => {
    //     confirm({
    //         title: '提示',
    //         icon: <ExclamationCircleOutlined />,
    //         content: '确定要撤销审核吗？',
    //         onOk() {
    //             // submitTrigger();
    //         },
    //     });
    // };

    return (
        // 流式布局
        <div style={{ color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: 150, marginBottom: 70 }}>
                <CheckCircleOutlined size={200} />
            </div>
            <div style={{ fontSize: 24 }}>已提交认证申请</div>
            <div style={{ marginTop: 10, color: '#cccccc' }}><Button type="link" size='small' onClick={() => navigate('/login', { replace: true })}>返回登录页</Button></div>
            {/* <Button type="primary" danger size='large' style={{ marginTop: 200 }} onClick={onSubmit}>
                撤销审核
            </Button> */}
        </div>
    );
}

export default RegisterStaffPending;