import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Form, message, Divider, Skeleton } from 'antd';

import { uploadFile } from '../../../apis/common';

import { ImageUploader, ImageUploadItem } from 'antd-mobile';

import { getAttachmentTagByPId, getPreArbitrationDetail, IAttachFilesData, IReplenishAttachmentForm, replenishAttachment } from '../../../apis/preArbitration';

const { useBreakpoint } = Grid;

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function PreArbitrationAttachmentFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IReplenishAttachmentForm>();

    // 初始化加载
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.resetFields();

        let id = data?.id ?? Number(searchParams.get('id'));
        form.setFieldValue('pre_worksheet_id', id);

        getPreArbitrationDetail({ id }).then(res => {
            form.setFieldValue('attach_files_data', res.data.detail.attach_files_data);
            setFileListMap(() => {
                let map: Record<number, ImageUploadItem[]> = {};
                res.data.detail.attach_files_data_info.forEach(item => {
                    map[item.value] = item.files_info.map(file => (
                        {
                            key: file.id,
                            extra: {
                                name: file.attach_name,
                                value: file.id,
                                data: file
                            },
                            url: file.attach_url_link,
                        }
                    ))
                })
                return map;
            })
        }).finally(() => {
            setLoading(false);
        });
    }, [data, searchParams]);

    // 上传附件
    const [fileListMap, setFileListMap] = useState<Record<number, ImageUploadItem[]>>({});
    const beforeUpload = (file: File) => {
        if (file.size > 1024 * 1024 * 50) {
            message.warning('请选择小于 50M 的图片');
            return null;
        }
        return file;
    }
    const imageUploaderUpload = (name: string, value: number) => {
        return async (file: File) => {
            let res = await uploadFile({ file: file as Blob, type: 'pre_worksheet' });
            return {
                key: res.data.file_id,
                extra: {
                    name,
                    value,
                    data: res.data
                },
                url: res.data.full_link,
            };
        }
    };
    const onImageUploaderChange = (value: number) => {
        return (items: ImageUploadItem[]) => {
            let attachFilesData: IAttachFilesData[] = form.getFieldValue('attach_files_data');
            attachFilesData.find(item => item.value === value)!.files = items.map(item => item.key as number);
            form.setFieldValue('attach_files_data', attachFilesData);
            setFileListMap(data => {
                data[value] = items;
                return data;
            });
        };
    };

    // 提交
    const submit = async () => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }
        let _form: IReplenishAttachmentForm = form.getFieldsValue(true);
        setConfirmLoading(true);
        try {
            let data = {
                ..._form,
            }

            let res = await replenishAttachment(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate('/preArbitration');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };


    // style
    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item
                noStyle
                shouldUpdate
            >
                {({ getFieldValue }) => {
                    let attachFilesData: IAttachFilesData[] = getFieldValue('attach_files_data') ?? [];
                    return (
                        <>
                            {
                                attachFilesData.map(item => (
                                    <Form.Item
                                        label={item.name}
                                    >
                                        <ImageUploader
                                            multiple
                                            value={fileListMap[item.value] ?? []}
                                            onChange={onImageUploaderChange(item.value)}
                                            upload={imageUploaderUpload(item.name, item.value)}
                                            beforeUpload={beforeUpload}
                                        />
                                    </Form.Item>
                                ))
                            }
                        </>
                    );
                }}
            </Form.Item>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
            </Form.Item>
        </Form>
    )


    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : FormItem
            }
        </div>
    );
}

export default PreArbitrationAttachmentFormModal;