import { useEffect, useState } from 'react';

// antd
import { Button, Col, Divider, Form, Input, message, Row, Select, Grid, Cascader, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Cascader as CascaderMobile, CascaderOption } from 'antd-mobile';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { UploadRequestOption } from "rc-upload/lib/interface";
import { UploadOutlined } from '@ant-design/icons';

// interface
import { AddInformation, getSelectOrgan, getSelectRegion, IAddInformationForm, ISelectOrganData, ISelectOrganForm, ISelectRegionData } from '../../../apis/order';
import DatePicker from '../../../components/customAntd/DatePicker';
import { FileUpload, getDictList, IDictData, uploadFile } from '../../../apis/common';
import dayjs from 'dayjs';
import { Attachment } from '../../../apis/attachment';
import { useNavigate } from 'react-router-dom';

interface CollectionCreateFormProps {
    onFinish?: () => void;
}

const { Option } = Select;
const { useBreakpoint } = Grid;

let tempFileList: UploadFile<any>[] = [];

function AddInfoFormPage({
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IAddInformationForm>();

    // 提交
    const submit = async () => {
        let _form = form.getFieldsValue(true);

        await form.validateFields();

        if (!_form.cur_id || _form.cur_id.length !== 4) {
            message.warning('地址请选择完整');
            return;
        }

        setConfirmLoading(true);
        try {
            let res = await AddInformation({
                ..._form,
                event_begin_at: _form.event_begin_at ? (_form.event_begin_at as dayjs.Dayjs).format('YYYY-MM-DD HH:mm:ss') : undefined,
                assigned_at: (_form.assigned_at as dayjs.Dayjs).format('YYYY-MM-DD HH:mm:ss'),
                attach_files: attachments.length > 0 ? attachments
                    .filter(file => file.response)
                    .map(file => {
                        let res: FileUpload | Attachment = file.response;
                        return (res as FileUpload).file_id || (res as Attachment).id;
                    }) : undefined,
            });
            message.success(res.msg);
            form.resetFields();
            if (onFinish) {
                onFinish();
            } else {
                navigate('/order');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 可选街道
    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }
    const [options, setOptions] = useState<Option[]>([]);
    useEffect(() => {
        let nodes: ISelectRegionData[] = [];
        const build = (list: ISelectRegionData[]): Option[] => {
            return list.map(item => ({
                label: item.name,
                value: item.id,
                children: item.children.length > 0 ? build(item.children) : undefined
            }));
        }
        getSelectRegion().then(res => {
            nodes.push(...res.data.list);
            setOptions(build(nodes));
        });
    }, []);
    type CascaderValueExtend = {
        items: (CascaderOption | null)[]
        isLeaf: boolean
    }
    const onCascaderMobileConfirm = (value: string[], extend: CascaderValueExtend) => {
        if (extend.items.length !== 4) {
            message.warning('地址请选择完整');
            return;
        }

        onCascaderChange(extend.items.map(item => Number(item!.value)));
    }
    const onCascaderChange = (value: any[]) => {
        let location = {
            province_id: value[0],
            city_id: value[1],
            area_id: value[2],
            street_id: value[3],
        };
        form.setFieldsValue(location);
        loadOrgan(location);
    }

    // 可选机构
    const [organ, setOrgan] = useState<ISelectOrganData[]>([]);
    const loadOrgan = (data: ISelectOrganForm) => {
        form.resetFields(['organ_id']);
        getSelectOrgan(data).then(res => {
            setOrgan(res.data.list);
        })
    }

    // 问题类别
    const [questionType, setQuestionType] = useState<IDictData>();
    // 投诉类别
    const [lawsuitType, setLawsuitType] = useState<IDictData>();
    // 事件等级
    const [levelType, setLevelType] = useState<IDictData>();
    // 表单类型
    const [formType, setFormType] = useState<IDictData>();
    useEffect(() => {
        getDictList(['worksheet_lawsuit_type', 'worksheet_question_type', 'worksheet_event_level', 'worksheet_form_type']).then(res => {
            setLawsuitType(res.data.worksheet_lawsuit_type);
            setQuestionType(res.data.worksheet_question_type);
            setLevelType(res.data.worksheet_event_level);
            setFormType(res.data.worksheet_form_type);
        });
    }, []);

    // 上传统一方法
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }

        return true;
    };
    const customRequest = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file, onProgress } = options;
        uploadFile({ file: file as Blob, type: 'default' })
            .then(res => {
                onSuccess!(res.data);
            });
    }
    // 上传附件
    const [attachments, setAttachments] = useState<UploadFile<any>[]>([]);
    const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
        console.log(info.fileList, attachments);
        if (info.fileList.length !== tempFileList.length) {
            tempFileList = [...info.fileList];
        } else if (info.file.status === 'done') {
            tempFileList = [info.file, ...tempFileList.filter((item) => item.uid !== info.file.uid)];
        }
        setAttachments(tempFileList);
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload | Attachment = file.response;
        window.open((res as FileUpload).full_link || (res as Attachment).attach_url_link, "_blank");
    }


    const gutter = 16 + 8 * 2;

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="选择问题类别"
                            name="question_type"
                            rules={[{ required: true, message: '请选择问题类别' }]}
                        >
                            <Select showArrow>
                                {
                                    questionType?.options.map(item => (
                                        <Option key={item.value} value={item.value}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="选择投诉类别"
                            name="lawsuit_type"
                            rules={[{ required: true, message: '请选择投诉类别' }]}
                        >
                            <Select showArrow>
                                {
                                    lawsuitType?.options.map(item => (
                                        <Option key={item.value} value={item.value}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="选择处理街道"
                            name="cur_id"
                            rules={[{ required: true, message: '请选择处理街道' }]}
                            trigger={screens.xs ? 'onConfirm' : undefined}
                        >
                            {
                                screens.xs
                                    ? <CascaderMobile
                                        options={options as any}
                                        onConfirm={onCascaderMobileConfirm}
                                    >
                                        {(items, actions) => (
                                            <>
                                                <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                                {
                                                    items.every(item => item === null)
                                                        ? '请选择处理街道'
                                                        : items.map(item => item?.label ?? '请选择处理街道').join('-')
                                                }
                                            </>
                                        )}
                                    </CascaderMobile>
                                    : <Cascader options={options} placeholder="请选择处理街道" onChange={onCascaderChange} />
                            }
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="选择服务商"
                            name="organ_id"
                        >
                            <Select showArrow>
                                {
                                    organ?.map(item => (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation='left'>登记内容</Divider>

                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                        <Form.Item
                            label="姓名"
                            name="name"
                            rules={[{ required: true, message: '请输入姓名' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="性别"
                            name="sex"
                            rules={[{ required: true, message: '请选择性别' }]}
                        >
                            <Select showArrow>
                                <Option key={1} value={1}>男性</Option>
                                <Option key={2} value={2}>女性</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="事件等级"
                            name="event_level"
                            rules={[{ required: true, message: '请选择事件等级' }]}
                        >
                            <Select showArrow>
                                {
                                    levelType?.options.map(item => (
                                        <Option key={item.value} value={item.value}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                        <Form.Item
                            label="来电号码"
                            name="telephone_number"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="身份证号码"
                            name="idcard"
                        >
                            <Input maxLength={18} />
                        </Form.Item>
                        <Form.Item
                            label="事项发生时间（对应投诉单以及举报单的事项发生时间）"
                            name="event_begin_at"
                        >
                            <DatePicker showTime style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                        <Form.Item
                            label="联系电话"
                            name="mobile"
                            rules={[{ required: true, message: '请输入联系电话' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="表单类型"
                            name="form_type"
                            rules={[{ required: true, message: '请选择表单类型' }]}
                        >
                            <Select showArrow>
                                {
                                    formType?.options.map(item => (
                                        <Option key={item.value} value={item.value}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="交办时间（派至一级账号的时间）"
                            name="assigned_at"
                            rules={[{ required: true, message: '请选择交办时间' }]}
                        >
                            <DatePicker showTime style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="标题"
                            name="title"
                            rules={[{ required: true, message: '请输入标题' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="是否有附件"
                            name="has_attachment"
                            rules={[{ required: true, message: '请选择是否有附件' }]}
                        >
                            <Select showArrow>
                                <Option key={'是'} value={'是'}>是</Option>
                                <Option key={'否'} value={'否'}>否</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    label="附件上传"
                    name='attach_files'
                >
                    <Upload
                        multiple
                        fileList={attachments}
                        beforeUpload={checkFileSize}
                        onChange={onFileChange}
                        customRequest={customRequest}
                        showUploadList={{ showDownloadIcon: true }}
                        onDownload={onAttachmentDownload}
                    >
                        <Button icon={<UploadOutlined />}>点击上传附件</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="问题描述"
                    name='describe'
                    rules={[{ required: true, message: '请输入问题描述' }]}

                >
                    <TextArea rows={2} placeholder="请填写问题描述" />
                </Form.Item>

                <Form.Item
                    label="求助事项地址"
                    name="event_address"
                    rules={[{ required: true, message: '请输入求助事项地址' }]}

                >
                    <Input />
                </Form.Item>

                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="被诉主体"
                            name="sued_company_name"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="被诉主体地址"
                            name="sued_company_address"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="时限要求"
                            name="time_limit"
                            rules={[{ required: true, message: '请输入时限要求' }]}

                        >
                            <TextArea rows={2} />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="派发意见"
                            name='dispatch_opinion'
                            rules={[{ required: true, message: '请输入派发意见' }]}
                        >
                            <TextArea rows={2} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={gutter}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="补充信息（催/补/撤）"
                            name='supplement_info'
                        >
                            <TextArea rows={2} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                            label="处理意见"
                            name='handle_opinion'
                        >
                            <TextArea rows={2} />
                        </Form.Item>
                    </Col>
                </Row>

                <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
            </Form>
        </div>
    );
}

export default AddInfoFormPage;