import { ChangeEvent, useEffect, useState } from 'react';
import React from 'react';
import Compressor from 'compressorjs';

// antd
import { Grid, Spin, Form, Button, Divider, Row, Col, Input, Select, Radio, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import DatePicker from '../../../components/customAntd/DatePicker';

// interface
import { IPreArbitrationCompany, IThirdPartyCompany } from '../../../apis/preArbitration';
import { getBizLicenseData, getDictList, IDictData, uploadFile } from '../../../apis/common';

// assets
import licenseImg from '@assets/images/register/license.png';
import { assert } from 'console';

const { Option } = Select;
const { useBreakpoint } = Grid;

export interface IPreArbitrationCompanyForm extends IPreArbitrationCompany {
    index?: number,
    business_license_attach_link?: string,
}

export interface IThirdPartyCompanyForm extends IThirdPartyCompany {
    index?: number,
    company: IPreArbitrationCompanyForm
}

interface CollectionCreateFormProps {
    formName: string,
    data?: IPreArbitrationCompanyForm | IThirdPartyCompanyForm,
    thirdParty?: boolean
}

function PreArbitrationCompanyFormModal({
    formName,
    thirdParty,
    data
}: CollectionCreateFormProps) {
    const [form] = Form.useForm<IPreArbitrationCompanyForm>();
    useEffect(() => {
        form.resetFields();
        if (data) {
            if (thirdParty) {
                form.setFieldsValue({
                    ...(data as IThirdPartyCompanyForm).company,
                    index: (data as IThirdPartyCompanyForm).index
                });
                if ((data as IThirdPartyCompanyForm).company.business_license_attach_link) {
                    setBusinessLicenseImg((data as IThirdPartyCompanyForm).company.business_license_attach_link);
                }
            } else {
                form.setFieldsValue((data as IPreArbitrationCompanyForm));
                if ((data as IPreArbitrationCompanyForm).business_license_attach_link) {
                    setBusinessLicenseImg((data as IPreArbitrationCompanyForm).business_license_attach_link);
                }
            }
        }
    }, [data]);

    const [companyType, setCompanyType] = useState<IDictData>();
    const [business, setBusiness] = useState<IDictData>();
    const [unitProperty, setUnitProperty] = useState<IDictData>();
    useEffect(() => {
        getDictList(['pre_worksheet_business', 'pre_worksheet_unit_property', 'pre_worksheet_company_type']).then(res => {
            setBusiness(res.data.pre_worksheet_business);
            setUnitProperty(res.data.pre_worksheet_unit_property);
            setCompanyType(res.data.pre_worksheet_company_type);
        });
    }, []);

    // 上传营业执照
    const [inputBusinessLicenseLoading, setInputBusinessLicenseLoading] = useState(false);
    const inputBusinessLicenseRef = React.createRef<HTMLInputElement>();
    const [businessLicenseImg, setBusinessLicenseImg] = useState<string>();
    const uploadBusinessLicense = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputBusinessLicenseLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'license' }).then(res => {
                    if ('data' in res) {
                        setBusinessLicenseImg(res.data.full_link);
                        form.setFieldValue('business_license_attach', res.data.file_id);
                        form.setFieldValue('business_license_attach_link', res.data.full_link);

                        // ocr
                        getBizLicenseData({ fid: res.data.file_id }).then(res => {
                            let data = res.data;
                            form.setFieldsValue({
                                social_no: data.social_no,
                                company_name: data.name,
                                legal_person: data.legal_person,
                                address: data.address,
                            });
                        });
                    }
                    setInputBusinessLicenseLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputBusinessLicenseLoading(false);
            },
        });
    };

    // style
    const gutter = 16 + 8 * 2;

    return (
        <Form
            form={form}
            layout="vertical"
            name={formName}
            initialValues={{
                is_default: false
            }}
        >
            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Spin spinning={inputBusinessLicenseLoading}>
                        <img
                            src={businessLicenseImg ?? licenseImg}
                            style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                            alt="营业执照上传"
                            onClick={() => inputBusinessLicenseRef.current?.click()}
                        />
                    </Spin>

                    <input
                        ref={inputBusinessLicenseRef}
                        style={{ display: 'none' }}
                        type="file"
                        accept='image/jpeg,image/jpg,image/png'
                        onChange={uploadBusinessLicense}
                    />
                </Col>

                <Divider></Divider>

                <Col xs={24}>
                    <Form.Item
                        label="企业类型"
                        name="company_type"
                        rules={[{ required: true, message: '请选择企业类型' }]}
                    >
                        <Select showArrow showSearch>
                            {companyType?.options.map(option => (
                                <Option key={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24}>
                    <Form.Item
                        label="用人单位"
                        name="company_name"
                        rules={[{ required: true, message: '请输入用人单位' }]}
                    >
                        <Input maxLength={100} />
                    </Form.Item>
                </Col>

                <Col xs={24}>
                    <Form.Item
                        label="用人地址"
                        name='address'
                        rules={[{ required: true, message: '请输入用人地址' }]}
                    >
                        <TextArea rows={1} />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="法人"
                        name="legal_person"
                    >
                        <Input maxLength={10} />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="联系人"
                        name="contact_name"
                    >
                        <Input maxLength={10} />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="联系电话"
                        name="contact_mobile"
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>

                <Col xs={24}>
                    <Form.Item
                        label="统一社会信用代码"
                        name="social_no"
                    >
                        <Input maxLength={18} />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="行业分类"
                        name="business_dict"
                    >
                        <Select showArrow showSearch>
                            {business?.options.map(option => (
                                <Option key={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="单位性质"
                        name="unit_property_dict"
                    >
                        <Select showArrow showSearch>
                            {unitProperty?.options.map(option => (
                                <Option key={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                label="设置为默认申请人"
                name="is_default"
            >
                <Radio.Group>
                    <Radio value={true}>是</Radio>
                    <Radio value={false}>否</Radio>
                </Radio.Group>
            </Form.Item>

            <Divider></Divider>

            <Button
                type="primary"
                block
                onClick={() => form.submit()}
            >
                {data ? <EditOutlined /> : <PlusOutlined />} {data ? '编辑' : '添加'}企业
            </Button>
        </Form>
    );
}

export default PreArbitrationCompanyFormModal;