// antd
import { Result } from "antd";

// assets
import waitingImg from '@assets/404.svg';

export function NothingPage() {
    return (
        <Result
            icon={<img style={{ width: '600px' }} src={waitingImg} alt="等待操作" />}
            title="未开放"
            subTitle="暂时未开放此功能，建议使用app操作"
        />
    );
}