import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { Pager } from "./common";
import { DownloadTemplate, IImportResult } from "./order";

export interface INaturalPeople {
    real_name: string,
    mobile: string,
    idcard: string,
    idcard_front_img: number,
    idcard_back_img: number,
    is_idcard_long_time: boolean,
    idcard_begin_date: string,
    idcard_expire_date: string,
    age: number,
    nation_dict: number,
    sex: number,
    sex_text: string,
    effect_service_address: string,
    is_default: boolean
    birthday?: string
}

export interface IPreArbitrationCompany {
    company_name: string,
    address: string,
    social_no: string,
    legal_person: string,
    contact_name: string,
    contact_mobile: string,
    is_default: boolean,
    business_license_attach: number,
    business_dict: number,
    unit_property_dict: number,
    company_type: number
}

export interface IThirdPartyNaturalPeople {
    subject_type: 1,
    natural_people: INaturalPeople,
}

export interface IThirdPartyCompany {
    subject_type: 2,
    company: IPreArbitrationCompany
}


export interface IPreArbitrationListData {
    id: number,
    is_remain_expire: boolean,
    question_type: number,
    case_number: string,
    order_no: string,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    case_cause: string,
    status: number,
    processing_result?: string,
    created_at: string,
    plaintiff_subject_type: number,
    defendant_subject_type: number,
    third_party?: (IThirdPartyCompany | IThirdPartyNaturalPeople)[],
    time_type_text?: string,
    province_text: string,
    city_text: string,
    area_text: string,
    street_text: string,
    lawsuit_type_text?: string,
    status_text: string,
    question_type_text: string,
    order_user: null,
    order_assist_user: null,
    plaintiff_default?: INaturalPeople | IPreArbitrationCompany,
    defendant_default?: INaturalPeople | IPreArbitrationCompany,
    can_archived: boolean,
    can_mediate: boolean,
    case_category_text?: string,
    closing_date?: string,
    organ?: {
        name: string
    }
    mediator?: string,
    receive_at?: string,
    staff_attitude?: string,
    company_attitude?: string,
    type_text?: string,
    acceptance_date?: string,
}

export interface IPreArbitrationList {
    list: IPreArbitrationListData[],
    total: number
}

export interface IPreArbitrationListSearchForm extends Pager {
    keywords?: string // 申请人/申请人电话/被申请人
    status?: number, // 调解状态
    receive_at?: string[], // 接单时间段
    question_type?: number, // 问题类别
    case_category?: number, // 案件类别
    lawsuit_type?: number, // 诉讼类别
    case_number?: string // 案号
    type?: number // 分办意见
    dispute_type?: number, // 争议类型
}

/**
 * 工单列表
 * @returns 
 */
export const getPreArbitrationList = (data: IPreArbitrationListSearchForm): Promise<CommonResponse<IPreArbitrationList>> => {
    return axiosInstance.post('/order/pre_worksheet/list', data).then(res => res.data);
};

/**
 * 工单列表导出
 * @returns 
 */
export const exportPreArbitrationList = (data: IPreArbitrationListSearchForm): Promise<CommonResponse<DownloadTemplate>> => {
    return axiosInstance.post('/order/pre_worksheet/list', { ...data, is_export: true }).then(res => res.data);
};

export interface IPreArbitrationSelectRegionData {
    id: number,
    name: string,
    pid: number,
    level: number,
    children: IPreArbitrationSelectRegionData[]
}

export interface IPreArbitrationSelectRegion {
    region_level: number,
    region_level_text: string,
    list: IPreArbitrationSelectRegionData[]
}

/**
 * 登记信息可选街道
 * @returns 
 */
export const getPreArbitrationSelectRegion = (): Promise<CommonResponse<IPreArbitrationSelectRegion>> => {
    return axiosInstance.post('/order/pre_worksheet/select_region').then(res => res.data);
};

export interface IAttachFilesData {
    name: string,
    value: number,
    files: number[]
}

export interface IAttachFilesDataInfo extends IAttachFilesData {
    files_info: Attachment[]
}

export interface IPreArbitrationForm {
    order_user_id: number,
    order_no: string,
    question_type: number,
    lawsuit_type: number,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    case_cause: string,
    attach_files_data: IAttachFilesData[],
    issue_appeal: string,
    receiver: string,
    lawsuit_money: string,
    source_type: number,
    plaintiff_subject_type: number,
    plaintiff_data: {
        natural_people_list: INaturalPeople[],
        company_list: IPreArbitrationCompany[]
    },
    defendant_subject_type: number,
    defendant_data: {
        natural_people_list: INaturalPeople[],
        company_list: IPreArbitrationCompany[]
    },
    third_party: (IThirdPartyNaturalPeople | IThirdPartyCompany)[]
    receive_at?: string,
    type: number,
    is_soldier: boolean,
    is_disability: boolean,
    case_category: number,
    acceptance_date?: string,
    dispute_type: number,
    involve_people: number,
}
/**
 * 保存案宗
 * @param data 
 * @returns 
 */
export const savePreArbitrationForm = (data: IPreArbitrationForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/add', data).then(res => res.data);
};

export interface IPreArbitrationRegionCaseRuleForm {
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
}

export interface IProcessingResultOption {
    key: number,
    selected: boolean,
    label: string,
    option?: IProcessingResultOption[]
}

export interface IPreArbitrationRegionCaseRuleData {
    case_number: string,
    attachment_tags_data: {
        name: string,
        value: number
    }[],
    mediate_attachment_tags_data: {
        name: string,
        value: number
    }[],
    processing_result_option: IProcessingResultOption[],
    form_list: {
        type: number,
        title: string
    }[]
}

/**
 * 获取地区案号规则
 * @returns 
 */
export const getPreArbitrationRegionCaseRule = (data: IPreArbitrationRegionCaseRuleForm): Promise<CommonResponse<IPreArbitrationRegionCaseRuleData>> => {
    return axiosInstance.post('/order/pre_worksheet/get_region_case_rule', data).then(res => res.data);
};

export interface IPreArbitrationDetail {
    detail: IPreArbitrationDetailData
}

export interface IPreArbitrationDetailData {
    id: number,
    type: number,
    case_number: string,
    order_no: string,
    status: number,
    receive_at: string,
    time_type: number,
    user_type: number,
    order_id: number,
    order_user_id?: number,
    order_assist_user_id?: number,
    lawsuit_type: number,
    question_type: number,
    case_cause: string,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    source_type: number,
    source_type_text?: string,
    mediate_attach_files: IAttachFilesData[],
    mediate_attach_files_data_info: IAttachFilesDataInfo[],
    issue_appeal: null,
    receiver: null,
    attach_files_data: IAttachFilesData[],
    attach_files_data_info: IAttachFilesDataInfo[],
    involve_people?: number,
    is_involve_people1?: boolean,
    is_involve_people2?: boolean,
    is_involve_people3?: boolean,
    is_involve_people4?: boolean,
    is_covid?: boolean,
    is_covid_control?: boolean,
    is_new_job?: boolean,
    is_cate10?: boolean,
    is_cate11?: boolean,
    is_cate20: boolean,
    is_cate21?: boolean,
    is_cate22?: boolean,
    is_cate30?: boolean,
    is_cate40?: boolean,
    is_cate50?: boolean,
    is_cate60?: boolean,
    is_cate70?: boolean,
    is_mediate_settle?: boolean,
    closing_date?: string,
    mediate_involve_people?: number,
    lawsuit_money: string,
    is_result10?: boolean,
    is_result11?: boolean,
    is_result12?: boolean,
    is_result20?: boolean,
    is_result30?: boolean,
    undertake_dict: null,
    handling_situation_dicts: null,
    specific_case?: string,
    staff_attitude?: string,
    company_attitude?: string,
    processing_result?: string,
    mediate_fail_date?: string,
    acceptance_date?: string,
    recently_edit_by: null,
    plaintiff_subject_type: number,
    defendant_subject_type: number,
    third_party: (IThirdPartyNaturalPeople | IThirdPartyCompany)[],
    deleted_at?: string,
    created_at: string,
    updated_at: string,
    time_type_text: string,
    province_text: string,
    city_text: string,
    area_text: string,
    street_text: string,
    lawsuit_type_text?: string,
    status_text: string,
    question_type_text?: string,
    plaintiff_default?: INaturalPeople | IPreArbitrationCompany,
    defendant_default?: INaturalPeople | IPreArbitrationCompany,
    plaintiff_data: (INaturalPeople | IPreArbitrationCompany)[],
    defendant_data: (INaturalPeople | IPreArbitrationCompany)[],
    is_soldier: boolean,
    is_disability: boolean,
    type_text?: string,
    case_category_text?: string
}

/**
 * 工单详情
 * @returns 
 */
export const getPreArbitrationDetail = (data: { id: number }): Promise<CommonResponse<IPreArbitrationDetail>> => {
    return axiosInstance.post('/order/pre_worksheet/detail', data).then(res => res.data);
};

export interface IEditPreArbitrationConciliationDetailForm {
    id: number,
    handling_phase_dict: number,
    handling_situation: string,
    mediate_fail_date: string,
    acceptance_date: string,
    cate70_reason: string,
    involved_amount: number,
    specific_case?: string,
    staff_attitude?: string,
    company_attitude?: string,
    processing_result?: string,
    involve_people?: number,
    is_involve_people1?: boolean,
    is_involve_people2?: boolean,
    is_involve_people3?: boolean,
    is_involve_people4?: boolean,
    is_covid?: boolean,
    is_covid_control?: boolean,
    is_new_job?: boolean,
    is_cate10?: boolean,
    is_cate11?: boolean,
    is_cate20?: boolean,
    is_cate21?: boolean,
    is_cate22?: boolean,
    is_cate30?: boolean,
    is_cate40?: boolean,
    is_cate50?: boolean,
    is_cate60?: boolean,
    is_cate70?: boolean,
    is_mediate_settle?: boolean,
    closing_date?: string,
    mediate_involve_people?: number,
    is_result10?: boolean,
    is_result11?: boolean,
    is_result12?: boolean,
    is_result20?: boolean,
    is_result30?: boolean,
    mediate_attach_files_data: IAttachFilesData[]
}

/**
 * 调解
 * @param data 
 * @returns 
 */
export const editPreArbitrationConciliationDetail = (data: IEditPreArbitrationConciliationDetailForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/mediate', data).then(res => res.data);
};

export interface IPreArbitrationTablesData {
    type: number,
    title: string
}

export interface IPreArbitrationTables {
    list: IPreArbitrationTablesData[]
}

/**
 * 类型获取表单列表
 * @param data 
 * @returns 
 */
export const getPreArbitrationTables = (data: { pre_worksheet_id: number }): Promise<CommonResponse<IPreArbitrationTables>> => {
    return axiosInstance.post('/order/pre_worksheet/get_form_list', data).then(res => res.data);
};


/**
 * 下载表单
 * @param data 
 * @returns 
 */
export const downloadPreArbitrationTable = (data: { pre_worksheet_id: number, form_type: number }): Promise<CommonResponse<{ full_link: string }>> => {
    return axiosInstance.post('/order/pre_worksheet/form_download', data).then(res => res.data);
};



export interface IPreArbitrationCoverTableData {
    order_name?: string,
    case_number?: string,
    case_cause?: string,
    plaintiff?: string,
    defendant?: string,
    third_party_data?: string,
    mediator?: string,
    recorder?: string,
    receive_at?: string,
    closing_date?: string,
    processing_result?: string
}

export interface ILaborDisputeHandlingTableDataMediate {
    key: number,
    label: string,
    extra: null,
    selected: boolean
}

export interface ILaborDisputeHandlingTableData {
    plaintiff_subject_type?: number,
    plaintiff_subject_type_text?: string,
    plaintiff_default?: INaturalPeople | IPreArbitrationCompany,
    defendant_subject_type?: number,
    defendant_subject_type_text?: string,
    defendant_default?: INaturalPeople | IPreArbitrationCompany,
    fact_reason?: string,
    receiver?: string,
    receive_at?: string,
    undertaker?: string,
    undertake_at?: string,
    mediate_type?: ILaborDisputeHandlingTableDataMediate[],
    mediate_basic_situation?: string
}

export interface ILaborDisputeRecordTableData {
    mediator?: string,
    plaintiff_name?: string,
    defendant_name?: string,
    other_personnel?: string,
    address?: string,
    record?: ILaborDisputeRecordTableFormRecordData[],
    plaintiff_opinion?: string,
    defendant_opinion?: string,
    mediate_result: string,
    mediate_time?: string,
}

export interface ILaborDisputeMediationApplicationData {
    plaintiff_subject_type: number,
    plaintiff_subject_type_text: string,
    plaintiff_default: INaturalPeople | IPreArbitrationCompany,
    defendant_subject_type: number,
    defendant_subject_type_text: string,
    defendant_default: INaturalPeople | IPreArbitrationCompany,
    plaintiff_data?: IThirdPartyCompany | IThirdPartyNaturalPeople,
    defendant_data?: IThirdPartyCompany | IThirdPartyNaturalPeople,
    agent_real_name?: string,
    agent_sex?: number,
    agent_sex_text?: string,
    agent_nation?: string,
    agent_native_place?: string,
    agent_company_name?: string,
    agent_current_address?: string,
    agent_mobile?: string,
    agent_duty?: string,
    reason?: string
}

export interface ILaborDisputeMediationAgreementData {
    plaintiff_name: string,
    defendant_name: string,
    plaintiff_agent?: string,
    defendant_agent?: string,
    reason?: string,
    date1?: string,
    date2?: string,
    date3?: string,
    money?: number,
    part?: string
}

export interface IPowerOfAttorneyTableData {
    name?: string,
    personnel_data?: IPowerOfAttorneyTableFormPersonnelData[],
    reason?: string
}

export interface ICaseBackCoverTableData {
    is_apply_arbitration_review?: boolean,
    party_complaint_status?: number[],
    party_apply_refer_status?: number[],
    party_apply_copy_status?: number[],
    is_arbitration_consult?: boolean,
    other_reason?: string
}

export interface IProofOfDeliveryTableData {
    receiver?: string,
    delivery_address?: string,
    delivery_data?: IProofOfDeliveryTableFormDeliveryData[],
    remark?: string,
    other_reason?: string
}

export interface ISocialIssuesRegistryTableData {
    register_date?: string,
    people_number?: string,
    people_data?: ISocialIssuesRegistryTableFormPeopleData[],
    company_name?: string,
    legal_person?: string,
    address?: string,
    mobile?: string,
    reason?: string
}


export interface IPreArbitrationTableContent {
    detail?: IPreArbitrationCoverTableData
    & ILaborDisputeHandlingTableData
    & ILaborDisputeRecordTableData
    & ILaborDisputeMediationApplicationData
    & ILaborDisputeMediationAgreementData
    & IPowerOfAttorneyTableData
    & ICaseBackCoverTableData
    & IProofOfDeliveryTableData
    & ISocialIssuesRegistryTableData,
    list?: IFileIndexTableListData[],
}

/**
 * 获取表单内容
 * @param data 
 * @returns 
 */
export const getPreArbitrationTableContent = (data: { pre_worksheet_id: number, form_type: number }): Promise<CommonResponse<IPreArbitrationTableContent>> => {
    return axiosInstance.post('/order/pre_worksheet/form_data', data).then(res => res.data);
};

export interface IPreArbitrationCoverTableForm {
    pre_worksheet_id: number,
    mediator?: string,
    recorder?: string,
    receive_at?: string,
    closing_date?: string
}

/**
 * 案卷封面
 * @param data 
 * @returns 
 */
export const savePreArbitrationCoverTable = (data: IPreArbitrationCoverTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/case_front_cover', data).then(res => res.data);
};

export interface ILaborDisputeHandlingTableForm {
    pre_worksheet_id: number,
    receive_at?: string,
    fact_reason?: string,
    receiver?: string,
    undertaker?: string,
    undertake_at?: string,
    mediate_type?: ILaborDisputeHandlingTableDataMediate[],
    mediate_basic_situation?: string
}

/**
 * 劳动人事争议调解登记处理表
 * @param data 
 * @returns 
 */
export const saveLaborDisputeHandlingTable = (data: ILaborDisputeHandlingTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/labor_dispute_handling', data).then(res => res.data);
};

export interface IFileIndexTableListData {
    name: string,
    pagination?: string
}

export interface IFileIndexTableForm {
    pre_worksheet_id: number,
    list: IFileIndexTableListData[],
}

/**
 * 卷内目录
 * @param data 
 * @returns 
 */
export const saveFileIndexTable = (data: IFileIndexTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/file_index', data).then(res => res.data);
};
export interface ILaborDisputeRecordTableFormRecordData {
    role: string,
    content: string
}

export interface ILaborDisputeRecordTableForm {
    pre_worksheet_id: number,
    other_personnel?: string,
    address?: string,
    record?: ILaborDisputeRecordTableFormRecordData[],
    plaintiff_opinion?: string,
    defendant_opinion?: string
    mediate_result?: string
    mediate_time?: string,
}

/**
 * 劳动人事争议调解情况记录（双面）
 * @param data 
 * @returns 
 */
export const saveLaborDisputeRecordTable = (data: ILaborDisputeRecordTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/labor_dispute_record', data).then(res => res.data);
};

export interface ILaborDisputeMediationApplicationTableForm {
    pre_worksheet_id: number,
    plaintiff_data?: IThirdPartyCompany | IThirdPartyNaturalPeople,
    defendant_data?: IThirdPartyCompany | IThirdPartyNaturalPeople,
    agent_real_name?: string,
    agent_sex?: number,
    agent_nation?: string,
    agent_birthday?: string,
    agent_native_place?: string,
    agent_company_name?: string,
    agent_current_address?: string,
    agent_mobile?: string,
    agent_duty: string,
    reason?: string
}

/**
 * 劳动人事争议调解申请书
 * @param data 
 * @returns 
 */
export const saveLaborDisputeMediationApplicationTable = (data: ILaborDisputeMediationApplicationTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/labor_dispute_mediation_application', data).then(res => res.data);
};

export interface ILaborDisputeMediationAgreementTableForm {
    pre_worksheet_id: number,
    plaintiff_agent?: string,
    defendant_agent?: string,
    reason?: string,
    date1?: string,
    date2?: string,
    date3?: string,
    money?: number,
    part?: string
}

/**
 * 劳动人事争议调解协议书
 * @param data 
 * @returns 
 */
export const saveLaborDisputeMediationAgreementTable = (data: ILaborDisputeMediationAgreementTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/labor_dispute_mediation_agreement', data).then(res => res.data);
};

export interface IPowerOfAttorneyTableFormPersonnelData {
    real_name?: string,
    sex?: string,
    nation?: string,
    company?: string,
    duty?: string,
    mobile?: string,
    birthday?: string,
    relationship?: string
}

export interface IPowerOfAttorneyTableForm {
    pre_worksheet_id: number,
    name?: string,
    personnel_data?: IPowerOfAttorneyTableFormPersonnelData[],
    reason?: string
}

/**
 * 授权委托书
 * @param data 
 * @returns 
 */
export const savePowerOfAttorneyTable = (data: IPowerOfAttorneyTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/power_of_attorney', data).then(res => res.data);
};

export interface ICaseBackCoverTableForm {
    pre_worksheet_id: number,
    is_apply_arbitration_review?: boolean,
    party_complaint_status?: number[],
    party_apply_refer_status?: number[],
    party_apply_copy_status?: number[],
    is_arbitration_consult?: boolean,
    other_reason?: string
}

/**
 * 案卷封底
 * @param data 
 * @returns 
 */
export const saveCaseBackCoverTable = (data: ICaseBackCoverTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/case_back_cover', data).then(res => res.data);
};

export interface IProofOfDeliveryTableFormDeliveryData {
    delivery_file?: string,
    signer?: string,
    deliverer?: string,
    receive_at?: string,
    receiver?: string,
    reason?: string
}

export interface IProofOfDeliveryTableForm {
    pre_worksheet_id: number,
    receiver: string,
    delivery_address: string,
    delivery_data: IProofOfDeliveryTableFormDeliveryData[],
    remark: string,
    other_reason: string
}

/**
 * 送达回证
 * @param data 
 * @returns 
 */
export const saveProofOfDeliveryTable = (data: IProofOfDeliveryTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/proof_of_delivery', data).then(res => res.data);
};

export interface ISocialIssuesRegistryTableFormPeopleData {
    real_name?: string,
    sex?: string,
    idcard?: string,
    mobile?: string,
    delivery_address?: string
}

export interface ISocialIssuesRegistryTableForm {
    pre_worksheet_id: number,
    register_date?: string,
    people_number?: string,
    people_data?: ISocialIssuesRegistryTableFormPeopleData[],
    company_name?: string,
    legal_person?: string,
    address?: string,
    mobile?: string,
    reason?: string
}

/**
 * 送达回证
 * @param data 
 * @returns 
 */
export const saveSocialIssuesRegistryTable = (data: ISocialIssuesRegistryTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/social_issues_registry', data).then(res => res.data);
};

export interface IDownloadTemplate {
    full_link: string
}

/**
 * 下载导入模板
 * @returns 
 */
export const downloadPreArbitrationTemplate = (): Promise<CommonResponse<IDownloadTemplate>> => {
    return axiosInstance.post('/order/pre_worksheet/download_excel').then(res => res.data);
};

export interface IImportPreArbitrationForm {
    file_id: number,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number
}

/**
 * 导入模板
 * @param data 
 * @returns 
 */
export const importPreArbitration = (data: IImportPreArbitrationForm): Promise<CommonResponse<IImportResult>> => {
    return axiosInstance.post('/order/pre_worksheet/import', data).then(res => res.data);
};

/**
 * 下载空白表单
 * @param data 
 * @returns     
 */
export const downloadPreArbitrationBlankTable = (data: { area_id: number, type: number }): Promise<CommonResponse<{ full_link: string }>> => {
    return axiosInstance.post('/order/pre_worksheet/download_blank_form', data).then(res => res.data);
};

export interface IPostponeFileForm {
    pre_worksheet_id: number,
    expire_at: string,
    postpone_attach_files_data: IAttachFilesData[]
}

/**
 * 延期
 * @param data 
 * @returns 
 */
export const savePostponeFile = (data: ISocialIssuesRegistryTableForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/postpone_file', data).then(res => res.data);
};

export interface IPreArbitrationAttachmentTag {
    attachment_tags_data: {
        name: string,
        value: number
    }[],
    mediate_attachment_tags_data: {
        name: string,
        value: number
    }[],
    postpone_attachment_tags_data: IProcessingResultOption[],
}

/**
 * 工单附件标签
 * @param data 
 * @returns     
 */
export const getPreArbitrationAttachmentTag = (data: { pre_worksheet_id: number }): Promise<CommonResponse<IPreArbitrationAttachmentTag>> => {
    return axiosInstance.post('/order/pre_worksheet/get_attachment_tag', data).then(res => res.data);
};

/**
 * 调解点击
 * @param data 
 * @returns     
 */
export const updateMediateStatus = (data: { pre_worksheet_id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/mediate_status', data).then(res => res.data);
};

/**
 * 归档
 * @param data 
 * @returns     
 */
export const archivedPreArbitration = (data: { pre_worksheet_id: number }): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/archived', data).then(res => res.data);
};



export interface IPreArbitrationPlaintiffDetail {
    plaintiff_subject_type: number,
    plaintiff_subject_type_text: string,
    plaintiff_data: {
        natural_people_list: INaturalPeople[],
        company_list: IPreArbitrationCompany[]
    }
}

/**
 * 申请人列表
 * @returns 
 */
export const getPreArbitrationPlaintiffDetail = (data: { pre_worksheet_id: number }): Promise<CommonResponse<IPreArbitrationPlaintiffDetail>> => {
    return axiosInstance.post('/order/pre_worksheet_plaintiff/list', data).then(res => res.data);
};

export interface IPreArbitrationPlaintiffForm {
    pre_worksheet_id: number,
    plaintiff_subject_type: number,
    plaintiff_data: {
        natural_people_list: INaturalPeople[],
        company_list: IPreArbitrationCompany[]
    }
}

/**
 * 申请人编辑
 * @param data 
 * @returns     
 */
export const editPreArbitrationPlaintiff = (data: IPreArbitrationPlaintiffForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet_plaintiff/edit', data).then(res => res.data);
};

export interface IPreArbitrationDefendantDetail {
    defendant_subject_type: number,
    defendant_subject_type_text: string,
    defendant_data: {
        natural_people_list: INaturalPeople[],
        company_list: IPreArbitrationCompany[]
    }
}

/**
 * 被申请人列表
 * @returns 
 */
export const getPreArbitrationDefendantDetail = (data: { pre_worksheet_id: number }): Promise<CommonResponse<IPreArbitrationDefendantDetail>> => {
    return axiosInstance.post('/order/pre_worksheet_defendant/list', data).then(res => res.data);
};


export interface IPreArbitrationDefendantForm {
    pre_worksheet_id: number,
    defendant_subject_type: number,
    defendant_data: {
        natural_people_list: INaturalPeople[],
        company_list: IPreArbitrationCompany[]
    }
}

/**
 * 被申请人编辑
 * @param data 
 * @returns
 */
export const editPreArbitrationDefendant = (data: IPreArbitrationDefendantForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet_defendant/edit', data).then(res => res.data);
};


export interface IPreArbitrationThirdPartyDetail {
    third_party?: (IThirdPartyCompany | IThirdPartyNaturalPeople)[],
}

/**
 * 第三人列表
 * @returns 
 */
export const getPreArbitrationThirdPartyDetail = (data: { pre_worksheet_id: number }): Promise<CommonResponse<IPreArbitrationThirdPartyDetail>> => {
    return axiosInstance.post('/order/pre_worksheet/third_party_list', data).then(res => res.data);
};


export interface IPreArbitrationThirdPartyForm {
    pre_worksheet_id: number,
    third_party: (IThirdPartyNaturalPeople | IThirdPartyCompany)[]
}

/**
 * 第三人编辑
 * @param data 
 * @returns
 */
export const editPreArbitrationThirdParty = (data: IPreArbitrationThirdPartyForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/third_party_edit', data).then(res => res.data);
};

/**
 * 批量导出压缩包
 * @param data 
 * @returns     
 */
export const exportZip = (data: { ids: number[] }): Promise<CommonResponse<{ full_link: string }>> => {
    return axiosInstance.post('/order/pre_worksheet/batch_export_zip', data).then(res => res.data);
};


export interface IAttachmentTag {
    attachment_tags_data: {
        name: string,
        value: number
    }[]
}

/**
 * 工单附件标签
 * @param data 
 * @returns
 */
export const getAttachmentTagByPId = (data: { pre_worksheet_id: number }): Promise<CommonResponse<IAttachmentTag>> => {
    return axiosInstance.post('/order/pre_worksheet/get_attachment_tag', data).then(res => res.data);
};

export interface IReplenishAttachmentForm {
    pre_worksheet_id: number,
    attach_files_data: IAttachFilesData[],
}

/**
 * 补充登记附件
 * @param data 
 * @returns
 */
export const replenishAttachment = (data: IReplenishAttachmentForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/pre_worksheet/replenish_attach', data).then(res => res.data);
};


export interface IPreArbitrationFormUserListData {
    id: number,
    username: string,
    real_name: string
}

export interface IPreArbitrationFormUserList {
    list: IPreArbitrationFormUserListData[],
}

/**
 * 当前机构所在地区下所有人员列表
 * @returns 
 */
export const getPreArbitrationFormUserList = (): Promise<CommonResponse<IPreArbitrationFormUserList>> => {
    return axiosInstance.post('/order/pre_worksheet/select_user').then(res => res.data);
};


export interface IRefuseAnalysisListData {
    id: number,
    name: string,
    value: number[],
    total_count: number
}


export interface IRefuseAnalysisList {
    list: IRefuseAnalysisListData[],
    enums: string[]
    total: number
}

export interface IRefuseAnalysisListSearchForm extends Pager {
    begin_month: string,
    end_month: string,
}
/**
 * 拒绝分析列表
 * @returns 
 */
export const getRefuseAnalysisList = (data: IRefuseAnalysisListSearchForm): Promise<CommonResponse<IRefuseAnalysisList>> => {
    return axiosInstance.post('/order/pre_worksheet_stat/refuse_analysis', data).then(res => res.data);
};