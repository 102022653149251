import { axiosInstance, CommonResponse } from ".";
import { Attachment } from "./attachment";
import { Pager } from "./common";

export interface IAreaMediationDataItem {
    month: string,
    total_count: number,
    success_count: number,
    fail_count: number,
    success_percent: number,
    fail_percent: number
}

export interface IAreaMediationData {
    list: IAreaMediationDataItem[]
}

/**
 * 获取地区调解数据
 * @returns 
 */
export const getAreaMediationData = (data: { begin_month: string, end_month: string }): Promise<CommonResponse<IAreaMediationData>> => {
    return axiosInstance.post('/order/chart/plate1', data).then(res => res.data);
};

export interface IMultiAreaMediationCountDataItemRegion {
    id: number,
    name: string,
    count: number
}

export interface IMultiAreaMediationCountDataItem {
    month: string,
    region_list: IMultiAreaMediationCountDataItemRegion[]
}

export interface IMultiAreaMediationCountData {
    list: IMultiAreaMediationCountDataItem[]
}

/**
 * 获取多地区案件数据
 * @returns 
 */
export const getMultiAreaMediationCountData = (data: { begin_month: string, end_month: string }): Promise<CommonResponse<IMultiAreaMediationCountData>> => {
    return axiosInstance.post('/order/chart/plate3', data).then(res => res.data);
};

export interface IComplaintTypePercentageDataItem {
    id: number,
    name: string,
    count: number,
    // percent: number,
}

export interface IComplaintTypePercentageData {
    list: IComplaintTypePercentageDataItem[],
    total_count: number
}

/**
 * 获取投诉类型百分比数据
 * @returns 
 */
export const getComplaintTypePercentageData = (data: { begin_month: string, end_month: string }): Promise<CommonResponse<IComplaintTypePercentageData>> => {
    return axiosInstance.post('/order/chart/plate2', data).then(res => res.data);
};

export interface ITimeFeedbackDataItem {
    id: number,
    name: string,
    day: number
}

export interface ITimeFeedbackData {
    list: ITimeFeedbackDataItem[]
}

/**
 * 获取时间反馈情况数据
 * @returns 
 */
export const getTimeFeedbackData = (): Promise<CommonResponse<ITimeFeedbackData>> => {
    return axiosInstance.post('/order/chart/plate4').then(res => res.data);
};

export interface IOrderListData {
    id: number,
    receive_at: string,
    time_type: number,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    name: string,
    status: number,
    created_at: string,
    time_type_text: string,
    province_text: string,
    city_text: string,
    area_text: string,
    street_text: string,
    lawsuit_type_text?: string,
    company_business_text?: string,
    company_scale_text?: string,
    status_text: string,
    order_no: string
}

export interface IOrderList {
    list: IOrderListData[],
    total: number
}

export interface IOrderListSearchForm extends Pager {
    receive_at?: string[],
    time_type?: number,
    source?: number,
    keywords?: string,
    specific_case?: string
}

/**
 * 工单列表
 * @returns 
 */
export const getOrderList = (data: IOrderListSearchForm): Promise<CommonResponse<IOrderList>> => {
    return axiosInstance.post('/order/worksheet/list', data).then(res => res.data);
};

export interface DownloadTemplate {
    full_link: string
}

/**
 * 导出工单列表
 * @returns 
 */
export const exportOrderList = (data: IOrderListSearchForm): Promise<CommonResponse<DownloadTemplate>> => {
    return axiosInstance.post('/order/worksheet/list', { ...data, is_export: true }).then(res => res.data);
};

export interface IImportResult {
    full_link?: string,
    has_fail?: boolean,
    tips: string
}

/**
 * Excel导入
 * @param data 
 * @returns 
 */
export const importWorksheetExcel = (data: { file_id: number }): Promise<CommonResponse<IImportResult>> => {
    return axiosInstance.post('/order/worksheet/import', data).then(res => res.data);
};

/**
 * Word导入
 * @param data 
 * @returns 
 */
export const importWorksheetWord = (data: { file_ids: number[], specific_case?: string }): Promise<CommonResponse<IImportResult>> => {
    return axiosInstance.post('/order/worksheet/word_import', data).then(res => res.data);
};

/**
 * ioc_Word批量导入
 * @param data 
 * @returns 
 */
export const importIocWorksheetWord = (data: { file_ids: number[], specific_case?: string }): Promise<CommonResponse<IImportResult>> => {
    return axiosInstance.post('/order/worksheet/ioc_word_import', data).then(res => res.data);
};

/**
 * 中山市模板导入
 * @param data 
 * @returns 
 */
export const importWorksheetExcelZS = (data: { file_ids: number[] }): Promise<CommonResponse<IImportResult>> => {
    return axiosInstance.post('/order/worksheet/import_ZS', data).then(res => res.data);
};

export interface IExportIOCForm {
    province_id?: number,
    city_id?: number,
    area_id?: number,
    street_id?: number,
    receive_at?: string[],
    time_type?: number
}

/**
 * 导出IOC压缩包
 * @param data 
 * @returns 
 */
export const exportIOC = (data: IExportIOCForm): Promise<CommonResponse<DownloadTemplate>> => {
    return axiosInstance.post('/order/worksheet/ioc_export', data).then(res => res.data);
};

/**
 * IOC结果报告压缩
 * @param data 
 * @returns 
 */
export const exportIOCReport = (data: IExportIOCForm): Promise<CommonResponse<DownloadTemplate>> => {
    return axiosInstance.post('/order/worksheet/ioc_export_report', data).then(res => res.data);
};


export interface IOrderDetailData {
    id: number,
    receive_at: string,
    time_type: number,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    title: string,
    name: string,
    mobile: string,
    age: number,
    idcard?: string,
    specific_case?: null,
    staff_attitude: string,
    company_attitude: string,
    trail_result: string,
    lawsuit_type?: number,
    lawsuit_money?: number,
    visit_appointment_at?: string,
    visit_practical_at?: string,
    peacemaker?: string,
    is_visit_conciliation?: boolean,
    conciliation_result?: string,
    time_type_text: string,
    province_text: string,
    city_text: string,
    area_text: string,
    street_text: string,
    lawsuit_type_text?: string,
    status?: string,
    status_text?: string,
    order_no: string,
    describe: string,
    question_type_text?: string,
    attach_files_info: Attachment[],
    mediate_attach_files_info: Attachment[]
}

export interface IOrderDetail {
    detail: IOrderDetailData
}

/**
 * 工单详情
 * @returns 
 */
export const getOrderDetail = (data: { id: number }): Promise<CommonResponse<IOrderDetail>> => {
    return axiosInstance.post('/order/worksheet/detail', data).then(res => res.data);
};

export interface IDownloadTemplate {
    full_link: string
}

/**
 * 下载导入模板
 * @returns 
 */
export const downloadTemplate = (): Promise<CommonResponse<IDownloadTemplate>> => {
    return axiosInstance.get('/api/shares/download_sheet?name=worksheet_template1').then(res => res.data);
};

export interface IAddInformationForm {
    question_type: number,
    lawsuit_type: number,
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number,
    organ_id: number,
    name: string,
    telephone_number?: string,
    mobile: string,
    idcard: string,
    sex: string,
    form_type: string,
    event_level: string,
    event_begin_at?: string,
    assigned_at: string,
    title: string,
    attach_files?: number[],
    describe: string,
    event_address: string,
    sued_company_name?: string,
    sued_company_address?: string,
    time_limit: string,
    dispatch_opinion: string,
    supplement_info?: string,
    handle_opinion?: string
}

/**
 * 添加登记信息
 * @returns 
 */
export const AddInformation = (data: IAddInformationForm): Promise<CommonResponse> => {
    return axiosInstance.post('/order/worksheet/add', data).then(res => res.data);
};

export interface ISelectRegionData {
    id: number,
    name: string,
    children: ISelectRegionData[]
}

export interface ISelectRegion {
    region_level: number,
    region_level_text: string,
    list: ISelectRegionData[]
}

/**
 * 登记信息-可选街道
 * @returns 
 */
export const getSelectRegion = (): Promise<CommonResponse<ISelectRegion>> => {
    return axiosInstance.post('/order/worksheet/select_region').then(res => res.data);
};

export interface ISelectOrganForm {
    province_id: number,
    city_id: number,
    area_id: number,
    street_id: number
}

export interface ISelectOrganData {
    id: number,
    name: string
}
export interface ISelectOrgan {
    list: ISelectOrganData[]
}

/**
 * 登记信息-可选机构
 * @returns 
 */
export const getSelectOrgan = (data: ISelectOrganForm): Promise<CommonResponse<ISelectOrgan>> => {
    return axiosInstance.post('/order/worksheet/select_organ', data).then(res => res.data);
};


/**
 * 裁前调解成功率
 * @returns 
 */
export const getPreArbitrationMediationData = (data: { begin_month: string, end_month: string }): Promise<CommonResponse<IAreaMediationData>> => {
    return axiosInstance.post('/order/chart/plate5', data).then(res => res.data);
};


/**
 * 裁前投诉类型
 * @returns 
 */
export const getPreArbitrationTypePercentageData = (data: { begin_month: string, end_month: string }): Promise<CommonResponse<IComplaintTypePercentageData>> => {
    return axiosInstance.post('/order/chart/plate6', data).then(res => res.data);
};

export interface IRegionStatListData {
    id: number,
    name: string,
    month: string,
    success_count: number,
    fail_count: number,
    total_count: number,
    conclude_count: number,
    success_percent: number,
    involved_amount_total: number,
    lawsuit_money_total: number
}


export interface IRegionStatList {
    list: IRegionStatListData[],
    total: number
}

export interface IRegionStatListSearchForm extends Pager {
    begin_month: string,
    end_month: string,
}

/**
 * 统计数据
 * @returns 
 */
export const getRegionStatList = (data: IRegionStatListSearchForm): Promise<CommonResponse<IRegionStatList>> => {
    return axiosInstance.post('/order/pre_worksheet_stat/region_list', data).then(res => res.data);
};