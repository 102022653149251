import { useNavigate } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDebounce } from 'ahooks';

// apis
import { getDictList, IDictData, uploadFile } from '../../apis/common';
import { archivedPreArbitration, downloadPreArbitrationBlankTable, downloadPreArbitrationTemplate, exportPreArbitrationList, exportZip, getPreArbitrationList, getPreArbitrationSelectRegion, IImportPreArbitrationForm, importPreArbitration, INaturalPeople, IPreArbitrationCompany, IPreArbitrationListData, IPreArbitrationListSearchForm, IPreArbitrationSelectRegionData, updateMediateStatus } from '../../apis/preArbitration';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, Modal, Card, Input, Pagination, Empty, Grid, message, Form, Cascader } from 'antd';
import { CloseOutlined, FileAddOutlined, AreaChartOutlined } from '@ant-design/icons';
import DatePicker from '../../components/customAntd/DatePicker';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionSheet, Cascader as CascaderMobile, CascaderOption, ImageUploader, ImageUploadItem, Popup } from 'antd-mobile';

import PreArbitrationFormModal from './actions/preArbitrationForm';
import PreArbitrationTablesModal from './tables/tableList';
import PreArbitrationPostponeFormModal from './actions/preArbitrationPostponeForm';
import PreArbitrationPlaintiffFormModal from './actions/preArbitrationPlaintiffForm';
import PreArbitrationDefendantFormModal from './actions/preArbitrationDefendantForm';
import PreArbitrationThirdPartyFormModal from './actions/preArbitrationThirdPartyForm';
import { Action } from 'antd-mobile/es/components/action-sheet';
import { globalAuthSigns, useHasAuth } from '../../utils/auth';
import PreArbitrationAttachmentFormModal from './actions/preArbitrationAttachmentForm';
import PreArbitrationRefuseAnalysisListModal from './actions/preArbitrationRefuseAnalysis';

const { Column } = Table;
const { Option } = Select;
const { useBreakpoint } = Grid;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

function PreArbitrationList() {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    // 权限检查
    const authRes = useHasAuth([
        globalAuthSigns.preWorksheet_add,
        globalAuthSigns.preWorksheet_form,
        globalAuthSigns.preWorksheet_import,
        globalAuthSigns.preWorksheet_exportZip,
        globalAuthSigns.preWorksheet_attachment,
        globalAuthSigns.preWorksheet_stat,
    ]);
    // 组件全局权限引用
    const [hasPreWorksheetAddAuth, setHasPreWorksheetAddAuth] = useState(false);
    const [hasPreWorksheetFormAuth, setHasPreWorksheetFormAuth] = useState(false);
    const [hasPreWorksheetImportAuth, setHasPreWorksheetImportAuth] = useState(false);
    const [hasPreWorksheetExportZipAuth, setHasPreWorksheetExportZipAuth] = useState(false);
    const [hasPreWorksheetAttachmentAuth, setHasPreWorksheetAttachmentAuth] = useState(false);

    useEffect(() => {
        authRes.then(value => {
            setHasPreWorksheetAddAuth(value[0]);
            setHasPreWorksheetFormAuth(value[1]);
            setHasPreWorksheetImportAuth(value[2]);
            setHasPreWorksheetExportZipAuth(value[3]);
            setHasPreWorksheetAttachmentAuth(value[4]);
        })
    }, [authRes]);

    // 搜索表单
    const [form, setForm] = useState<IPreArbitrationListSearchForm>({
        page: 1,
        size: 10
    });

    // 统计数据
    // const [stat, setStat] = useState<IMyConciliationStat>();

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IPreArbitrationListData)[]>([]);
    const [mListData, setMListData] = useState<(IPreArbitrationListData)[]>([]);
    const [mActions, setMActions] = useState<{
        visible: boolean,
        actions: Action[]
    }>({
        visible: false,
        actions: []
    });

    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IPreArbitrationListSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getPreArbitrationList(tempForm);
            setTotal(res.data.total);
            setMListData(res.data.list.map(item => ({
                ...item,
                action: false
            })));
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }

        // getMyConciliationStat().then(res => {
        //     setStat(res.data);
        // })
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm = {
            nav_type: 1,
            page: 1,
            size: 20
        }
        setForm(newForm);
        loadData(newForm);
    }

    // 诉讼类别
    const [lawsuitType, setLawsuitType] = useState<IDictData>();
    const handleLawsuitTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                lawsuit_type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };


    // 接单时间段
    const handleReceiveChange = (_: any, formatString: [string, string]) => {
        if (!formatString[0] || !formatString[1]) {
            setForm((form) => {
                let newForm = {
                    ...form,
                    receive_at: undefined,
                };
                loadData(newForm);
                return newForm;
            });
        } else {
            setForm((form) => {
                let newForm = {
                    ...form,
                    receive_at: formatString,
                };
                loadData(newForm);
                return newForm;
            });
        }
    }

    // 案件类型
    const [questionType, setQuestionType] = useState<IDictData>();
    const handleQuestionTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                question_type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 案件类别
    const [caseCategory, setCaseCategory] = useState<IDictData>();
    const handleQCaseCategoryChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                case_category: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 状态搜索
    const [status, setStatus] = useState<IDictData>();
    const handleStatusChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                status: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 案件类型
    const [type, setType] = useState<IDictData>();
    const handleTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 争议类型
    const [disputeType, setDisputeType] = useState<IDictData>();
    const handleDisputeTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                dispute_type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    useEffect(() => {
        getDictList(['pre_worksheet_status', 'worksheet_question_type', 'global_time_type', 'worksheet_lawsuit_type', 'pre_worksheet_case_category', 'pre_worksheet_type', 'pre_worksheet_dispute_type']).then(res => {
            setStatus(res.data.pre_worksheet_status);
            setQuestionType(res.data.worksheet_question_type);
            setLawsuitType(res.data.worksheet_lawsuit_type);
            setCaseCategory(res.data.pre_worksheet_case_category);
            setType(res.data.pre_worksheet_type);
            setDisputeType(res.data.pre_worksheet_dispute_type);
        });
    }, []);

    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    const caseNumberDebouncedValue = useDebounce(form.case_number, { wait: 500 });
    useEffect(() => {
        loadData(form);
    }, [keywordsDebouncedValue, caseNumberDebouncedValue]);
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }
    const handleCaseNumberChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            case_number: value.target.value
        };
        setForm(newForm);
    }
    // 表单弹窗
    const [toAddEditModal, setToAddEditModal] = useState<{
        isModalVisible: boolean,
        data?: { id: number }
    }>();
    const toAdd = () => {
        if (screens.xs) {
            navigate(`/preArbitration/form`);
        } else {
            setToAddEditModal({
                isModalVisible: true,
            });
        }
    };
    const toEdit = (id: number) => {
        if (screens.xs) {
            navigate(`/preArbitration/form?id=${id}`);
        } else {
            setToAddEditModal({
                isModalVisible: true,
                data: { id }
            });
        }
    };
    const onDetailClose = () => {
        setToAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    };
    const onAddEditFinish = () => {
        loadData();
        setToAddEditModal({
            ...toAddEditModal!,
            isModalVisible: false
        })
    }


    // 调解弹窗
    const [toConciliationModal, setConciliationModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toConciliation = (id: number) => {
        updateMediateStatus({ pre_worksheet_id: id });
        if (screens.xs) {
            navigate(`/preArbitration/conciliation?id=${id}`);
        } else {
            setConciliationModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    };
    const onConciliationClose = () => {
        loadData();
        setConciliationModal({
            ...toConciliationModal!,
            isModalVisible: false
        })
    };
    const onConciliationFinish = () => {
        loadData();
        setConciliationModal({
            ...toConciliationModal!,
            isModalVisible: false
        })
    }

    // 添加表单
    const [tablesModal, setTablesModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toTables = (id: number) => {
        if (screens.xs) {
            navigate(`/preArbitration/tables?id=${id}`);
        } else {
            setTablesModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    }
    const onTablesClose = () => {
        setTablesModal({
            ...tablesModal!,
            isModalVisible: false
        })
    };


    // 添加表单
    const [attachmentModal, setAttachmentModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toAttachment = (id: number) => {
        if (screens.xs) {
            navigate(`/preArbitration/attachment?id=${id}`);
        } else {
            setAttachmentModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    }
    const onAttachmentClose = () => {
        setAttachmentModal({
            ...attachmentModal!,
            isModalVisible: false
        })
    };



    // 申请人编辑弹窗
    const [toEditPlaintiffModal, setToEditPlaintiffModal] = useState<{
        isModalVisible: boolean,
        type: number,
        data: { id: number }
    }>();
    const toEditPlaintiff = (id: number, type: number) => {
        updateMediateStatus({ pre_worksheet_id: id });
        if (screens.xs) {
            switch (type) {
                case 1:
                    navigate(`/preArbitration/plaintiff?id=${id}`);
                    break;

                case 2:
                    navigate(`/preArbitration/defendant?id=${id}`);
                    break;

                case 3:
                    navigate(`/preArbitration/thirdParty?id=${id}`);
                    break;

                default:
                    break;
            }
        } else {
            setToEditPlaintiffModal({
                isModalVisible: true,
                type,
                data: { id: id }
            });
        }
    };
    const onEditPlaintiffClose = () => {
        loadData();
        setToEditPlaintiffModal({
            ...toEditPlaintiffModal!,
            isModalVisible: false
        })
    };
    const onEditPlaintiffFinish = () => {
        loadData();
        setToEditPlaintiffModal({
            ...toEditPlaintiffModal!,
            isModalVisible: false
        })
    }

    // 转移工单
    // const [transferForm] = Form.useForm<ITransferConciliationForm>();
    // const [toTransferModal, setToTransferModal] = useState(false);
    // const onTransferClose = () => {
    //     setToTransferModal(false);
    // };
    // const onTransferSubmit = async () => {
    //     await transferForm.validateFields();

    //     setLoading(true);
    //     try {
    //         let _form = transferForm.getFieldsValue(true);
    //         let res = await transferConciliation(_form);
    //         onTransferClose();
    //         message.success(res.msg);
    //         loadData();
    //     } catch (e) {
    //         console.error(e);
    //     } finally {
    //         setLoading(false);
    //     }
    // }
    // const toTransfer = (ids: number[]) => {
    //     if (ids.length === 0) {
    //         message.warning('请至少选择一项');
    //     }
    //     transferForm.resetFields();
    //     transferForm.setFieldsValue({
    //         worksheet_ids: ids
    //     })
    //     setToTransferModal(true);
    // }

    // 下载导入模板
    const toDownload = async () => {
        let res = await downloadPreArbitrationTemplate();
        window.open(res.data.full_link, '_blank');
    }

    // 导出数据
    const exportData = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定要导出当前列表吗？',
            onOk() {
                setLoading(true);
                exportPreArbitrationList(form).then(res => {
                    setTimeout(() => {
                        window.open(res.data.full_link, '_blank');
                    }, 100);
                }).finally(() => setLoading(false));
            },
        });
    };

    // 空白登记表单下载
    const [downloadBlankTableForm] = Form.useForm<{ area_id: number, type: number }>();
    const [toDownloadBlankTableModal, setToDownloadBlankTableModal] = useState(false);
    const onDownloadBlankTableClose = () => {
        setToDownloadBlankTableModal(false);
    };
    const onDownloadBlankTableSubmit = async () => {
        await downloadBlankTableForm.validateFields();

        setLoading(true);
        try {
            let _form = downloadBlankTableForm.getFieldsValue(true);
            let res = await downloadPreArbitrationBlankTable(_form);
            setTimeout(() => {
                window.open(res.data.full_link, '_blank');
            }, 100);
            onDownloadBlankTableClose();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
    const toDownloadBlankTable = () => {
        setToDownloadBlankTableModal(true);
    }

    // 导入数据
    const inputRef = React.createRef<HTMLInputElement>();
    const upload = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        setLoading(true);
        try {
            let file = await uploadFile({ file: event.target.files![0] as Blob, type: 'default' });
            importDataForm.setFieldValue('file_id', file.data.file_id);
            let importResult = await importPreArbitration(importDataForm.getFieldsValue(true));
            if (importResult.data.has_fail) {
                confirm({
                    title: '导入失败',
                    icon: <ExclamationCircleOutlined />,
                    content: '是否下载失败结果文档',
                    onOk() {
                        window.open(importResult.data.full_link, '_blank');
                    },
                });
            } else {
                onImportDataClose();
                message.success(importResult.msg);
                loadData();
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };
    const [importDataForm] = Form.useForm<IImportPreArbitrationForm>();
    const [toImportDataModal, setToImportDataModal] = useState(false);
    const onImportDataClose = () => {
        setToImportDataModal(false);
    };
    const onImportDataSubmit = async () => {
        await importDataForm.validateFields();

        let _form = importDataForm.getFieldsValue(true);
        console.log(_form);
        if (!_form.province_id || !_form.city_id || !_form.area_id || !_form.street_id) {
            message.warning('请先选择处理区域');
            return;
        }

        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.click();
        }
    }
    const toImportData = () => {
        setToImportDataModal(true);
    }

    // 省/市/区/街道
    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }
    const [pcaOptions, setPcaOptions] = useState<Option[]>([]);
    const [options, setOptions] = useState<Option[]>([]);
    useEffect(() => {
        let nodes: IPreArbitrationSelectRegionData[] = [];
        const build = (list: IPreArbitrationSelectRegionData[], maxDepth: number, depth: number = 1,): Option[] => {
            return list.map(item => ({
                label: item.name,
                value: item.id,
                children: item.children.length > 0 && depth < maxDepth ? build(item.children, maxDepth, depth + 1) : undefined
            }));
        }
        getPreArbitrationSelectRegion().then(res => {
            nodes.push(...res.data.list);
            setOptions(build(nodes, 4));
            setPcaOptions(build(nodes, 3));
            console.log(pcaOptions);

            try {
                let province = nodes[0];
                let city = province.children[0];
                let area = city.children[0];
                let street = area.children[0];
                let locations = [province.id, city.id, area.id, street.id];
                downloadBlankTableForm.setFieldValue('cur_id', locations.slice(0, 3));
                importDataForm.setFieldValue('cur_id', locations);
                onCascaderChange(locations);
                onImportCascaderChange(locations);
            } catch (e) {
                message.error('无可用地区');
            }
        });
    }, []);
    type CascaderValueExtend = {
        items: (CascaderOption | null)[]
        isLeaf: boolean
    }
    // 下载空白模板
    const onCascaderMobileConfirm = (value: string[], extend: CascaderValueExtend) => {
        if (extend.items.length !== 3) {
            message.warning('地址请选择完整');
            return;
        }

        onCascaderChange(extend.items.map(item => Number(item!.value)));
    }
    const onCascaderChange = (value: any[]) => {

        let location = {
            province_id: value[0],
            city_id: value[1],
            area_id: value[2],
        };
        console.log(location);
        downloadBlankTableForm.setFieldsValue(location);
    }

    // 导入数据
    const onImportCascaderMobileConfirm = (value: string[], extend: CascaderValueExtend) => {
        if (extend.items.length !== 4) {
            message.warning('地址请选择完整');
            return;
        }

        onImportCascaderChange(extend.items.map(item => Number(item!.value)));
    }
    const onImportCascaderChange = (value: any[]) => {
        let location = {
            province_id: value[0],
            city_id: value[1],
            area_id: value[2],
            street_id: value[3]
        };
        importDataForm.setFieldsValue(location);
    }


    // 延期
    const [toPostponeModal, setPostponeModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toPostpone = (id: number) => {
        if (screens.xs) {
            navigate(`/preArbitration/postpone?id=${id}`);
        } else {
            setPostponeModal({
                isModalVisible: true,
                data: { id: id }
            })
        }
    };
    const onPostponeClose = () => {
        setPostponeModal({
            ...toPostponeModal!,
            isModalVisible: false
        })
    };
    const onPostponeFinish = () => {
        loadData();
        setPostponeModal({
            ...toPostponeModal!,
            isModalVisible: false
        })
    }

    // table选中
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    // 批量导出压缩包
    const toBatchExportZip = (ids: number[] = selectedRowKeys.map(id => Number(id))) => {
        if (ids.length === 0) {
            message.warning('请至少选择一项导出');
            return;
        }
        exportZip({ ids }).then(res => {
            window.open(
                res.data.full_link, '_blank'
            );
        });
    };

    // 地区统计拒绝分析
    const [toRefuseAnalysisModal, setToRefuseAnalysisModal] = useState<{
        isModalVisible: boolean,
    }>();
    const toRefuseAnalysis = () => {
        if (screens.xs) {
            navigate(`/preArbitration/refuseAnalysis`);
        } else {
            setToRefuseAnalysisModal({
                isModalVisible: true,
            });
        }
    };
    const onRefuseAnalysisClose = () => {
        setToRefuseAnalysisModal({
            isModalVisible: false
        })
    };


    return (
        <div className='address inner-page'>
            {
                // stat && <Alert message={<div>{stat.today} 前 <span style={spanStyle}>{stat.today_count}</span> 单未解决，至今共处理 <span style={spanStyle}>{stat.total_count}</span> 个工单</div>} type="info" showIcon />
            }

            <div className='search'>
                <div>
                    <RangePicker
                        className="search__item"
                        style={{ 'width': '300px' }}
                        value={[
                            form.receive_at && form.receive_at[0] ? dayjs(form.receive_at[0]) : null,
                            form.receive_at && form.receive_at[1] ? dayjs(form.receive_at[1]) : null,
                        ]}
                        onChange={handleReceiveChange}
                        format='YYYY-MM-DD'
                        placeholder={['开始日期', '结束日期']}
                    />
                    {/* <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="问题类别"
                        value={form.question_type}
                        onChange={handleQuestionTypeChange}
                    >
                        {questionType?.options.map(option => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                        ))}
                    </Select> */}
                    {/* <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="诉讼类别"
                        value={form.lawsuit_type}
                        onChange={handleLawsuitTypeChange}
                    >
                        {lawsuitType?.options.map(option => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                        ))}
                    </Select> */}
                    {/* <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="案件类别"
                        value={form.case_category}
                        onChange={handleQCaseCategoryChange}
                    >
                        {caseCategory?.options.map(option => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                        ))}
                    </Select> */}
                    <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="案件类型"
                        value={form.type}
                        onChange={handleTypeChange}
                    >
                        {type?.options.map(option => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="争议类型"
                        value={form.dispute_type}
                        onChange={handleDisputeTypeChange}
                    >
                        {disputeType?.options.map(option => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        className="search__item"
                        showArrow
                        placeholder="工单状态"
                        value={form.status}
                        onChange={handleStatusChange}
                    >
                        {status?.options.map(option => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <Input
                        className="search__item"
                        value={form.keywords}
                        onChange={handleKeywordsChange}
                        placeholder="姓名、电话号码搜索"
                    />
                    <Input
                        className="search__item"
                        value={form.case_number}
                        onChange={handleCaseNumberChange}
                        placeholder="案号搜索"
                    />
                    <Button
                        className="search__button"
                        icon={<CloseOutlined />}
                        loading={loading}
                        onClick={() => resetData()}
                    >
                        重置筛选
                    </Button>

                    <Dropdown.Button
                        className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                        style={{ marginRight: screens.xs ? 15 : 0 }}
                        type="primary"
                        loading={false}
                        trigger={['click']}
                        overlay={
                            <Menu
                                items={selectedRowKeys.length > 0 ? [
                                    {
                                        key: 'batchExportZip',
                                        disabled: !hasPreWorksheetExportZipAuth,
                                        label: (
                                            <div>批量导出压缩包</div>
                                        ),
                                        onClick: () => toBatchExportZip(),
                                    }
                                ] : [
                                    {
                                        key: 'template',
                                        label: (
                                            <div>下载导入模板</div>
                                        ),
                                        onClick: () => toDownload()
                                    },
                                    {
                                        key: 'import',
                                        disabled: !hasPreWorksheetImportAuth,
                                        label: (
                                            <>
                                                <div>导入数据</div>
                                                <input
                                                    ref={inputRef}
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    onChange={upload}
                                                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                                />
                                            </>
                                        ),
                                        onClick: () => toImportData()
                                    },
                                    {
                                        key: 'download',
                                        label: (
                                            <div>空白登记表单下载</div>
                                        ),
                                        onClick: () => toDownloadBlankTable()
                                    },
                                    {
                                        key: 'export',
                                        label: (
                                            <div>导出报表</div>
                                        ),
                                        onClick: exportData,
                                    },

                                ]}
                            />
                        }>
                        {selectedRowKeys.length > 0 ? `已选择${selectedRowKeys.length}项` : '更多操作'}
                    </Dropdown.Button>
                    {
                        hasPreWorksheetAddAuth &&
                        <Button
                            className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                            loading={loading}
                            type="primary"
                            onClick={toAdd}
                            icon={<FileAddOutlined />}
                        >
                            登记案件
                        </Button>
                    }
                </div>
            </div>
            <ActionSheet
                visible={mActions.visible}
                actions={mActions.actions}
                onClose={() => setMActions({
                    visible: false,
                    actions: []
                })}
            />
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map((item, index) =>
                                <Card className="m-card" onClick={() => {
                                    setMActions({
                                        visible: true,
                                        actions: [
                                            {
                                                key: 'tables',
                                                text: "填写表单",
                                                disabled: !hasPreWorksheetFormAuth,
                                                onClick: () => toTables(item.id),
                                            },
                                            {
                                                key: 'plaintiff',
                                                text: "编辑申请人",
                                                disabled: !hasPreWorksheetAddAuth,
                                                onClick: () => toEditPlaintiff(item.id, 1),
                                            },
                                            {
                                                key: 'defendant',
                                                text: "编辑被申请人",
                                                disabled: !hasPreWorksheetAddAuth,
                                                onClick: () => toEditPlaintiff(item.id, 2),
                                            },
                                            {
                                                key: 'thirdParty',
                                                text: "编辑第三人",
                                                disabled: !hasPreWorksheetAddAuth,
                                                onClick: () => toEditPlaintiff(item.id, 3),
                                            }, {
                                                key: 'attachment',
                                                disabled: !hasPreWorksheetAttachmentAuth,
                                                text: "补充登记附件",
                                                onClick: () => toAttachment(item.id),
                                            },
                                        ],
                                    })
                                }}>
                                    <div className='m-card_item'>
                                        <div>处理机构：</div>
                                        <div>{item.organ?.name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>案件类型：</div>
                                        <div>{item.type_text}</div>
                                    </div>
                                    <div className={`m-card_item ${item.is_remain_expire ? 'red-row' : ''}`}>
                                        <div>案号：</div>
                                        <div>{item.case_number}</div>
                                    </div>
                                    <div className={`m-card_item`}>
                                        <div>工单号：</div>
                                        <div>{item.order_no}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>街道：</div>
                                        <div>{item.street_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>申请人：</div>
                                        <div>{(item.plaintiff_default as INaturalPeople)?.real_name ?? (item.plaintiff_default as IPreArbitrationCompany)?.company_name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>被申请人：</div>
                                        <div>{(item.defendant_default as INaturalPeople)?.real_name ?? (item.defendant_default as IPreArbitrationCompany)?.company_name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>第三人：</div>
                                        <div>{
                                            item.third_party?.map(item => {
                                                switch (item.subject_type) {
                                                    case 1:
                                                        return item.natural_people.real_name

                                                    case 2:
                                                        return item.company.company_name
                                                }
                                            }).join('，')
                                        }</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>调解员：</div>
                                        <div>{item.mediator}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>受理时间：</div>
                                        <div>{item.acceptance_date}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>申请人态度：</div>
                                        {
                                            item.staff_attitude
                                                ? <div
                                                    className='clickable-text'
                                                    onClick={() => Modal.info({
                                                        title: '申请人态度',
                                                        content: item.staff_attitude,
                                                    })}
                                                >
                                                    <div style={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.staff_attitude}</div>
                                                </div>
                                                : '无'
                                        }
                                    </div>
                                    <div className='m-card_item'>
                                        <div>被申请人态度：</div>
                                        {
                                            item.company_attitude
                                                ? <div
                                                    className='clickable-text'
                                                    onClick={() => Modal.info({
                                                        title: '被申请人态度',
                                                        content: item.company_attitude,
                                                    })}
                                                >
                                                    <div style={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.company_attitude}</div>
                                                </div>
                                                : '无'
                                        }
                                    </div>
                                    <div className='m-card_item'>
                                        <div>处理结果：</div>
                                        <div>{item.status_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>结案时间：</div>
                                        <div>{item.closing_date ?? '无'}</div>
                                    </div>
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        rowSelection={
                            {
                                selectedRowKeys,
                                onChange: onSelectChange
                            }
                        }
                        rowKey={(record: IPreArbitrationListData) => record?.id}
                        rowClassName={(record, index) => {
                            return record?.is_remain_expire ? 'red-row' : '';
                        }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="处理机构" dataIndex={['organ', 'name']} key="name" />
                        <Column title="案件类型" dataIndex="type_text" key="type_text" />
                        <Column title="案号" dataIndex="case_number" key="case_number" />
                        <Column title="工单号" dataIndex="order_no" key="order_no" />
                        <Column title="街道" dataIndex="street_text" key="street_text" />
                        <Column title="申请人" dataIndex="plaintiff_default" key="plaintiff_default"
                            render={
                                (_: any, record: IPreArbitrationListData | null) => (
                                    record &&
                                    <div
                                        className={hasPreWorksheetAddAuth ? 'clickable-text' : ''}
                                        onClick={hasPreWorksheetAddAuth ? () => toEditPlaintiff(record.id, 1) : undefined}
                                    >
                                        {(record.plaintiff_default as INaturalPeople)?.real_name ?? (record.plaintiff_default as IPreArbitrationCompany)?.company_name ?? '无'}
                                    </div>
                                )
                            }
                        />
                        <Column title="被申请人" dataIndex="defendant_default" key="defendant_default"
                            render={
                                (_: any, record: IPreArbitrationListData | null) => (
                                    record &&
                                    <div
                                        className={hasPreWorksheetAddAuth ? 'clickable-text' : ''}
                                        onClick={hasPreWorksheetAddAuth ? () => toEditPlaintiff(record.id, 2) : undefined}
                                    >
                                        {(record.defendant_default as INaturalPeople)?.real_name ?? (record.defendant_default as IPreArbitrationCompany)?.company_name ?? '无'}
                                    </div>
                                )
                            }
                        />
                        <Column title="第三人" dataIndex="third_party" key="third_party" render={
                            (_: any, record: IPreArbitrationListData | null) => (
                                record &&
                                <div
                                    className={hasPreWorksheetAddAuth ? 'clickable-text' : ''}
                                    onClick={hasPreWorksheetAddAuth ? () => toEditPlaintiff(record.id, 3) : undefined}
                                >
                                    {
                                        record.third_party && record.third_party.length > 0
                                            ? record.third_party.map(item => {
                                                switch (item.subject_type) {
                                                    case 1:
                                                        return item.natural_people.real_name

                                                    case 2:
                                                        return item.company.company_name
                                                }
                                            }).join('，')
                                            : '无'
                                    }
                                </div>
                            )
                        } />
                        <Column title="调解员" dataIndex="mediator_text" key="mediator_text" />
                        <Column title="受理时间" dataIndex="acceptance_date" key="acceptance_date" />
                        <Column title="申请人态度" dataIndex="staff_attitude" key="staff_attitude"
                            render={
                                (_: any, record: IPreArbitrationListData | null) => (
                                    record?.staff_attitude ?
                                        <div
                                            className='clickable-text'
                                            onClick={() => Modal.info({
                                                title: '申请人态度',
                                                content: record.staff_attitude,
                                            })}
                                        >
                                            <div style={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{record.staff_attitude}</div>
                                        </div>
                                        : '无'
                                )
                            }
                        />
                        <Column title="被申请人态度" dataIndex="company_attitude" key="company_attitude"
                            render={
                                (_: any, record: IPreArbitrationListData | null) => (
                                    record?.company_attitude ?
                                        <div
                                            className='clickable-text'
                                            onClick={() => Modal.info({
                                                title: '被申请人态度',
                                                content: record.company_attitude,
                                            })}
                                        >
                                            <div style={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{record.company_attitude}</div>
                                        </div>
                                        : '无'
                                )
                            }
                        />
                        <Column title="处理结果" dataIndex="status_text" key="status_text" />
                        <Column title="结案时间" dataIndex="closing_date" key="closing_date" />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IPreArbitrationListData | null) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click']}
                                        size='small'
                                        type="primary"
                                        loading={loading}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'tables',
                                                        disabled: !hasPreWorksheetFormAuth,
                                                        label: (
                                                            <div>填写表单</div>
                                                        ),
                                                        onClick: () => toTables(record!.id),
                                                    }, {
                                                        key: 'attachment',
                                                        disabled: !hasPreWorksheetAttachmentAuth,
                                                        label: (
                                                            <div>补充登记附件</div>
                                                        ),
                                                        onClick: () => toAttachment(record!.id),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }

            {/* 表单弹窗 */}
            {
                toAddEditModal ? <Modal
                    width={1000}
                    open={toAddEditModal.isModalVisible}
                    title={`案件登记`}
                    maskClosable={false}
                    onCancel={onDetailClose}
                    footer={[
                        <Button key="back" onClick={onDetailClose}>
                            关闭
                        </Button>
                    ]}
                >
                    <PreArbitrationFormModal data={toAddEditModal.data} onFinish={onAddEditFinish}></PreArbitrationFormModal>
                </Modal> : null
            }

            {/* 涉案人员编辑 */}
            {
                toEditPlaintiffModal &&
                <Modal
                    open={toEditPlaintiffModal.isModalVisible}
                    title={toEditPlaintiffModal.type === 1
                        ? `申请人`
                        : toEditPlaintiffModal.type === 2
                            ? '被申请人'
                            : '第三人'}
                    onCancel={onEditPlaintiffClose}
                    footer={null}
                >
                    {
                        toEditPlaintiffModal.type === 1 &&
                        <PreArbitrationPlaintiffFormModal data={toEditPlaintiffModal.data} onFinish={onEditPlaintiffFinish}></PreArbitrationPlaintiffFormModal>
                    }
                    {
                        toEditPlaintiffModal.type === 2 &&
                        <PreArbitrationDefendantFormModal data={toEditPlaintiffModal.data} onFinish={onEditPlaintiffFinish}></PreArbitrationDefendantFormModal>
                    }
                    {
                        toEditPlaintiffModal.type === 3 &&
                        <PreArbitrationThirdPartyFormModal data={toEditPlaintiffModal.data} onFinish={onEditPlaintiffFinish}></PreArbitrationThirdPartyFormModal>
                    }
                </Modal>
            }

            {/* 填写表单 */}
            {
                tablesModal ? <Modal
                    open={tablesModal.isModalVisible}
                    title={`填写表单`}
                    onCancel={onTablesClose}
                    width={800}
                    footer={null}
                >
                    <PreArbitrationTablesModal data={tablesModal.data}></PreArbitrationTablesModal>
                </Modal> : null
            }

            {/* 补充登记附件 */}
            {
                attachmentModal ? <Modal
                    open={attachmentModal.isModalVisible}
                    title={`补充登记附件`}
                    onCancel={onAttachmentClose}
                    width={800}
                    footer={null}
                >
                    <PreArbitrationAttachmentFormModal data={attachmentModal.data} onFinish={onAttachmentClose}></PreArbitrationAttachmentFormModal>
                </Modal> : null
            }

            {/* 延期 */}
            {
                toPostponeModal ? <Modal
                    open={toPostponeModal.isModalVisible}
                    title={`工单延期`}
                    onCancel={onPostponeClose}
                    footer={null}
                >
                    <PreArbitrationPostponeFormModal data={toPostponeModal.data} onFinish={onPostponeFinish}></PreArbitrationPostponeFormModal>
                </Modal> : null
            }

            {
                <Modal
                    title="空白登记表单下载"
                    open={toDownloadBlankTableModal}
                    onOk={onDownloadBlankTableSubmit}
                    onCancel={onDownloadBlankTableClose}
                    confirmLoading={loading}
                >
                    <Form form={downloadBlankTableForm} layout="vertical">
                        <Form.Item
                            label="处理地区"
                            name="cur_id"
                            rules={[{ required: true, message: '请选择处理地区' }]}
                            trigger={screens.xs ? 'onConfirm' : undefined}
                        >
                            {
                                screens.xs
                                    ? <CascaderMobile
                                        options={pcaOptions as any}
                                        onConfirm={onCascaderMobileConfirm}
                                    >
                                        {(items, actions) => (
                                            <>
                                                <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                                {
                                                    items.every(item => item === null)
                                                        ? '请选择处理地区'
                                                        : items.map(item => item?.label ?? '请选择处理地区').join('-')
                                                }
                                            </>
                                        )}
                                    </CascaderMobile>
                                    : <Cascader options={pcaOptions} placeholder="请选择处理地区" onChange={onCascaderChange} />
                            }
                        </Form.Item>
                        <Form.Item
                            label="选择案件类型"
                            name="type"
                        >
                            <Select showArrow allowClear>
                                {type?.options?.map(option => (
                                    <Option key={option.value} value={option.value}>{option.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            }

            {/* 拒绝分析 */}
            {
                toRefuseAnalysisModal ? <Modal
                    width={1300}
                    visible={toRefuseAnalysisModal.isModalVisible}
                    title={`地区统计拒绝分析`}
                    maskClosable={false}
                    onCancel={onRefuseAnalysisClose}
                    footer={[
                        <Button key="back" onClick={onRefuseAnalysisClose}>
                            关闭
                        </Button>
                    ]}
                >
                    <PreArbitrationRefuseAnalysisListModal></PreArbitrationRefuseAnalysisListModal>
                </Modal> : null
            }

            {
                <Modal
                    title="导入数据"
                    open={toImportDataModal}
                    onOk={onImportDataSubmit}
                    onCancel={onImportDataClose}
                    confirmLoading={loading}
                >
                    <Form form={importDataForm} layout="vertical">
                        <Form.Item
                            label="处理地区"
                            name="cur_id"
                            rules={[{ required: true, message: '请选择处理地区' }]}
                            trigger={screens.xs ? 'onConfirm' : undefined}
                        >
                            {
                                screens.xs
                                    ? <CascaderMobile
                                        options={options as any}
                                        onConfirm={onImportCascaderMobileConfirm}
                                    >
                                        {(items, actions) => (
                                            <>
                                                <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                                {
                                                    items.every(item => item === null)
                                                        ? '请选择处理地区'
                                                        : items.map(item => item?.label ?? '请选择处理地区').join('-')
                                                }
                                            </>
                                        )}
                                    </CascaderMobile>
                                    : <Cascader options={options} placeholder="请选择处理地区" onChange={onImportCascaderChange} />
                            }
                        </Form.Item>
                    </Form>
                </Modal>
            }

            {/* 转移工单 */}
            {/* <Modal
                title="转移工单"
                open={toTransferModal}
                onOk={onTransferSubmit}
                onCancel={onTransferClose}
                confirmLoading={loading}
            >
                <Form form={transferForm} layout="vertical">
                    <Form.Item
                        label="选择处理人"
                        name="organ_user_id"
                        rules={[{ required: true, message: '请选择处理人' }]}
                    >
                        <Select showArrow>
                            {userList?.map(option => (
                                <Option key={option.id} value={option.id}>[{option.username}]{option.real_name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="选择协助人"
                        name="organ_assist_user_id"
                    >
                        <Select showArrow>
                            {userList?.map(option => (
                                <Option key={option.id} value={option.id}>[{option.username}]{option.real_name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal> */}
        </div>
    );
}

export default PreArbitrationList;