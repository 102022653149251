import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Form, message, Select, Modal, Skeleton, Radio, Descriptions, Typography } from 'antd';
import { EditOutlined, CloseCircleOutlined, SmileOutlined } from '@ant-design/icons';


import { Popup } from 'antd-mobile';

import { getPreArbitrationDefendantDetail, editPreArbitrationDefendant, INaturalPeople, IPreArbitrationCompany, IPreArbitrationDefendantForm } from '../../../apis/preArbitration';
import PreArbitrationNaturalPeopleFormModal, { INaturalPeopleForm } from './preArbitrationNaturalPeopleForm';
import PreArbitrationCompanyFormModal, { IPreArbitrationCompanyForm } from './preArbitrationCompanyForm';

const { Option } = Select;
const { useBreakpoint } = Grid;

/**
 * plaintiff 申请人
 * defendant 被申请人
 */
enum formNameTypes {
    plaintiffDataNaturalPeopleForm = 'plaintiffDataNaturalPeopleForm',
    plaintiffDataCompanyForm = 'plaintiffDataCompanyForm',
    defendantDataNaturalPeopleForm = 'defendantDataNaturalPeopleForm',
    defendantDataCompanyForm = 'defendantDataCompanyForm',
    thirdPartyNaturalPeopleForm = 'thirdPartyNaturalPeopleForm',
    thirdPartyCompanyForm = 'thirdPartyCompanyForm',
}

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function PreArbitrationDefendantFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IPreArbitrationDefendantForm>();

    // 初始化加载
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.resetFields();

        setLoading(true);
        let id = data?.id ?? Number(searchParams.get('id'));
        form.setFieldValue('pre_worksheet_id', id);

        getPreArbitrationDefendantDetail({ pre_worksheet_id: id }).then(res => {
            form.setFieldsValue(res.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [data, searchParams]);

    // 提交
    const submit = async () => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }
        let _form: IPreArbitrationDefendantForm = form.getFieldsValue(true);
        if (
            !_form.defendant_data ||
            (!_form.defendant_data.natural_people_list && !_form.defendant_data.company_list) ||
            ((_form.defendant_data.natural_people_list ?? []).length === 0 && (_form.defendant_data.company_list ?? []).length === 0)
        ) {
            message.warning('请至少添加一个被申请人');
            return;
        }


        let defaultNum = _form.defendant_data.company_list.filter(item => item.is_default).length + _form.defendant_data.natural_people_list.filter(item => item.is_default).length;

        if (defaultNum === 0) {
            message.warning('请至少添加一个默认被申请人');
            return;
        }

        if (defaultNum > 1) {
            message.warning('存在多于一个默认被申请人');
            return;
        }

        setConfirmLoading(true);
        try {
            let data = {
                ..._form,
            }

            let res = await editPreArbitrationDefendant(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate('/preArbitration');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    // 添加人员弹窗
    const [addEditNaturalPeopleOpen, setAddEditNaturalPeopleOpen] = useState(false);
    const [addEditNaturalPeopleFormName, setAddEditNaturalPeopleFormName] = useState('');
    const [addEditNaturalPeopleData, setAddEditNaturalPeopleData] = useState<INaturalPeopleForm>();
    const showAddEditNaturalPeopleModal = (formName: string, data?: INaturalPeople, index?: number) => {
        setAddEditNaturalPeopleFormName(formName);
        setAddEditNaturalPeopleOpen(true);
        if (data && typeof index === 'number') {
            setAddEditNaturalPeopleData({
                ...data,
                index
            });
        } else {
            setAddEditNaturalPeopleData(undefined);
        }
    };
    const hideAddEditNaturalPeopleModal = () => {
        setAddEditNaturalPeopleOpen(false);
    };
    const onAddEditNaturalPeopleDelClick = (index: number, path: string) => {
        let naturalPeople: INaturalPeople[] = form.getFieldValue([path, 'natural_people_list']);
        console.log(naturalPeople);
        form.setFieldValue([path, 'natural_people_list'], naturalPeople.filter((_, i) => index !== i));
    }
    // 添加企业弹窗
    const [addEditCompanyOpen, setAddEditCompanyOpen] = useState(false);
    const [addEditCompanyFormName, setAddEditCompanyFormName] = useState('');
    const [addEditCompanyData, setAddEditCompanyData] = useState<IPreArbitrationCompanyForm>();
    const showAddEditCompanyModal = (formName: string, data?: IPreArbitrationCompanyForm, index?: number) => {
        setAddEditCompanyFormName(formName);
        if (data && typeof index === 'number') {
            setAddEditCompanyData({
                ...data,
                index
            });
        } else {
            setAddEditCompanyData(undefined);
        }
        setAddEditCompanyOpen(true);
    };
    const hideAddEditCompanyModal = () => {
        setAddEditCompanyOpen(false);
    };
    const onAddEditCompanyDelClick = (index: number, path: string) => {
        let company: IPreArbitrationCompanyForm[] = form.getFieldValue([path, 'company_list']);
        console.log(company);
        form.setFieldValue([path, 'company_list'], company.filter((_, i) => index !== i));
    }

    // style
    const gutter = 16 + 8 * 2;

    const FormItem = (
        <Form.Provider
            onFormFinish={(name, { values, forms }) => {
                // =================== 个人 =====================
                if (name === formNameTypes.defendantDataNaturalPeopleForm) {
                    const { defendantDataNaturalPeopleForm } = forms;
                    const naturalPeople = defendantDataNaturalPeopleForm.getFieldsValue(true);
                    const naturalPeopleListData = form.getFieldValue(['defendant_data', 'natural_people_list']) || [];

                    // 默认有且只有一个
                    if (naturalPeople.is_default) {
                        naturalPeopleListData.forEach((item: INaturalPeopleForm) => item.is_default = false);
                    } else if (naturalPeopleListData.every((item: INaturalPeopleForm) => item.is_default === false)) {
                        naturalPeople.is_default = true;
                    }

                    console.log(naturalPeople);
                    if (naturalPeople.index !== undefined) {
                        let newNaturalPeopleList = [...naturalPeopleListData];
                        newNaturalPeopleList.splice(naturalPeople.index, 1, naturalPeople);
                        form.setFieldValue(['defendant_data', 'natural_people_list'], newNaturalPeopleList);
                    } else {
                        form.setFieldValue(['defendant_data', 'natural_people_list'], [...naturalPeopleListData, naturalPeople]);
                    }
                    setAddEditNaturalPeopleOpen(false);
                }

                // =================== 企业 =====================
                if (name === formNameTypes.defendantDataCompanyForm) {
                    const { defendantDataCompanyForm } = forms;
                    const company = defendantDataCompanyForm.getFieldsValue(true);
                    const companyListData = form.getFieldValue(['defendant_data', 'company_list']) || [];

                    // 默认有且只有一个
                    if (company.is_default) {
                        companyListData.forEach((item: IPreArbitrationCompanyForm) => item.is_default = false);
                    } else if (companyListData.every((item: IPreArbitrationCompanyForm) => item.is_default === false)) {
                        company.is_default = true;
                    }

                    console.log(company);
                    if (company.index !== undefined) {
                        let newCompanyList = [...companyListData];
                        newCompanyList.splice(company.index, 1, company);
                        form.setFieldValue(['defendant_data', 'company_list'], newCompanyList);
                    } else {
                        form.setFieldValue(['defendant_data', 'company_list'], [...companyListData, company]);
                    }
                    setAddEditCompanyOpen(false);
                }
            }}
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="被申请人类型"
                    name="defendant_subject_type"
                    rules={[{ required: true, message: '请选择被申请人类型' }]}
                >
                    <Radio.Group>
                        <Radio value={1}>自然人</Radio>
                        <Radio value={2}>企业</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => prevValues.defendant_subject_type !== curValues.defendant_subject_type}
                >
                    {({ getFieldValue }) => {
                        let defendantSubjectType = getFieldValue('defendant_subject_type');
                        return (
                            <>
                                <Form.Item
                                    label="添加被申请人"
                                    required
                                    shouldUpdate={(prevValues, curValues) => prevValues.defendant_data !== curValues.defendant_data}
                                >
                                    {({ getFieldValue }) => {
                                        var itemData: INaturalPeople[] | IPreArbitrationCompany[];
                                        switch (defendantSubjectType) {
                                            case 1:
                                                itemData = getFieldValue(['defendant_data', 'natural_people_list']) || [];
                                                break;
                                            case 2:
                                                itemData = getFieldValue(['defendant_data', 'company_list']) || [];
                                                break;

                                            default:
                                                return;
                                        }

                                        return itemData.length ? (
                                            <Descriptions size='small' bordered column={1}>
                                                {itemData.map((item, index) => (
                                                    <Descriptions.Item>
                                                        <div className="ding__code">
                                                            {
                                                                defendantSubjectType === 1 &&
                                                                <div>{`${(item as INaturalPeople).real_name}(${(item as INaturalPeople).mobile})`}{item.is_default ? '(默认)' : ''}</div>
                                                            }
                                                            {
                                                                defendantSubjectType === 2 &&
                                                                <div>{`${(item as IPreArbitrationCompany).company_name}`}{item.is_default ? '(默认)' : ''}</div>
                                                            }
                                                            <div style={{ minWidth: 64 }}>
                                                                <Button
                                                                    type="text"
                                                                    icon={<EditOutlined />}
                                                                    onClick={() => {
                                                                        switch (defendantSubjectType) {
                                                                            case 1:
                                                                                showAddEditNaturalPeopleModal(formNameTypes.defendantDataNaturalPeopleForm, (item as any), index);
                                                                                break;

                                                                            case 2:
                                                                                showAddEditCompanyModal(formNameTypes.defendantDataCompanyForm, (item as any), index);
                                                                                break;
                                                                        }
                                                                    }}
                                                                />
                                                                <Button
                                                                    type="text"
                                                                    danger
                                                                    icon={<CloseCircleOutlined />}
                                                                    onClick={() => {
                                                                        switch (defendantSubjectType) {
                                                                            case 1:
                                                                                onAddEditNaturalPeopleDelClick(index, 'defendant_data');
                                                                                break;

                                                                            case 2:
                                                                                onAddEditCompanyDelClick(index, 'defendant_data');
                                                                                break;
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Descriptions.Item>
                                                ))}
                                            </Descriptions>
                                        ) : (
                                            <Typography.Text className="ant-form-text" type="secondary">
                                                ( <SmileOutlined /> 暂无添加数据。 )
                                            </Typography.Text>
                                        );
                                    }}
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="button"
                                        onClick={() => {
                                            switch (defendantSubjectType) {
                                                case 1:
                                                    showAddEditNaturalPeopleModal(formNameTypes.defendantDataNaturalPeopleForm);
                                                    break;

                                                case 2:
                                                    showAddEditCompanyModal(formNameTypes.defendantDataCompanyForm);
                                                    break;
                                            }
                                        }}
                                    >
                                        添加被申请人
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.Item>

                <Form.Item>
                    <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
                </Form.Item>

                {/* 添加人员弹窗 */}
                {
                    screens.xs
                        ? <Popup
                            visible={addEditNaturalPeopleOpen}
                            showCloseButton
                            position='right'
                            onClose={hideAddEditNaturalPeopleModal}
                            bodyStyle={{ overflowY: 'auto' }}
                        >
                            <div style={{ margin: '40px 20px' }}>
                                <PreArbitrationNaturalPeopleFormModal key={Math.random()} formName={addEditNaturalPeopleFormName} data={addEditNaturalPeopleData}></PreArbitrationNaturalPeopleFormModal>
                            </div>
                        </Popup>
                        : <Modal
                            visible={addEditNaturalPeopleOpen}
                            title={`添加人员`}
                            maskClosable={false}
                            onCancel={hideAddEditNaturalPeopleModal}
                            footer={null}
                        >
                            <PreArbitrationNaturalPeopleFormModal key={Math.random()} formName={addEditNaturalPeopleFormName} data={addEditNaturalPeopleData}></PreArbitrationNaturalPeopleFormModal>
                        </Modal>
                }

                {/* 添加企业弹窗 */}
                {
                    screens.xs
                        ? <Popup
                            visible={addEditCompanyOpen}
                            showCloseButton
                            position='right'
                            onClose={hideAddEditCompanyModal}
                            bodyStyle={{ overflowY: 'auto' }}
                        >
                            <div style={{ margin: '40px 20px' }}>
                                <PreArbitrationCompanyFormModal key={Math.random()} formName={addEditCompanyFormName} data={addEditCompanyData}></PreArbitrationCompanyFormModal>
                            </div>
                        </Popup>
                        : <Modal
                            visible={addEditCompanyOpen}
                            title={`添加企业`}
                            maskClosable={false}
                            onCancel={hideAddEditCompanyModal}
                            footer={null}
                        >
                            <PreArbitrationCompanyFormModal key={Math.random()} formName={addEditCompanyFormName} data={addEditCompanyData}></PreArbitrationCompanyFormModal>
                        </Modal>
                }
            </Form>
        </Form.Provider>
    )


    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : FormItem
            }
        </div>
    );
}

export default PreArbitrationDefendantFormModal;