import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';
import RoutesLayout from './routes';

import zhCN from 'antd/es/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import { ConfigProvider, Modal } from 'antd';

import './assets/style/style.scss';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';

// 长时间未操作自动登出
let globalLogoutCounter = setInterval(() => {
    const token = localStorage.getItem('token');
    const loginTime = localStorage.getItem('login_time');
    console.log(loginTime, dayjs().diff(dayjs(Number(loginTime)), 'hours'));

    if (token && loginTime) {
        if (dayjs().diff(dayjs(Number(loginTime)), 'hours') >= 2) {
            console.log('logout!!!!!!!!!');
            localStorage.removeItem('token');
            localStorage.removeItem('account');
            Modal.warning({
                title: '提示',
                content: '由于长时间未操作系统自动登出，请重新登录。',
                afterClose: () => {
                    window.location.href = "/login";
                }
            });
        }
    }
}, 60 * 1000);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

window._AMapSecurityConfig = {
    // serviceHost: '您的代理服务器域名或地址/_AMapService',
    // 例如 ：serviceHost:'http://1.1.1.1:80/_AMapService',
    securityJsCode: '7fe26ec5d47a984719f2d1a7b7cae45a',
}

root.render(
    <ConfigProvider locale={zhCN}>
        <RoutesLayout />
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
