import { useEffect, useState } from 'react';

// antd
import { Grid, Card, Table, Empty, Pagination } from 'antd';
import DatePicker from '../../../components/customAntd/DatePicker';

import dayjs, { Dayjs } from 'dayjs';

import { getRefuseAnalysisList, IRefuseAnalysisListData, IRefuseAnalysisListSearchForm } from '../../../apis/preArbitration';

const { Column } = Table;
const { useBreakpoint } = Grid;
const { RangePicker } = DatePicker;

const disabledDate = (current: Dayjs) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('month');
};

function PreArbitrationRefuseAnalysisListModal() {
    const screens = useBreakpoint();

    // 搜索表单
    const [form, setForm] = useState<IRefuseAnalysisListSearchForm>({
        page: 1,
        size: 10,
        begin_month: dayjs().format('YYYY-MM'),
        end_month: dayjs().format('YYYY-MM'),
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IRefuseAnalysisListData)[]>([]);
    const [enumData, setEnumData] = useState<string[]>([]);

    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IRefuseAnalysisListSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getRefuseAnalysisList(tempForm);
            setEnumData(res.data.enums);
            setTotal(res.data.total);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        loadData();
    }, []);

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    // 月份筛选
    const handleReceiveChange = (_: any, formatString: [string, string]) => {
        if (formatString[0] && formatString[1]) {
            setForm((form) => {
                let newForm = {
                    ...form,
                    page: 1,
                    begin_month: formatString[0],
                    end_month: formatString[1],
                };
                loadData(newForm);
                return newForm;
            });
        }
    }

    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            <div className='search'>
                <RangePicker
                    picker="month"
                    disabledDate={disabledDate}
                    className="search__item"
                    style={{ 'width': '300px' }}
                    value={[
                        form.begin_month && form.begin_month ? dayjs(form.begin_month) : null,
                        form.end_month && form.end_month ? dayjs(form.end_month) : null,
                    ]}
                    onChange={handleReceiveChange}
                    format='YYYY-MM'
                    placeholder={['开始年月', '结束年月']}
                    allowClear={false}
                />
            </div>

            {
                screens.xs
                    ? <div>
                        {
                            listData.length > 0 ? listData.map(item =>
                                <Card className='m-card'>
                                    <div className='m-card_item'>
                                        <div>街道：</div>
                                        <div>{item.name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>案件总数：</div>
                                        <div>{item.total_count}</div>
                                    </div>
                                    {
                                        enumData.map((e, i) => (
                                            <div className='m-card_item'>
                                                <div>{e}</div>
                                                <div>{item.value[i]}%</div>
                                            </div>
                                        ))
                                    }
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        rowKey={(record) => record?.id}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column title="街道" dataIndex="name" key="name" />
                        <Column title="案件总数" dataIndex="total_count" key="total_count" />
                        {
                            enumData.map((e, i) => <Column title={`${e}(%)`} dataIndex={['value', i]} key={e} />)
                        }
                    </Table>
            }
        </div>
    );
}

export default PreArbitrationRefuseAnalysisListModal;