import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import DefaultLayout from "../layout/defaultLayout";
import ApprovalListPage from "../views/approval/approvalList";
import { NotFoundPage } from "../views/common/404";
import { NothingPage } from "../views/common/nothing";
import GuidePage from "../views/guide";
import LoginForm from "../views/login/login";
import RegisterStaff from "../views/login/register/registerStaff";
import RegisterStaffPending from "../views/login/register/registerStaffPending";
import Wizard from "../views/login/register/wizard";
import ChangePasswordFormPage from "../views/mine/password";
import AddInfoFormPage from "../views/order/actions/addInfoForm";
import OrderCockpit from "../views/order/orderCockpit";
import OrderList from "../views/order/orderList";
import PreArbitrationAttachmentFormModal from "../views/preArbitration/actions/preArbitrationAttachmentForm";
import PreArbitrationDefendantFormModal from "../views/preArbitration/actions/preArbitrationDefendantForm";
import PreArbitrationFormModal from "../views/preArbitration/actions/preArbitrationForm";
import PreArbitrationPlaintiffFormModal from "../views/preArbitration/actions/preArbitrationPlaintiffForm";
import PreArbitrationThirdPartyFormModal from "../views/preArbitration/actions/preArbitrationThirdPartyForm";
import PreArbitrationList from "../views/preArbitration/preArbitrationList";
import LaborDisputeHandlingTableFormModal from "../views/preArbitration/tables/laborDisputeHandlingTable";
import SocialIssuesRegistryTableFormModal from "../views/preArbitration/tables/socialIssuesRegistryTable";
import PreArbitrationTablesModal from "../views/preArbitration/tables/tableList";
import PreArbitrationRefuseAnalysisListModal from "../views/preArbitration/actions/preArbitrationRefuseAnalysis";
interface IRoute {
    path: string | undefined;
    name: string;
    element: JSX.Element;
    children?: IRoute[];
}

/**
 * 路由统一配置
 */
export const routes: IRoute[] = [
    {
        path: '/',
        name: '首页',
        element: <GuidePage />,
    }, {
        path: '/order',
        name: '业务管理',
        element: <DefaultLayout><OrderList /></DefaultLayout>,
    }, {
        path: '/infoForm',
        name: '登记信息',
        element: <DefaultLayout><AddInfoFormPage /></DefaultLayout>,
    }, {
        path: '/data',
        name: '数据管理',
        element: <DefaultLayout><OrderCockpit /></DefaultLayout>,
    }, {
        path: '/charts',
        name: '报表管理',
        element: <DefaultLayout><NothingPage /></DefaultLayout>,
    }, {
        path: '/preArbitration',
        name: '裁前管理',
        element: <DefaultLayout><PreArbitrationList /></DefaultLayout>,
    }, {
        path: '/preArbitration/form',
        name: '登记案件',
        element: <DefaultLayout><PreArbitrationFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/plaintiff',
        name: '登记申请人管理',
        element: <DefaultLayout><PreArbitrationPlaintiffFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/defendant',
        name: '登记被申请人管理',
        element: <DefaultLayout><PreArbitrationDefendantFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/thirdParty',
        name: '登记第三人管理',
        element: <DefaultLayout><PreArbitrationThirdPartyFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/attachment',
        name: '补充登记附件',
        element: <DefaultLayout><PreArbitrationAttachmentFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/tables',
        name: '表单列表',
        element: <DefaultLayout><PreArbitrationTablesModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/laborDisputeHandling',
        name: '劳动人事争议调解登记处理表',
        element: <DefaultLayout><LaborDisputeHandlingTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/socialIssuesRegistry',
        name: '涉人社领域问题登记表',
        element: <DefaultLayout><SocialIssuesRegistryTableFormModal /></DefaultLayout>,
    }, {
        path: '/preArbitration/refuseAnalysis',
        name: '地区统计拒绝分析',
        element: <DefaultLayout><PreArbitrationRefuseAnalysisListModal /></DefaultLayout>,
    }, {
        path: '/approval',
        name: '审核管理',
        element: <DefaultLayout><ApprovalListPage /></DefaultLayout>,
    }, {
        path: '/password',
        name: '密码修改',
        element: <DefaultLayout><ChangePasswordFormPage /></DefaultLayout>,
    }, {
        path: '/login',
        name: '登录',
        element: <Wizard />,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <LoginForm />,
            },
        ]
    }, {
        path: '/register',
        name: '注册',
        element: <Wizard />,
        children: [
            {
                path: undefined,
                name: 'index',
                element: <RegisterStaff />,
            },
            {
                path: 'pending',
                name: '注册待审批',
                element: <RegisterStaffPending />,
            },
        ]
    }, {
        path: '*',
        name: '无页面',
        element: <NotFoundPage />
    }
]

// 遍历路由树生成route列表
function getRouteList(routes: IRoute[] | undefined): RouteObject[] {
    if (!routes) return [];

    return routes.map(route => {
        let item: any = {
            path: route.path,
            element: route.element,
        };
        if (route.name === 'index') {
            item.index = true;
        } else {
            item.children = getRouteList(route.children);
        }
        return item;
    });
}

// 构建router
export const router = createBrowserRouter(getRouteList(routes));