import React, { ChangeEvent, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

// apis
import { FileUpload, getDictList, IDictData, uploadFile } from '../../apis/common';

// interface
import { downloadTemplate, exportIOC, exportIOCReport, exportOrderList, getOrderList, getSelectRegion, IExportIOCForm, importIocWorksheetWord, importWorksheetExcel, importWorksheetExcelZS, importWorksheetWord, IOrderListData, IOrderListSearchForm, ISelectRegionData } from '../../apis/order';
import { Attachment } from '../../apis/attachment';

// antd
import { Space, Table, Dropdown, Menu, Select, Button, Modal, message, Card, Upload, UploadFile, Input, Form, Pagination, Empty, Grid, Cascader } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import DatePicker from '../../components/customAntd/DatePicker';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from "rc-upload/lib/interface";
import OrderDetailModal from './actions/orderDetail';
import { globalAuthSigns, useHasAuth } from '../../utils/auth';
import { useDebounce } from 'ahooks';
import TextArea from 'antd/lib/input/TextArea';
import { useNavigate } from 'react-router-dom';
import AddInfoFormPage from './actions/addInfoForm';
import { Cascader as CascaderMobile } from 'antd-mobile';

const { Column } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { useBreakpoint } = Grid;


function OrderList() {
    const screens = useBreakpoint();
    const navigate = useNavigate();

    // 上传器重置
    const [uploadModalKey, setUploadModalKey] = useState(Math.random());


    // 权限检查
    const authRes = useHasAuth([
        globalAuthSigns.worksheet_import,
        globalAuthSigns.worksheet_importZS,
        globalAuthSigns.worksheet_iocExport,
        globalAuthSigns.worksheet_iocExportReport
    ]);
    // 组件全局权限引用
    const [hasWorksheetImportAuth, setHasWorksheetImportAuth] = useState(false);
    const [hasWorksheetImportZSAuth, setHasWorksheetImportZSAuth] = useState(false);
    const [hasWorksheetIOCExportAuth, setHasWorksheetIOCExportAuth] = useState(false);
    const [hasWorksheetIOCExportReportAuth, setHasWorksheetIOCExportReportAuth] = useState(false);
    useEffect(() => {
        authRes.then(value => {
            setHasWorksheetImportAuth(value[0]);
            setHasWorksheetImportZSAuth(value[1]);
            setHasWorksheetIOCExportAuth(value[2]);
            setHasWorksheetIOCExportReportAuth(value[3]);
        })
    }, [authRes]);

    // 搜索表单
    const [form, setForm] = useState<IOrderListSearchForm>({
        page: 1,
        size: 10
    });

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IOrderListData)[]>([]);
    const [mListData, setMListData] = useState<(IOrderListData)[]>([]);

    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IOrderListSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getOrderList(tempForm);
            setTotal(res.data.total);
            setMListData(res.data.list);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    const resetData = () => {
        let newForm = {
            page: 1,
            size: 20
        }
        setForm(newForm);
        loadData(newForm);
    }


    // 时间段类型
    const [timeType, setTimeType] = useState<IDictData>();
    const handleTimeTypeChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                time_type: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    // 来源
    const [source, setSource] = useState<IDictData>();
    const handleSourceChange = (value: number) => {
        console.log(`selected ${value}`);
        setForm((form) => {
            let newForm = {
                ...form,
                source: value,
            };
            loadData(newForm);
            return newForm;
        });
    };

    useEffect(() => {
        getDictList(['global_time_type', 'worksheet_source']).then(res => {
            setTimeType(res.data.global_time_type);
            setSource(res.data.worksheet_source);
        });
    }, []);


    // 关键词搜索
    const keywordsDebouncedValue = useDebounce(form.keywords, { wait: 500 });
    const handleKeywordsChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            keywords: value.target.value
        };
        setForm(newForm);
    }

    // 具体情况搜索
    const specificCaseDebouncedValue = useDebounce(form.specific_case, { wait: 500 });
    const handleSpecificCaseChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        let newForm = {
            ...form,
            specific_case: value.target.value
        };
        setForm(newForm);
    }

    useEffect(() => {
        loadData(form);
    }, [specificCaseDebouncedValue, keywordsDebouncedValue]);

    // 接单时间段
    const handleReceiveChange = (_: any, formatString: [string, string]) => {
        if (!formatString[0] || !formatString[1]) {
            setForm((form) => {
                let newForm = {
                    ...form,
                    receive_at: undefined,
                };
                loadData(newForm);
                return newForm;
            });
        } else {
            setForm((form) => {
                let newForm = {
                    ...form,
                    receive_at: formatString,
                };
                loadData(newForm);
                return newForm;
            });
        }
    }

    // 导出数据
    const exportData = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定要导出当前列表吗？',
            onOk() {
                setLoading(true);
                exportOrderList(form).then(res => {
                    window.open(res.data.full_link, '_blank');
                }).finally(() => setLoading(false));
            },
        });
    };

    // 上传点击框ref
    const inputRef = React.createRef<HTMLInputElement>();
    const upload = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        setLoading(true);
        // 上传到服务器
        uploadFile({ file: event.target.files![0], type: 'order' }).then(uploadRes => {
            importWorksheetExcel({ file_id: uploadRes.data.file_id })
                .then(res => {
                    if (res.data.has_fail) {
                        confirm({
                            title: '导入失败',
                            icon: <ExclamationCircleOutlined />,
                            content: (
                                <div>
                                    <div>{res.data.tips}</div>
                                    <div>是否下载失败结果文档</div>
                                </div>
                            ),
                            onOk() {
                                window.open(res.data.full_link, '_blank');
                            },
                        });
                    } else {
                        Modal.info({
                            title: '导入结果',
                            content: res.data.tips,
                        });
                    }
                }).finally(() => {
                    setLoading(false);
                    loadData();
                });
        });
    };

    // specific_case具体情况
    const [remark, setRemark] = useState<string>();
    const onRemarkChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        console.log(e.target.value);
        setRemark(e.target.value);
    }

    // 弹窗
    const [modalData, setModalData] = useState<{ isOpen: boolean, type: 'word' | 'ioc' }>({
        isOpen: false,
        type: 'word'
    })
    useEffect(() => {
        resetFileUploadList();
    }, [modalData]);
    const handleOk = () => {
        if (attachments.length > 0) {
            switch (modalData.type) {
                case 'word':
                    importWorksheetWord({
                        file_ids: attachments.map(file => file.file_id),
                        specific_case: remark
                    }).then(res => {
                        Modal.info({
                            title: '导入结果',
                            content: res.data.tips,
                            onOk() {
                                resetFileUploadList();
                                setModalData({
                                    ...modalData,
                                    isOpen: false
                                });
                            },
                        });
                    }).finally(() => {
                        loadData();
                    });
                    break;
                case 'ioc':
                    importIocWorksheetWord({
                        file_ids: attachments.map(file => file.file_id),
                        specific_case: remark
                    }).then(res => {
                        Modal.info({
                            title: '导入结果',
                            content: res.data.tips,
                            onOk() {
                                resetFileUploadList();
                                setModalData({
                                    ...modalData,
                                    isOpen: false
                                });
                            },
                        });
                    }).finally(() => {
                        loadData();
                    });
                    break;
            }


        } else {
            message.warning('至少上传一个文件');
        }
        // form.validateFields().then(values => {
        //     approveTrigger(form.getFieldsValue(true));
        //     setIsModalOpen(false);
        // });
    };
    const handleCancel = () => {
        resetFileUploadList();
        setModalData({
            ...modalData,
            isOpen: false
        });
    };

    const resetFileUploadList = () => {
        setUploadModalKey(Math.random());
        setRemark("");
        setAttachments([]);
    }

    // 上传附件
    const [attachments, setAttachments] = useState<FileUpload[]>([]);
    const checkFileSize = (file: RcFile) => {
        if ((file.size / 1024 / 1024) > 50) {
            message.error(`${file.name} 文件超出限制大小`);
            return Upload.LIST_IGNORE;
        }
        return true;
    };
    const customRequest = ({ onSuccess, onError, file, onProgress }: UploadRequestOption<any>) => {
        uploadFile({ file: file as Blob, type: 'default', uploadProgressCallback: onProgress }).then(res => {
            onSuccess!(res.data);
            setAttachments(data => {
                let newData = [...data, res.data];
                return newData;
            });
        }).catch(onError);
    }
    const onAttachmentDownload = (file: UploadFile<any>) => {
        console.log(file);
        let res: FileUpload = file.response;
        window.open(res.full_link, "_blank");
    }

    // 详情弹窗
    const [toDetailModal, setDetailModal] = useState<{
        isModalVisible: boolean,
        data: { id: number }
    }>();
    const toDetail = (id: number) => {
        setDetailModal({
            isModalVisible: true,
            data: { id: id }
        })
    };

    // 下载导入模板
    const toDownload = async () => {
        let res = await downloadTemplate();
        window.open(res.data.full_link, '_blank');
    }

    // 登记信息
    const [toAddEditModal, setAddEditModal] = useState<{
        isModalVisible: boolean,
    }>();
    const onAddEditClose = () => {
        setAddEditModal({
            isModalVisible: false
        })
    };
    const onAddEditFinish = () => {
        loadData();
        setAddEditModal({
            isModalVisible: false
        })
    }
    const toAdd = () => {
        if (screens.xs) {
            navigate('/infoForm');
        } else {
            setAddEditModal({
                isModalVisible: true,
            })
        }
    }

    // 可选街道
    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }
    const [options, setOptions] = useState<Option[]>([]);
    useEffect(() => {
        let nodes: ISelectRegionData[] = [];
        const build = (list: ISelectRegionData[]): Option[] => {
            return list.map(item => ({
                label: item.name,
                value: item.id,
                children: item.children.length > 0 ? build(item.children) : undefined
            }));
        }
        getSelectRegion().then(res => {
            nodes.push(...res.data.list);
            setOptions(build(nodes));
        });
    }, []);

    // 导出IOC工单压缩包
    const [exportIOCForm] = Form.useForm<IExportIOCForm>();
    const [isIOCModalOpen, setIsIOCModalOpen] = useState(false);
    const handleIOCOk = async () => {
        await exportIOCForm.validateFields();

        setLoading(true);

        const _form = exportIOCForm.getFieldsValue(true);
        exportIOC({
            time_type: _form.time_type ?? undefined,
            province_id: _form.cur_id ? _form.cur_id[0] : undefined,
            city_id: _form.cur_id ? _form.cur_id[1] : undefined,
            area_id: _form.cur_id ? _form.cur_id[2] : undefined,
            street_id: _form.cur_id ? _form.cur_id[3] : undefined,
            receive_at: _form.receive_at_dayjs ? _form.receive_at_dayjs.map((e: Dayjs) => e.format('YYYY-MM-DD')) : undefined
        }).then(res => {
            setTimeout(() => {
                window.open(res.data.full_link, '_blank');
            }, 100);
        }).finally(() => {
            setLoading(false);
            handleIOCCancel();
        });
    };
    const handleIOCCancel = () => {
        exportIOCForm.resetFields();
        setIsIOCModalOpen(false);
    };

    // IOC结果报告压缩
    const [exportIOCReportForm] = Form.useForm<IExportIOCForm>();
    const [isIOCReportModalOpen, setIsIOCReportModalOpen] = useState(false);
    const handleIOCReportOk = async () => {
        await exportIOCReportForm.validateFields();

        setLoading(true);

        const _form = exportIOCReportForm.getFieldsValue(true);
        exportIOCReport({
            time_type: _form.time_type ?? undefined,
            province_id: _form.cur_id ? _form.cur_id[0] : undefined,
            city_id: _form.cur_id ? _form.cur_id[1] : undefined,
            area_id: _form.cur_id ? _form.cur_id[2] : undefined,
            street_id: _form.cur_id ? _form.cur_id[3] : undefined,
            receive_at: _form.receive_at_dayjs ? _form.receive_at_dayjs.map((e: Dayjs) => e.format('YYYY-MM-DD')) : undefined
        }).then(res => {
            setTimeout(() => {
                window.open(res.data.full_link, '_blank');
            }, 100);
        }).finally(() => {
            setLoading(false);
            handleIOCReportCancel();
        });
    };
    const handleIOCReportCancel = () => {
        exportIOCReportForm.resetFields();
        setIsIOCReportModalOpen(false);
    };

    // 中山市模板导入
    const [isZSModalOpen, setIsZSModalOpen] = useState(false);
    useEffect(() => {
        resetFileUploadList();
    }, [isZSModalOpen]);
    const handleZSOk = () => {
        if (attachments.length > 0) {
            importWorksheetExcelZS({
                file_ids: attachments.map(file => file.file_id),
            }).then(res => {
                Modal.info({
                    title: '导入结果',
                    content: res.data.tips,
                    onOk() {
                        resetFileUploadList();
                        setIsZSModalOpen(false);
                    },
                });
            }).finally(() => {
                loadData();
            });
        } else {
            message.warning('至少上传一个文件');
        }
    };
    const handleZSCancel = () => {
        resetFileUploadList();
        setIsZSModalOpen(false);
    };


    return (
        <div className='address inner-page'>
            <div className='search'>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="时间段类型"
                    value={form.time_type}
                    onChange={handleTimeTypeChange}
                >
                    {timeType?.options.map(option => (
                        <Option key={option.value}>{option.name}</Option>
                    ))}
                </Select>
                <Select
                    allowClear
                    className="search__item"
                    showArrow
                    placeholder="信息来源"
                    value={form.source}
                    onChange={handleSourceChange}
                >
                    {source?.options.map(option => (
                        <Option key={option.value}>{option.name}</Option>
                    ))}
                </Select>
                <Input
                    className="search__item"
                    value={form.specific_case}
                    onChange={handleSpecificCaseChange}
                    placeholder="具体情况搜索"
                />

                <RangePicker
                    className="search__item"
                    style={{ 'width': '300px' }}
                    value={[
                        form.receive_at && form.receive_at[0] ? dayjs(form.receive_at[0]) : null,
                        form.receive_at && form.receive_at[1] ? dayjs(form.receive_at[1]) : null,
                    ]}
                    onChange={handleReceiveChange}
                    format='YYYY-MM-DD'
                    placeholder={['入职开始日期', '入职结束日期']}
                />
                <Input
                    className="search__item"
                    value={form.keywords}
                    onChange={handleKeywordsChange}
                    placeholder="工单号、投诉人姓名、手机号码搜索"
                />

                <Button
                    className="search__button"
                    icon={<CloseOutlined />}
                    loading={loading}
                    onClick={() => resetData()}
                >
                    重置筛选
                </Button>

                <Dropdown.Button
                    className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                    style={{ marginRight: screens.xs ? 15 : 0 }}
                    type="primary"
                    loading={false}
                    trigger={['click', 'hover']}
                    overlay={
                        <Menu
                            items={[
                                {
                                    key: 'download',
                                    disabled: !hasWorksheetImportAuth,
                                    label: (
                                        <div>下载导入模板</div>
                                    ),
                                    onClick: toDownload,
                                },
                                {
                                    key: 'excelImport',
                                    disabled: !hasWorksheetImportAuth,
                                    label: (
                                        <div>
                                            Excel导入
                                            <input
                                                ref={inputRef}
                                                style={{ display: 'none' }}
                                                type="file"
                                                onChange={upload}
                                                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                            />
                                        </div>
                                    ),
                                    onClick: () => {
                                        if (inputRef.current) {
                                            inputRef.current.value = '';
                                            inputRef.current.click();
                                        }
                                    }
                                },
                                {
                                    key: 'excelImportZS',
                                    disabled: !hasWorksheetImportZSAuth,
                                    label: (
                                        <div>中山市模板导入</div>
                                    ),
                                    onClick: () => {
                                        setIsZSModalOpen(true);
                                    }
                                },
                                {
                                    key: 'wordImport',
                                    disabled: !hasWorksheetImportAuth,
                                    label: (
                                        <div>市长直通车word导入</div>
                                    ),
                                    onClick: () => {
                                        setModalData({
                                            isOpen: true,
                                            type: 'word'
                                        });
                                    }
                                },
                                {
                                    key: 'iocImport',
                                    disabled: !hasWorksheetImportAuth,
                                    label: (
                                        <div>IOC工单word导入</div>
                                    ),
                                    onClick: () => {
                                        setModalData({
                                            isOpen: true,
                                            type: 'ioc'
                                        });
                                    }
                                },
                                {
                                    key: 'export',
                                    label: (
                                        <div>导出报表</div>
                                    ),
                                    onClick: exportData,
                                },
                                {
                                    key: 'exportIOC',
                                    disabled: !hasWorksheetIOCExportAuth,
                                    label: (
                                        <div>导出IOC压缩包</div>
                                    ),
                                    onClick: () => {
                                        setIsIOCModalOpen(true);
                                    }
                                },
                                {
                                    key: 'exportIOCReport',
                                    disabled: !hasWorksheetIOCExportReportAuth,
                                    label: (
                                        <div>IOC结果报告压缩</div>
                                    ),
                                    onClick: () => {
                                        setIsIOCReportModalOpen(true);
                                    }
                                },
                            ].filter(item => !item.disabled)}
                        />
                    }>
                    更多操作
                </Dropdown.Button>

                <Button
                    className={`search__last-button ${screens.xs ? 'xs' : ''}`}
                    icon={<PlusOutlined />}
                    loading={loading}
                    onClick={toAdd}
                >
                    登记信息
                </Button>
            </div>
            {
                screens.xs
                    ? <div>
                        {
                            mListData.length > 0 ? mListData.map(item =>
                                <Card className='m-card' onClick={() => item.id ? toDetail(item.id ?? 0) : null}>
                                    <div className='m-card_item'>
                                        <div>时间：</div>
                                        <div>{item.receive_at ? `${item.receive_at} ${item.time_type_text}` : '无'}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>工单号：</div>
                                        <div>{item.order_no}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>省-市-区：</div>
                                        <div>{`${item.province_text}-${item.city_text}-${item.area_text}`}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>街道：</div>
                                        <div>{item.street_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>投诉：</div>
                                        <div>{item.name}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>调解状态：</div>
                                        <div>{item.status_text}</div>
                                    </div>
                                    <div className='m-card_item'>
                                        <div>导入时间：</div>
                                        <div>{item.created_at}</div>
                                    </div>
                                </Card>
                            )
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                        }

                        <Pagination
                            current={form.page}
                            onChange={onPageChange}
                            showTotal={total => `共 ${total} 项`}
                            total={total}
                            simple
                        />
                    </div>
                    : <Table
                        dataSource={listData}
                        size='small'
                        loading={loading}
                        scroll={{ x: 800 }}
                        pagination={{
                            position: ['bottomRight'],
                            size: "small",
                            total: total,
                            showTotal: total => `共 ${total} 项`,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            onChange: onPageChange
                        }}>
                        <Column
                            title="时间"
                            dataIndex="receive_at"
                            key="receive_at"
                            render={(_: any, record: IOrderListData | null) => (
                                record && record.receive_at ? `${record.receive_at} ${record.time_type_text}` : '无'
                            )}
                        />
                        <Column title="工单号" dataIndex="order_no" key="order_no" />
                        <Column
                            title="省-市-区"
                            dataIndex="province_text"
                            key="province_text"
                            render={(_: any, record: IOrderListData | null) => (
                                record ? `${record.province_text}-${record.city_text}-${record.area_text}` : ''
                            )}
                        />
                        <Column title="街道" dataIndex="street_text" key="street_text" />
                        <Column title="投诉" dataIndex="name" key="name" />
                        <Column title="调解状态" dataIndex="status_text" key="status_text" />
                        <Column title="导入时间" dataIndex="created_at" key="created_at" />
                        <Column
                            title="操作"
                            key="action"
                            fixed="right"
                            render={(_: any, record: IOrderListData | null) => (
                                <Space>
                                    <Dropdown.Button
                                        trigger={['click', 'hover']}
                                        size='small'
                                        type="primary"
                                        loading={loading}
                                        overlay={
                                            <Menu
                                                items={[
                                                    {
                                                        key: 'detail',
                                                        disabled: !record?.id,
                                                        label: (
                                                            <div>查看详情</div>
                                                        ),
                                                        onClick: () => toDetail(record?.id ?? 0),
                                                    },
                                                ]}
                                            />
                                        }>
                                        操作
                                    </Dropdown.Button>
                                </Space>
                            )}
                        />
                    </Table>
            }


            <Modal
                title={
                    modalData.type === 'word'
                        ? '市长直通车word导入'
                        : modalData.type === 'ioc'
                            ? 'IOC工单word导入'
                            : '未知表单'
                }
                confirmLoading={loading}
                open={modalData.isOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                <Form.Item
                    label="具体情况"
                >
                    <TextArea rows={2} placeholder="请填写具体情况" onChange={onRemarkChange} value={remark} />
                </Form.Item>

                <Upload
                    key={uploadModalKey + 1}
                    multiple
                    beforeUpload={checkFileSize}
                    customRequest={customRequest}
                    showUploadList={{ showDownloadIcon: true }}
                    onDownload={onAttachmentDownload}
                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                >
                    <Button icon={<UploadOutlined />}>点击上传Word文档</Button>
                </Upload>
            </Modal>

            <Modal title="中山市模板导入" confirmLoading={loading} open={isZSModalOpen} onOk={handleZSOk} onCancel={handleZSCancel}>
                <Upload
                    key={uploadModalKey + 2}
                    multiple
                    beforeUpload={checkFileSize}
                    customRequest={customRequest}
                    showUploadList={{ showDownloadIcon: true }}
                    onDownload={onAttachmentDownload}
                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                >
                    <Button icon={<UploadOutlined />}>点击上传文件</Button>
                </Upload>
            </Modal>

            {/* 详情弹窗 */}
            {
                toDetailModal && <OrderDetailModal
                    data={toDetailModal.data}
                    visible={toDetailModal.isModalVisible}
                    onCancel={() => {
                        setDetailModal({
                            ...toDetailModal,
                            isModalVisible: false
                        })
                    }}
                />
            }

            {/* 登记信息 */}
            {
                toAddEditModal && <Modal
                    maskClosable={false}
                    visible={toAddEditModal.isModalVisible}
                    title={`登记信息`}
                    onCancel={onAddEditClose}
                    width={1000}
                    footer={null}
                >
                    <AddInfoFormPage onFinish={onAddEditFinish}></AddInfoFormPage>
                </Modal>
            }

            <Modal title="导出IOC工单压缩包" confirmLoading={loading} open={isIOCModalOpen} onOk={handleIOCOk} onCancel={handleIOCCancel}>
                <Form
                    form={exportIOCForm}
                >
                    <Form.Item
                        label="地区"
                        name="cur_id"
                        trigger={screens.xs ? 'onConfirm' : undefined}
                    >
                        {
                            screens.xs
                                ? <CascaderMobile
                                    options={options as any}
                                >
                                    {(items, actions) => (
                                        <>
                                            <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                            {
                                                items.every(item => item === null)
                                                    ? '请选择地址'
                                                    : items.map(item => item?.label ?? '请选择地址').join('-')
                                            }
                                        </>
                                    )}
                                </CascaderMobile>
                                : <Cascader options={options} placeholder="请选择地址" />
                        }
                    </Form.Item>
                    <Form.Item
                        label="接单日期"
                        name="receive_at_dayjs"
                    >
                        <RangePicker
                            format='YYYY-MM-DD'
                            placeholder={['开始日期', '结束日期']}
                        />
                    </Form.Item>
                    <Form.Item
                        label="时间段"
                        name="time_type"

                    >
                        <Select showArrow>
                            {timeType?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="IOC结果报告压缩" confirmLoading={loading} open={isIOCReportModalOpen} onOk={handleIOCReportOk} onCancel={handleIOCReportCancel}>
                <Form
                    form={exportIOCReportForm}
                >
                    <Form.Item
                        label="地区"
                        name="cur_id"
                        trigger={screens.xs ? 'onConfirm' : undefined}
                    >
                        {
                            screens.xs
                                ? <CascaderMobile
                                    options={options as any}
                                >
                                    {(items, actions) => (
                                        <>
                                            <Button onClick={actions.open} style={{ marginRight: 10 }}>选择</Button>
                                            {
                                                items.every(item => item === null)
                                                    ? '请选择地址'
                                                    : items.map(item => item?.label ?? '请选择地址').join('-')
                                            }
                                        </>
                                    )}
                                </CascaderMobile>
                                : <Cascader options={options} placeholder="请选择地址" />
                        }
                    </Form.Item>
                    <Form.Item
                        label="接单日期"
                        name="receive_at_dayjs"
                    >
                        <RangePicker
                            format='YYYY-MM-DD'
                            placeholder={['开始日期', '结束日期']}
                        />
                    </Form.Item>
                    <Form.Item
                        label="时间段"
                        name="time_type"

                    >
                        <Select showArrow>
                            {timeType?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default OrderList;