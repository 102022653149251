import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../routes/routes';

/**
 * 面包屑
 * 路径根据routes自动生成
 */
function BreadcrumbBar() {
    const breadcrumbNameMap: Record<string, string> = routes.reduce<Record<string, string>>((perv, route) => {
        if (route.path) perv[route.path] = route.name;
        return perv;
    }, {});

    console.log(breadcrumbNameMap);


    const location = useLocation();
    console.log(location);
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const extraBreadcrumbItems = pathSnippets.filter((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return breadcrumbNameMap[url];
    }).map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return (
            <Breadcrumb.Item key={url}>
                <Link to={url}>{breadcrumbNameMap[url]}</Link>
            </Breadcrumb.Item>
        );
    });

    return <Breadcrumb>{extraBreadcrumbItems}</Breadcrumb>;
}

export default BreadcrumbBar;