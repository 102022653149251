import { ChangeEvent, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import Compressor from 'compressorjs';

// antd
import { Grid, Spin, Form, Button, Divider, Row, Col, Input, Select, InputNumber, Radio, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import DatePicker from '../../../components/customAntd/DatePicker';

// interface
import { INaturalPeople, IThirdPartyNaturalPeople } from '../../../apis/preArbitration';
import { getDictList, getIdcardOcrData, IDictData, uploadFile } from '../../../apis/common';

// assets
import idcardBackImg from '@assets/images/register/idcard_back.png';
import idcardFrontImg from '@assets/images/register/idcard_front.png';

const { Option } = Select;
const { useBreakpoint } = Grid;
const { RangePicker } = DatePicker;

export interface INaturalPeopleForm extends INaturalPeople {
    index?: number,
    idcard_front_img_link?: string,
    idcard_back_img_link?: string,
}

export interface IThirdPartyNaturalPeopleForm extends IThirdPartyNaturalPeople {
    index?: number,
    natural_people: INaturalPeopleForm
}

interface CollectionCreateFormProps {
    formName: string,
    data?: INaturalPeopleForm | IThirdPartyNaturalPeopleForm,
    thirdParty?: boolean
}

function PreArbitrationNaturalPeopleFormModal({
    formName,
    thirdParty,
    data
}: CollectionCreateFormProps) {
    const [form] = Form.useForm<INaturalPeopleForm>();
    useEffect(() => {
        form.resetFields();
        if (data) {
            if (thirdParty) {
                form.setFieldsValue({
                    ...(data as IThirdPartyNaturalPeopleForm).natural_people,
                    index: (data as IThirdPartyNaturalPeopleForm).index
                });
                if ((data as IThirdPartyNaturalPeopleForm).natural_people.idcard_front_img_link) {
                    setInputIdcardFrontImg((data as IThirdPartyNaturalPeopleForm).natural_people.idcard_front_img_link);
                }
                if ((data as IThirdPartyNaturalPeopleForm).natural_people.idcard_back_img_link) {
                    setInputIdcardBackImg((data as IThirdPartyNaturalPeopleForm).natural_people.idcard_back_img_link);
                }

                // 身份证日期
                if ((data as IThirdPartyNaturalPeopleForm).natural_people.idcard_begin_date && (data as IThirdPartyNaturalPeopleForm).natural_people.idcard_expire_date) {
                    form.setFieldValue('idcard_date', [dayjs((data as IThirdPartyNaturalPeopleForm).natural_people.idcard_begin_date), dayjs((data as IThirdPartyNaturalPeopleForm).natural_people.idcard_expire_date)]);
                } else if ((data as IThirdPartyNaturalPeopleForm).natural_people.is_idcard_long_time && (data as IThirdPartyNaturalPeopleForm).natural_people.idcard_begin_date) {
                    form.setFieldValue('idcard_date_long', dayjs((data as IThirdPartyNaturalPeopleForm).natural_people.idcard_begin_date));
                }
            } else {
                form.setFieldsValue((data as INaturalPeopleForm));
                if ((data as INaturalPeopleForm).idcard_front_img_link) {
                    setInputIdcardFrontImg((data as INaturalPeopleForm).idcard_front_img_link);
                }
                if ((data as INaturalPeopleForm).idcard_back_img_link) {
                    setInputIdcardBackImg((data as INaturalPeopleForm).idcard_back_img_link);
                }

                // 身份证日期
                if ((data as INaturalPeopleForm).idcard_begin_date && (data as INaturalPeopleForm).idcard_expire_date) {
                    form.setFieldValue('idcard_date', [dayjs((data as INaturalPeopleForm).idcard_begin_date), dayjs((data as INaturalPeopleForm).idcard_expire_date)]);
                } else if ((data as INaturalPeopleForm).is_idcard_long_time && (data as INaturalPeopleForm).idcard_begin_date) {
                    form.setFieldValue('idcard_date_long', dayjs((data as INaturalPeopleForm).idcard_begin_date));
                }
            }
        }
    }, [data]);

    const [nation, setNation] = useState<IDictData>();
    useEffect(() => {
        getDictList(['sys_nation']).then(res => {
            setNation(res.data.sys_nation);
        });
    }, []);

    // 身份证有效期时间段
    const handleIdcardDateChange = (_: any, formatString: [string, string]) => {
        form.setFieldsValue({
            idcard_begin_date: formatString[0],
            idcard_expire_date: formatString[1],
        })
    }
    const handleIdcardLongDateChange = (date: Dayjs | null, dateString: string) => {
        form.setFieldsValue({
            idcard_begin_date: dateString,
        })
    }

    // 上传身份证国徽面
    const [inputIdcardFrontLoading, setInputIdcardFrontLoading] = useState(false);
    const inputIdcardFrontRef = React.createRef<HTMLInputElement>();
    const [inputIdcardFrontImg, setInputIdcardFrontImg] = useState<string>();
    const uploadIdcardFront = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputIdcardFrontLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'idcard' }).then(res => {
                    if ('data' in res) {
                        setInputIdcardFrontImg(res.data.full_link);
                        form.setFieldValue('idcard_front_img', res.data.file_id);
                        form.setFieldValue('idcard_front_img_link', res.data.full_link);
                        getIdcardOcrData({ fid: res.data.file_id }).then(res => {
                            let data = res.data;
                            form.setFieldValue('is_idcard_long_time', data.is_idcard_long_time);
                            if (data.valid_date_start && data.valid_date_end) {
                                form.setFieldValue('idcard_date', [dayjs(data.valid_date_start), dayjs(data.valid_date_end)]);
                            } else if (data.is_idcard_long_time && data.valid_date_start) {
                                form.setFieldValue('idcard_date_long', dayjs(data.valid_date_start));
                            }
                            form.setFieldsValue({
                                idcard_begin_date: data.valid_date_start,
                                idcard_expire_date: data.valid_date_end,
                            });
                        });
                    }
                    setInputIdcardFrontLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputIdcardFrontLoading(false);
            },
        });
    };
    // 上传身份证人像面
    const [inputIdcardBackLoading, setInputIdcardBackLoading] = useState(false);
    const inputIdcardBackRef = React.createRef<HTMLInputElement>();
    const [inputIdcardBackImg, setInputIdcardBackImg] = useState<string>();
    const uploadIdcardBack = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        let files = event.target.files;
        setInputIdcardBackLoading(true);

        // 压缩
        new Compressor(files[0], {
            quality: 0.6,
            success(result) {
                let file = new File([result], files[0].name);
                uploadFile({ file: file, type: 'idcard' }).then(res => {
                    if ('data' in res) {
                        setInputIdcardBackImg(res.data.full_link);
                        form.setFieldValue('idcard_back_img', res.data.file_id);
                        form.setFieldValue('idcard_back_img_link', res.data.full_link);
                        getIdcardOcrData({ fid: res.data.file_id }).then(res => {
                            let data = res.data;
                            form.setFieldsValue({
                                real_name: data.name,
                                idcard: data.idcard,
                                nation_dict: data.nation_dict ? Number(data.nation_dict) : undefined,
                                age: data.age
                            });
                        });
                    }
                    setInputIdcardBackLoading(false);
                });
            },
            error(err) {
                console.log(err.message);
                message.error('压缩过程出现错误');
                setInputIdcardBackLoading(false);
            },
        });
    };

    // style
    const gutter = 16 + 8 * 2;

    return (
        <Form
            form={form}
            layout="vertical"
            name={formName}
            initialValues={{
                is_idcard_long_time: false,
                is_soldier: false,
                is_disability: false,
                is_default: false
            }}
        >
            <Row gutter={gutter}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Spin spinning={inputIdcardBackLoading}>
                        <img
                            src={inputIdcardBackImg ?? idcardBackImg}
                            style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                            alt="身份证人像面"
                            onClick={() => inputIdcardBackRef.current?.click()}
                        />
                    </Spin>

                    <input
                        ref={inputIdcardBackRef}
                        style={{ display: 'none' }}
                        type="file"
                        accept='image/jpeg,image/jpg,image/png'
                        onChange={uploadIdcardBack}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Spin spinning={inputIdcardFrontLoading}>
                        <img
                            src={inputIdcardFrontImg ?? idcardFrontImg}
                            style={{ width: "100%", height: 200, cursor: 'pointer', objectFit: 'contain' }}
                            alt="身份证国徽面"
                            onClick={() => inputIdcardFrontRef.current?.click()}

                        />
                    </Spin>
                    <input
                        ref={inputIdcardFrontRef}
                        style={{ display: 'none' }}
                        type="file"
                        accept='image/jpeg,image/jpg,image/png'
                        onChange={uploadIdcardFront}
                    />
                </Col>

                <Divider></Divider>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="申请人"
                        name="real_name"
                        rules={[{ required: true, message: '请输入申请人姓名' }]}
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="身份证"
                        name="idcard"
                        rules={[
                            { required: true, message: '请输入身份证号码' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('身份证格式错误'));
                                },
                            }),
                        ]}
                    >
                        <Input maxLength={18} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="身份证是否长期"
                        name="is_idcard_long_time"
                        rules={[{ required: true, message: '请选择身份证是否长期' }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.is_idcard_long_time !== curValues.is_idcard_long_time}
                    >
                        {({ getFieldValue, resetFields }) => {
                            let isLongTime = getFieldValue('is_idcard_long_time');
                            return (
                                isLongTime
                                    ? <Form.Item
                                        name="idcard_date_long"
                                        label="身份证有效期开始时间"
                                        rules={[{ required: true, message: '请选择身份证有效期开始时间' }]}
                                        shouldUpdate
                                    >
                                        <DatePicker
                                            style={{ 'width': '100%' }}
                                            onChange={handleIdcardLongDateChange}
                                            format='YYYY-MM-DD'
                                            placeholder='身份证有效期开始时间'
                                        />
                                    </Form.Item>
                                    : <Form.Item
                                        name="idcard_date"
                                        label="身份证有效期"
                                        rules={[{ required: true, message: '请选择身份证有效期' }]}
                                        shouldUpdate
                                    >
                                        <RangePicker
                                            style={{ 'width': '100%' }}
                                            onChange={handleIdcardDateChange}
                                            format='YYYY-MM-DD'
                                            placeholder={['身份证有效期开始时间', '身份证有效期结束时间']}
                                        />
                                    </Form.Item>
                            )
                        }}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="民族"
                        name="nation_dict"
                    >
                        <Select showArrow showSearch>
                            {nation?.options.map(option => (
                                <Option key={option.value} value={option.value}>{option.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="联系电话"
                        name="mobile"
                        rules={[
                            { required: true, message: '请输入联系电话' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (/^1[23456789]\d{9}$/.test(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('手机号格式错误'));
                                },
                            }),
                        ]}
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="年龄"
                        name="age"
                    >
                        <InputNumber max={200} min={0} addonAfter="岁" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                label="文书送达地址"
                name='effect_service_address'
                rules={[{ required: true, message: '请输入文书送达地址' }]}
            >
                <TextArea rows={1} />
            </Form.Item>

            <Form.Item
                label="设置为默认申请人"
                name="is_default"
            >
                <Radio.Group>
                    <Radio value={true}>是</Radio>
                    <Radio value={false}>否</Radio>
                </Radio.Group>
            </Form.Item>

            <Divider></Divider>

            <Button
                type="primary"
                block
                onClick={() => form.submit()}
            >
                {data ? <EditOutlined /> : <PlusOutlined />} {data ? '编辑' : '添加'}人员
            </Button>
        </Form>
    );
}

export default PreArbitrationNaturalPeopleFormModal;