import { useEffect, useState } from 'react';
import { Column, Pie, G2 } from '@antv/g2plot';
import { Button, Card, Table } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import dayjs, { Dayjs } from 'dayjs';

import { getAreaMediationData, getComplaintTypePercentageData, getMultiAreaMediationCountData, getPreArbitrationMediationData, getPreArbitrationTypePercentageData, getRegionStatList, getTimeFeedbackData, IRegionStatListData, IRegionStatListSearchForm } from '../../apis/order';
import DatePicker from '../../components/customAntd/DatePicker';
import './cockpit.scss';
import { globalAuthSigns, useHasAuth } from '../../utils/auth';
import PreArbitrationRefuseAnalysisListModal from '../preArbitration/actions/preArbitrationRefuseAnalysis';

const { RangePicker } = DatePicker;
const G = G2.getEngine('canvas');

function OrderCockpit() {
    // 权限检查
    const authRes = useHasAuth([
        globalAuthSigns.chart_plate1,
        globalAuthSigns.chart_plate2,
        globalAuthSigns.chart_plate3,
        globalAuthSigns.chart_plate4,
        globalAuthSigns.chart_plate5,
        globalAuthSigns.chart_plate6,
        globalAuthSigns.preWorksheet_stat
    ]);
    // 组件全局权限引用
    const [hasChartPlate1Auth, setHasChartPlate1AuthAuth] = useState(false); // 地区调解数据
    const [hasChartPlate2Auth, setHasChartPlate2AuthAuth] = useState(false); // 投诉类型百分比
    const [hasChartPlate3Auth, setHasChartPlate3AuthAuth] = useState(false); // 多地区案件数量
    const [hasChartPlate4Auth, setHasChartPlate4AuthAuth] = useState(false); // 时间反馈情况
    const [hasChartPlate5Auth, setHasChartPlate5AuthAuth] = useState(false); // 裁前调解成功率
    const [hasChartPlate6Auth, setHasChartPlate6AuthAuth] = useState(false); // 裁前投诉类型
    const [hasPreWorksheetStatAuth, setHasPreWorksheetStatAuth] = useState(false); // 地区统计拒绝分析

    useEffect(() => {
        authRes.then(value => {
            setHasChartPlate1AuthAuth(value[0]);
            setHasChartPlate2AuthAuth(value[1]);
            setHasChartPlate3AuthAuth(value[2]);
            setHasChartPlate4AuthAuth(value[3]);
            setHasChartPlate5AuthAuth(value[4]);
            setHasChartPlate6AuthAuth(value[5]);
            setHasPreWorksheetStatAuth(value[6]);
            loadPlot(value);
        })
    }, [authRes]);

    const rangePresets: Record<string, [Dayjs, Dayjs]> = {
        '最近三个月': [dayjs().add(-2, 'months'), dayjs()],
        '最近六个月': [dayjs().add(-5, 'months'), dayjs()],
        '最近一年': [dayjs().add(-11, 'months'), dayjs()],
    }

    const disabledDate = (current: Dayjs) => {
        if (!areaMediationDates) {
            return false;
        }
        const tooLate = areaMediationDates[0] && current.diff(areaMediationDates[0], 'months') >= 12;
        const tooEarly = areaMediationDates[1] && areaMediationDates[1].diff(current, 'months') >= 12;
        const future = current.isAfter(dayjs());
        return !!tooEarly || !!tooLate || !!future;
    };

    // 地区调解数据
    // search
    const [areaMediationDates, setAreaMediationDates] = useState<RangeValue<dayjs.Dayjs>>([dayjs().add(-5, 'months'), dayjs()]);
    const handleAreaMediationMonthChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setAreaMediationDates([dates[0], dates[1]]);
        }
    }
    // plot
    const [areaMediationPlot, setAreaMediationPlot] = useState<Column>();
    useEffect(() => {
        if (areaMediationPlot) areaMediationPlot.render();
    }, [areaMediationPlot]);
    // api
    const loadAreaMediationData = async () => {
        let areaMediationRes = await getAreaMediationData({
            begin_month: areaMediationDates![0]!.format('YYYY-MM'),
            end_month: areaMediationDates![1]!.format('YYYY-MM')
        });
        if (areaMediationPlot) {
            let data: Record<string, any>[] = [];
            areaMediationRes.data.list.forEach((item) => {
                data.push({
                    month: item.month,
                    count: item.fail_count,
                    type: '调解失败'
                });
                data.push({
                    month: item.month,
                    count: item.success_count,
                    type: '调解成功'
                });
            })

            areaMediationPlot.update({
                data
            });
        }
    }
    useEffect(() => {
        if (areaMediationPlot) loadAreaMediationData();
    }, [areaMediationDates, areaMediationPlot])

    // 多地区案件数据
    // search
    const [multiAreaMediationCountDates, setMultiAreaMediationCountDates] = useState<RangeValue<dayjs.Dayjs>>([dayjs().add(-5, 'months'), dayjs()]);
    const handleMultiAreaMediationCountMonthChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setMultiAreaMediationCountDates([dates[0], dates[1]]);
        }
    }
    // plot
    const [multiAreaMediationCountPlot, setMultiAreaMediationCountPlot] = useState<Column>();
    useEffect(() => {
        if (multiAreaMediationCountPlot) multiAreaMediationCountPlot.render();
    }, [multiAreaMediationCountPlot]);
    // api
    const loadMultiAreaMediationCountData = async () => {
        let multiAreaMediationCountRes = await getMultiAreaMediationCountData({
            begin_month: multiAreaMediationCountDates![0]!.format('YYYY-MM'),
            end_month: multiAreaMediationCountDates![1]!.format('YYYY-MM')
        });
        if (multiAreaMediationCountPlot) {
            let data: Record<string, any>[] = [];
            multiAreaMediationCountRes.data.list.forEach((item) => {
                item.region_list.forEach(region => {
                    data.push({
                        '月份': item.month,
                        '数量': region.count,
                        name: region.name
                    });
                })
            })

            multiAreaMediationCountPlot.update({
                data
            });
        }
    }
    useEffect(() => {
        if (multiAreaMediationCountPlot) loadMultiAreaMediationCountData();
    }, [multiAreaMediationCountDates, multiAreaMediationCountPlot])

    // 投诉类型百分比数据
    // search
    const [complaintTypePercentageDates, setComplaintTypePercentageDates] = useState<RangeValue<dayjs.Dayjs>>([dayjs().add(-5, 'months'), dayjs()]);
    const handleComplaintTypePercentageMonthChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setComplaintTypePercentageDates([dates[0], dates[1]]);
        }
    }
    // plot
    const [complaintTypePercentagePlot, setComplaintTypePercentagePlot] = useState<Pie>();
    useEffect(() => {
        if (complaintTypePercentagePlot) complaintTypePercentagePlot.render();
    }, [complaintTypePercentagePlot]);
    // api
    const loadComplaintTypePercentageData = async () => {
        let complaintTypePercentageRes = await getComplaintTypePercentageData({
            begin_month: complaintTypePercentageDates![0]!.format('YYYY-MM'),
            end_month: complaintTypePercentageDates![1]!.format('YYYY-MM')
        });
        if (complaintTypePercentagePlot) {
            let data: Record<string, any>[] = [];
            complaintTypePercentageRes.data.list.forEach((item) => {
                data.push({
                    count: item.count,
                    name: item.name
                });
            })

            complaintTypePercentagePlot.update({
                data
            });
        }
    }
    useEffect(() => {
        if (complaintTypePercentagePlot) loadComplaintTypePercentageData();
    }, [complaintTypePercentageDates, complaintTypePercentagePlot])

    // 时间反馈情况数据
    // plot
    const [timeFeedbackPlot, setTimeFeedbackPlot] = useState<Column>();
    useEffect(() => {
        if (timeFeedbackPlot) timeFeedbackPlot.render();
    }, [timeFeedbackPlot]);
    // api
    const loadTimeFeedbackData = async () => {
        let timeFeedbackRes = await getTimeFeedbackData();
        if (timeFeedbackPlot) {
            let data: Record<string, any>[] = [];
            timeFeedbackRes.data.list.forEach((item) => {
                data.push({
                    '反馈时间': item.day,
                    name: item.name
                });
            })

            timeFeedbackPlot.update({
                data
            });
        }
    }
    useEffect(() => {
        if (timeFeedbackPlot) loadTimeFeedbackData();
    }, [timeFeedbackPlot])


    // 裁前调解成功率
    // search
    const [preArbitrationMediationDates, setPreArbitrationMediationDates] = useState<RangeValue<dayjs.Dayjs>>([dayjs().add(-5, 'months'), dayjs()]);
    const handlePreArbitrationMediationMonthChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setPreArbitrationMediationDates([dates[0], dates[1]]);
        }
    }
    // plot
    const [preArbitrationMediationPlot, setPreArbitrationMediationPlot] = useState<Column>();
    useEffect(() => {
        if (preArbitrationMediationPlot) preArbitrationMediationPlot.render();
    }, [preArbitrationMediationPlot]);
    // api
    const loadPreArbitrationMediationData = async () => {
        let preArbitrationMediationRes = await getPreArbitrationMediationData({
            begin_month: preArbitrationMediationDates![0]!.format('YYYY-MM'),
            end_month: preArbitrationMediationDates![1]!.format('YYYY-MM')
        });
        if (preArbitrationMediationPlot) {
            let data: Record<string, any>[] = [];
            preArbitrationMediationRes.data.list.forEach((item) => {
                data.push({
                    month: item.month,
                    count: item.fail_count,
                    type: '调解失败'
                });
                data.push({
                    month: item.month,
                    count: item.success_count,
                    type: '调解成功'
                });
            })

            preArbitrationMediationPlot.update({
                data
            });
        }
    }
    useEffect(() => {
        if (preArbitrationMediationPlot) loadPreArbitrationMediationData();
    }, [preArbitrationMediationDates, preArbitrationMediationPlot])


    // 裁前投诉类型
    // search
    const [preArbitrationTypePercentageDates, setPreArbitrationTypePercentageDates] = useState<RangeValue<dayjs.Dayjs>>([dayjs().add(-5, 'months'), dayjs()]);
    const handlePreArbitrationTypePercentageMonthChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setPreArbitrationTypePercentageDates([dates[0], dates[1]]);
        }
    }
    // plot
    const [preArbitrationTypePercentagePlot, setPreArbitrationTypePercentagePlot] = useState<Pie>();
    useEffect(() => {
        if (preArbitrationTypePercentagePlot) preArbitrationTypePercentagePlot.render();
    }, [preArbitrationTypePercentagePlot]);
    // api
    const loadPreArbitrationTypePercentageData = async () => {
        let preArbitrationTypePercentageRes = await getPreArbitrationTypePercentageData({
            begin_month: preArbitrationTypePercentageDates![0]!.format('YYYY-MM'),
            end_month: preArbitrationTypePercentageDates![1]!.format('YYYY-MM')
        });
        if (preArbitrationTypePercentagePlot) {
            let data: Record<string, any>[] = [];
            preArbitrationTypePercentageRes.data.list.forEach((item) => {
                data.push({
                    count: item.count,
                    name: item.name
                });
            })

            preArbitrationTypePercentagePlot.update({
                data
            });
        }
    }
    useEffect(() => {
        if (preArbitrationTypePercentagePlot) loadPreArbitrationTypePercentageData();
    }, [preArbitrationTypePercentageDates, preArbitrationTypePercentagePlot])

    // 初始化图标
    const loadPlot = (auth: boolean[]) => {
        // 地区调解数据
        if (auth[0]) {
            setAreaMediationPlot(new Column('area', {
                data: [],
                isStack: true,
                xField: 'month',
                yField: 'count',
                seriesField: 'type',
                label: {
                    // 可手动配置 label 数据标签位置
                    position: 'middle', // 'top', 'bottom', 'middle'
                    // 可配置附加的布局方法
                    layout: [
                        // 柱形图数据标签位置自动调整
                        { type: 'interval-adjust-position' },
                        // 数据标签防遮挡
                        { type: 'interval-hide-overlap' },
                        // 数据标签文颜色自动调整
                        { type: 'adjust-color' },
                    ],
                },
            }));
        }

        // 投诉类型百分比
        if (auth[1]) {
            setComplaintTypePercentagePlot(new Pie('complaint', {
                appendPadding: 10,
                data: [],
                angleField: 'count',
                colorField: 'name',
                radius: 0.75,
                legend: false,
                label: {
                    type: 'spider',
                    labelHeight: 40,
                    formatter: (data, mappingData) => {
                        const group = new G.Group({});
                        group.addShape({
                            type: 'circle',
                            attrs: {
                                x: 0,
                                y: 0,
                                width: 40,
                                height: 50,
                                r: 5,
                                fill: mappingData.color
                            },
                        });
                        group.addShape({
                            type: 'text',
                            attrs: {
                                x: 10,
                                y: 8,
                                text: `${data.name}`,
                                fill: mappingData.color,
                            },
                        });
                        group.addShape({
                            type: 'text',
                            attrs: {
                                x: 0,
                                y: 25,
                                text: `${data.count}件 ${(data.percent * 100).toFixed(2)}%`,
                                fill: 'rgba(0, 0, 0, 0.65)',
                                fontWeight: 700,
                            },
                        });
                        return group;
                    },
                },
                interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
            }));
        }

        // 多地区案件数量
        if (auth[2]) {
            setMultiAreaMediationCountPlot(new Column('multi', {
                data: [],
                isGroup: true,
                xField: '月份',
                yField: '数量',
                seriesField: 'name',
                /** 设置颜色 */
                //color: ['#1ca9e6', '#f88c24'],
                /** 设置间距 */
                // marginRatio: 0.1,
                label: {
                    // 可手动配置 label 数据标签位置
                    position: 'middle', // 'top', 'middle', 'bottom'
                    // 可配置附加的布局方法
                    layout: [
                        // 柱形图数据标签位置自动调整
                        { type: 'interval-adjust-position' },
                        // 数据标签防遮挡
                        { type: 'interval-hide-overlap' },
                        // 数据标签文颜色自动调整
                        { type: 'adjust-color' },
                    ],
                },
            }));
        }

        // 时间反馈情况
        if (auth[3]) {
            setTimeFeedbackPlot(new Column('feedback', {
                data: [],
                xField: 'name',
                yField: '反馈时间',
                label: {
                    // 可手动配置 label 数据标签位置
                    position: 'middle', // 'top', 'bottom', 'middle',
                    // 配置样式
                    style: {
                        fill: '#FFFFFF',
                        opacity: 0.6,
                    },
                },
                tooltip: {
                    formatter: (datum) => ({
                        name: datum.name,
                        value: `${datum['反馈时间']}天`
                    }),
                },
                xAxis: {
                    label: {
                        autoHide: true,
                        autoRotate: false,
                    },
                },
                meta: {
                    type: {
                        alias: '类别',
                    },
                    sales: {
                        alias: '销售额',
                    },
                },
            }));
        }

        // 裁前调解成功率
        if (auth[4]) {
            setPreArbitrationMediationPlot(new Column('preArbitrationMediation', {
                data: [],
                isStack: true,
                xField: 'month',
                yField: 'count',
                seriesField: 'type',
                label: {
                    // 可手动配置 label 数据标签位置
                    position: 'middle', // 'top', 'bottom', 'middle'
                    // 可配置附加的布局方法
                    layout: [
                        // 柱形图数据标签位置自动调整
                        { type: 'interval-adjust-position' },
                        // 数据标签防遮挡
                        { type: 'interval-hide-overlap' },
                        // 数据标签文颜色自动调整
                        { type: 'adjust-color' },
                    ],
                },
            }));
        }

        // 裁前投诉类型
        if (auth[5]) {
            setPreArbitrationTypePercentagePlot(new Pie('PreArbitrationType', {
                appendPadding: 10,
                data: [],
                angleField: 'count',
                colorField: 'name',
                radius: 0.75,
                legend: false,
                label: {
                    type: 'spider',
                    labelHeight: 40,
                    formatter: (data, mappingData) => {
                        const group = new G.Group({});
                        group.addShape({
                            type: 'circle',
                            attrs: {
                                x: 0,
                                y: 0,
                                width: 40,
                                height: 50,
                                r: 5,
                                fill: mappingData.color
                            },
                        });
                        group.addShape({
                            type: 'text',
                            attrs: {
                                x: 10,
                                y: 8,
                                text: `${data.name}`,
                                fill: mappingData.color,
                            },
                        });
                        group.addShape({
                            type: 'text',
                            attrs: {
                                x: 0,
                                y: 25,
                                text: `${data.count}件 ${(data.percent * 100).toFixed(2)}%`,
                                fill: 'rgba(0, 0, 0, 0.65)',
                                fontWeight: 700,
                            },
                        });
                        return group;
                    },
                },
                interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
            }));
        }
    }

    // 搜索表单
    const [form, setForm] = useState<IRegionStatListSearchForm>({
        page: 1,
        size: 10,
        begin_month: dayjs().format('YYYY-MM'),
        end_month: dayjs().format('YYYY-MM'),
    });


    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<(IRegionStatListData)[]>([]);

    const [total, setTotal] = useState(0);
    const loadData = async (newForm?: IRegionStatListSearchForm) => {
        let tempForm = newForm ?? form;
        setLoading(true);
        try {
            let res = await getRegionStatList(tempForm);
            setTotal(res.data.total);
            setListData((listData) => {
                let newListData = [...listData];
                // 初始化
                if (newListData.length !== res.data.total) {
                    newListData = new Array(res.data.total);
                    console.log('初始化', newListData);
                }
                // 更新局部数据
                newListData.splice((tempForm.page! - 1) * tempForm.size, res.data.list.length, ...res.data.list);
                console.log('结果', newListData);
                return newListData;
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    // 分页
    const onPageChange = (page: number, pageSize: number) => {
        console.log(page, pageSize);
        let newForm = {
            ...form,
            page,
            size: pageSize,
        };
        setForm(newForm);
        loadData(newForm);
    }

    // 月份筛选
    const disabledRegionDate = (current: Dayjs) => {
        // Can not select days before today and today
        return current && current > dayjs().endOf('month');
    };
    const handleMonthChange = (value: dayjs.Dayjs | null, dateString: string) => {
        console.log(`date: ${value}`);
        let newForm = {
            ...form,
            page: 1,
            month: value!.format('YYYY-MM')
        };
        setForm(newForm)
        loadData(newForm);
    }
    const handleReceiveChange = (_: any, formatString: [string, string]) => {
        if (formatString[0] && formatString[1]) {
            setForm((form) => {
                let newForm = {
                    ...form,
                    page: 1,
                    begin_month: formatString[0],
                    end_month: formatString[1],
                };
                loadData(newForm);
                return newForm;
            });
        }
    }

    return (
        <div className='address inner-page statistic' style={{ background: '#fcfcfc' }}>
            <Card bordered={false} style={{ paddingBottom: 20 }}>
                <h2>裁前调解统计</h2>
                <div className='search'>
                    <RangePicker
                        picker="month"
                        disabledDate={disabledRegionDate}
                        className="search__item"
                        style={{ 'width': '300px' }}
                        value={[
                            form.begin_month && form.begin_month ? dayjs(form.begin_month) : null,
                            form.end_month && form.end_month ? dayjs(form.end_month) : null,
                        ]}
                        onChange={handleReceiveChange}
                        format='YYYY-MM'
                        placeholder={['开始年月', '结束年月']}
                        allowClear={false}
                    />
                </div>
                <Table
                    dataSource={listData}
                    size='small'
                    loading={loading}
                    scroll={{ x: 800 }}
                    pagination={{
                        position: ['bottomRight'],
                        size: "small",
                        total: total,
                        showTotal: total => `共 ${total} 项`,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange: onPageChange
                    }}>
                    <Table.Column title="街镇" dataIndex="name" key="name" />
                    <Table.Column title="接案数量（宗）" dataIndex="total_count" key="total_count" />
                    <Table.Column title="受理案件数" dataIndex="acceptance_count" key="acceptance_count" />
                    <Table.Column title="拒绝调解数" dataIndex="refuse_count" key="refuse_count" />
                    <Table.Column title="办结案件（宗）" dataIndex="conclude_count" key="conclude_count" />
                    <Table.Column title="调解成功（宗）" dataIndex="success_count" key="success_count" />
                    <Table.Column title="成功率（%）" dataIndex="success_percent" key="success_percent" />
                    <Table.Column title="为职工挽回经济损失（万元）" dataIndex="involved_amount_total" key="involved_amount_total" />
                    <Table.Column title="涉案金额（万元）" dataIndex="lawsuit_money_total" key="lawsuit_money_total" />
                </Table>
            </Card>

            {
                hasPreWorksheetStatAuth &&
                <Card bordered={false} style={{ paddingBottom: 20 }}>
                    <h2>地区统计拒绝分析</h2>
                    <PreArbitrationRefuseAnalysisListModal />
                </Card>
            }

            <div style={{ display: hasChartPlate1Auth ? 'block' : 'none' }}>
                <Card bordered={false} style={{ paddingBottom: 20 }}>
                    <h2>电话调解成功率</h2>
                    <RangePicker
                        picker="month"
                        ranges={rangePresets}
                        value={areaMediationDates}
                        onChange={handleAreaMediationMonthChange}
                        disabledDate={disabledDate}
                        onCalendarChange={(val) => setAreaMediationDates(val)}
                        style={{ marginBottom: 20 }}
                        allowClear={false}
                    />
                    <div id='area'></div>
                </Card>
            </div>

            <div style={{ display: hasChartPlate3Auth ? 'block' : 'none' }}>
                <Card bordered={false} style={{ paddingBottom: 20 }}>
                    <h2>电话调解多地对比</h2>
                    <RangePicker
                        picker="month"
                        ranges={rangePresets}
                        value={multiAreaMediationCountDates}
                        onChange={handleMultiAreaMediationCountMonthChange}
                        disabledDate={disabledDate}
                        onCalendarChange={(val) => setMultiAreaMediationCountDates(val)}
                        style={{ marginBottom: 20 }}
                        allowClear={false}
                    />
                    <div id='multi'></div>
                </Card>
            </div>

            <div style={{ display: hasChartPlate2Auth ? 'block' : 'none' }}>
                <Card bordered={false} style={{ paddingBottom: 20 }}>
                    <h2>电话调解投诉类型</h2>
                    <RangePicker
                        picker="month"
                        ranges={rangePresets}
                        value={complaintTypePercentageDates}
                        onChange={handleComplaintTypePercentageMonthChange}
                        disabledDate={disabledDate}
                        onCalendarChange={(val) => setComplaintTypePercentageDates(val)}
                        style={{ marginBottom: 20 }}
                        allowClear={false}
                    />
                    <div id='complaint'></div>
                </Card>
            </div>

            <div style={{ display: hasChartPlate4Auth ? 'block' : 'none' }}>
                <Card bordered={false} style={{ paddingBottom: 20 }}>
                    <h2>电话调解反馈时间</h2>
                    <div id='feedback'></div>
                </Card>
            </div>

            <div style={{ display: hasChartPlate5Auth ? 'block' : 'none' }}>
                <Card bordered={false} style={{ paddingBottom: 20 }}>
                    <h2>裁前调解成功率</h2>
                    <RangePicker
                        picker="month"
                        ranges={rangePresets}
                        value={preArbitrationMediationDates}
                        onChange={handlePreArbitrationMediationMonthChange}
                        disabledDate={disabledDate}
                        onCalendarChange={(val) => setPreArbitrationMediationDates(val)}
                        style={{ marginBottom: 20 }}
                        allowClear={false}
                    />
                    <div id='preArbitrationMediation'></div>
                </Card>
            </div>

            <div style={{ display: hasChartPlate6Auth ? 'block' : 'none' }}>
                <Card bordered={false} style={{ paddingBottom: 20 }}>
                    <h2>裁前投诉类型</h2>
                    <RangePicker
                        picker="month"
                        ranges={rangePresets}
                        value={preArbitrationTypePercentageDates}
                        onChange={handlePreArbitrationTypePercentageMonthChange}
                        disabledDate={disabledDate}
                        onCalendarChange={(val) => setPreArbitrationTypePercentageDates(val)}
                        style={{ marginBottom: 20 }}
                        allowClear={false}
                    />
                    <div id='PreArbitrationType'></div>
                </Card>
            </div>


        </div>
    );
}

export default OrderCockpit;
