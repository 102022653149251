// antd
import { Dropdown, Menu, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// style
import './shortcutAccess.scss'

// router
import { Link, useNavigate } from 'react-router-dom';

// icons
import accountIcon from '@assets/icons/mine/account.svg';
import logoutIcon from '@assets/icons/mine/logout.svg';
import settingsIcon from '@assets/icons/mine/common.svg';

// assets
import { AvatarImage } from '../image/avatar';
import { useEffect, useState } from 'react';
import AvatarImg from '@assets/icons/default_avatar.svg';

// 确认弹框
const { confirm } = Modal;

const dropdownItemStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px'
}

function ShortcutAccess() {
    // 路由
    const navigate = useNavigate();

    const [account, setAccount] = useState<string>();
    useEffect(() => {
        const loadUserInfo = () => {
            const user = localStorage.getItem('account');
            if (user) setAccount(user);
        }
        loadUserInfo();
        let timer = setInterval(loadUserInfo, 5000);

        return () => {
            clearInterval(timer);
        }
    }, [])


    const logout = () => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '确定要退出登录吗？',
            onOk() {
                localStorage.removeItem('token');
                localStorage.removeItem('account');
                navigate("/login", { replace: true });
            },
        });
    }

    const menu = (
        <Menu
            items={[
                {
                    key: 'password',
                    label: (
                        <Link to='/password' style={dropdownItemStyle}>
                            <img src={settingsIcon} alt="修改密码" />
                            <div style={{ marginLeft: '10px' }}>修改密码</div>
                        </Link>
                    ),
                },
                {
                    key: 'exit',
                    label: (
                        <a style={dropdownItemStyle} onClick={logout}>
                            <img src={logoutIcon} alt="退出登录" />
                            <div style={{ marginLeft: '10px' }}>退出登录</div>
                        </a>
                    ),
                },
            ]}
        />
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a className='shortcut'>
                <div className='shortcut__name'>{account ?? '未登录'}</div>
                <img width={40} src={AvatarImg} alt="头像" />
            </a>
        </Dropdown>
    );
}

export default ShortcutAccess;