import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// antd
import { Grid, Button, Form, message, Skeleton, Cascader } from 'antd';
import DatePicker from '../../../components/customAntd/DatePicker';
import { DatePicker as DatePickerMobile } from 'antd-mobile';

import { uploadFile } from '../../../apis/common';
import dayjs, { Dayjs } from 'dayjs';

import { Cascader as CascaderMobile, CascaderOption, ImageUploader, ImageUploadItem, Popup } from 'antd-mobile';

import { getPreArbitrationRegionCaseRule, getPreArbitrationSelectRegion, IAttachFilesData, INaturalPeople, IPreArbitrationCompany, IPostponeFileForm, IPreArbitrationRegionCaseRuleForm, IPreArbitrationSelectRegionData, IThirdPartyCompany, IThirdPartyNaturalPeople, savePreArbitrationForm, savePostponeFile, getPreArbitrationAttachmentTag } from '../../../apis/preArbitration';
import { PickerDate } from 'antd-mobile/es/components/date-picker/util';

const { useBreakpoint } = Grid;

interface CollectionCreateFormProps {
    data?: { id: number },
    onFinish?: () => void;
}

function PreArbitrationPostponeFormModal({
    data,
    onFinish,
}: CollectionCreateFormProps) {
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // 加载状态
    const [confirmLoading, setConfirmLoading] = useState(false);

    // 表单数据
    const [form] = Form.useForm<IPostponeFileForm>();

    // 延期时间
    const [receiveAtVisible, setReceiveAtVisible] = useState(false);

    // 初始化加载
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.resetFields();
        let pre_worksheet_id = data?.id ?? Number(searchParams.get('id'));
        form.setFieldValue('pre_worksheet_id', pre_worksheet_id);

        getPreArbitrationAttachmentTag({ pre_worksheet_id }).then(res => {
            form.setFieldValue('postpone_attach_files_data', res.data.postpone_attachment_tags_data?.map(item => ({
                ...item,
                files: []
            })) ?? []);
        });
    }, [data, searchParams]);


    // 上传附件
    const [fileListMap, setFileListMap] = useState<Record<number, ImageUploadItem[]>>({});
    const beforeUpload = (file: File) => {
        if (file.size > 1024 * 1024 * 50) {
            message.warning('请选择小于 50M 的图片');
            return null;
        }
        return file;
    }
    const imageUploaderUpload = (name: string, value: number) => {
        return async (file: File) => {
            let res = await uploadFile({ file: file as Blob, type: 'pre_worksheet' });
            return {
                key: res.data.file_id,
                extra: {
                    name,
                    value,
                    data: res.data
                },
                url: res.data.full_link,
            };
        }
    };
    const onImageUploaderChange = (value: number) => {
        return (items: ImageUploadItem[]) => {
            let attachFilesData: IAttachFilesData[] = form.getFieldValue('postpone_attach_files_data');
            attachFilesData.find(item => item.value === value)!.files = items.map(item => item.key as number);
            form.setFieldValue('postpone_attach_files_data', attachFilesData);
            setFileListMap(data => {
                data[value] = items;
                return data;
            });
        };
    };

    // 提交
    const submit = async () => {
        try {
            await form.validateFields();
        } catch (e: any) {
            message.warning(e.errorFields[0].errors[0]);
            return;
        }

        let _form: IPostponeFileForm = form.getFieldsValue(true);
        if (!_form.expire_at) {
            message.warning('请填写延期时间');
            return;
        }

        setConfirmLoading(true);
        try {
            let data = {
                ..._form,
                expire_at: (_form.expire_at as unknown as Dayjs).format('YYYY-MM-DD'),
            }

            let res = await savePostponeFile(data);
            message.success(res.msg);
            if (onFinish) {
                onFinish();
            } else {
                navigate('/preArbitration');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    };

    const FormItem = (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            {
                screens.xs ?
                    <Form.Item
                        label="延期时间"
                        required
                        shouldUpdate={(prevValues, curValues) => prevValues.expire_at !== curValues.expire_at}
                    >
                        <DatePickerMobile
                            visible={receiveAtVisible}
                            onClose={() => {
                                setReceiveAtVisible(false)
                            }}
                            value={(form.getFieldValue('expire_at') as Dayjs)?.toDate()}
                            onConfirm={(value: PickerDate) => {
                                form.setFieldValue('expire_at', dayjs(value));
                            }}
                        >
                            {value =>
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => {
                                            setReceiveAtVisible(true)
                                        }}
                                    >选择</Button>
                                    {value ? dayjs(value).format('YYYY年MM月DD日') : '请选择时间'}
                                </>
                            }
                        </DatePickerMobile>
                    </Form.Item>
                    : <Form.Item
                        label="延期时间"
                        name="expire_at"
                        rules={[{ required: true, message: '请选择延期时间' }]}
                    >
                        <DatePicker
                            format='YYYY-MM-DD'
                        />
                    </Form.Item>
            }

            <Form.Item
                noStyle
                shouldUpdate
            >
                {({ getFieldValue }) => {
                    let attachFilesData: IAttachFilesData[] = getFieldValue('postpone_attach_files_data') ?? [];
                    return (
                        <>
                            {
                                attachFilesData.map(item => (
                                    <Form.Item
                                        label={item.name}
                                    >
                                        <ImageUploader
                                            multiple
                                            value={fileListMap[item.value] ?? []}
                                            onChange={onImageUploaderChange(item.value)}
                                            upload={imageUploaderUpload(item.name, item.value)}
                                            beforeUpload={beforeUpload}
                                        />
                                    </Form.Item>
                                ))
                            }
                        </>
                    );
                }}
            </Form.Item>

            <Form.Item>
                <Button block type='primary' size='large' loading={confirmLoading} onClick={submit}>提交</Button>
            </Form.Item>
        </Form>
    )


    return (
        <div className={screens.xs ? 'inner-page mobile' : ''}>
            {
                loading
                    ? <Skeleton />
                    : FormItem
            }
        </div>
    );
}

export default PreArbitrationPostponeFormModal;