import { useEffect, useRef } from "react";
import { getAuthData, IAuthNode } from "../apis/common";

/// 获取用户权限
export const useHasAuth = async (fields: string[]): Promise<boolean[]> => {
    // effect调用引用
    const fieldsRef = useRef(fields);

    // DFS查找
    // 规定：子节点字段前缀是父节点字段，如：roles -> roles_add
    const search = (forest: IAuthNode[], finalNode: string): boolean => {
        let stack: IAuthNode[] = [];
        stack.push(...forest);

        while (stack.length > 0) {
            let item = stack.pop();
            if (item!.sign === finalNode) {
                // 找到返回
                return true;
            } else if (RegExp(`^${item!.sign}`).test(finalNode)) {
                // 优化：只有符合规定（含有前缀）的父节点的子节点入栈，减少搜索量
                stack = [...item!.children];
            }
        }

        // 无结果
        return false;
    };

    // promise订阅
    const completeRef = useRef<(value: boolean[] | PromiseLike<boolean[]>) => void>();
    let completer = new Promise<boolean[]>((resolve) => {
        completeRef.current = resolve;
    })

    useEffect(() => {
        // 获取数据
        getAuthData().then(res => {
            let auth = res.data.list;

            console.log('获取权限树：', res.data);
            // 获取结果
            let authRes = fieldsRef.current.map(field => search(auth.node, field));
            // 订阅通知完成
            // completeRef.current在Promise实例化时已经同步赋值，这里可以放心直接使用
            completeRef.current!(authRes);
        });
    }, []);

    // 订阅promise结果
    return await completer;
}

/// 权限字典
export const globalAuthSigns = {
    worksheet: 'worksheet', // 业务管理
    worksheet_import: 'worksheet_import', // 导入
    worksheet_importZS: 'worksheet_importZS', // 中山市长热线导入
    worksheet_iocExport: 'worksheet_iocExport', // 导出IOC工单压缩包
    worksheet_iocExportReport: 'worksheet_iocExportReport', // IOC结果报告压缩
    
    chart: 'chart', // 数据管理
    chart_plate1: 'chart_plate1', // 地区调解数据
    chart_plate2: 'chart_plate2', // 投诉类型百分比
    chart_plate3: 'chart_plate3', // 多地区案件数量
    chart_plate4: 'chart_plate4', // 时间反馈情况
    chart_plate5: 'chart_plate5', // 裁前调解成功率
    chart_plate6: 'chart_plate6', // 裁前投诉类型

    preWorksheet: 'preWorksheet', // 裁前管理
    preWorksheet_add: 'preWorksheet_add', // 登记
    preWorksheet_form: 'preWorksheet_form', // 填写表单
    preWorksheet_import: 'preWorksheet_import', // 导入
    preWorksheet_exportZip: 'preWorksheet_exportZip', // 导出
    preWorksheet_attachment: 'preWorksheet_attachment', // 补充附件
    preWorksheet_stat: 'preWorksheet_stat', // 统计管理

    approval: 'approval', // 审批管理
}