import { Link, useLocation } from 'react-router-dom';

// antd
import { Badge } from 'antd';

// assets
import logo from '@assets/logo.svg';
import indexIcon from "@assets/navigator/index.svg";
import addressIcon from "@assets/navigator/address.svg";
import functionIcon from "@assets/navigator/function.svg";
// import mineIcon from "@assets/navigator/mine.svg";
import messageIcon from "@assets/navigator/message.svg";

// scss
import './navigator.scss';
import { globalAuthSigns, useHasAuth } from '../../utils/auth';
import { useEffect, useState } from 'react';

/**
 * 侧边导航条组件
 */
function NavigatorBar() {

    // 获取当前路径并激活对应模块
    const location = useLocation();
    const path = `/${location.pathname.split('/')[1]}`;
    console.log(`当前路径: ${path}`);

    // 权限检查
    const authRes = useHasAuth([
        globalAuthSigns.worksheet,
        globalAuthSigns.chart,
        globalAuthSigns.preWorksheet,
        globalAuthSigns.approval,
    ]);
    useEffect(() => {
        authRes.then(value => {
            setNavList([
                {
                    name: '电话业务',
                    path: '/order',
                    icon: functionIcon,
                    display: value[0],
                    badge: 0
                },
                {
                    name: '数据管理',
                    path: '/data',
                    icon: messageIcon,
                    display: value[1],
                    badge: 0
                },
                {
                    name: '裁前管理',
                    path: '/preArbitration',
                    icon: addressIcon,
                    display: value[2],
                    badge: 0
                },
                {
                    name: '审核管理',
                    path: '/approval',
                    icon: functionIcon,
                    display: value[3],
                    badge: 0
                },
                // {
                //     name: '报表管理',
                //     path: '/charts',
                //     icon: addressIcon,
                //     display: false,
                //     badge: 0
                // },
            ]);
        })
    }, [authRes]);

    // 导航栏
    const [navList, setNavList] = useState<{
        name: string;
        path: string;
        icon: string;
        display: boolean;
        badge: number;
    }[]>([]);

    return (
        <div className='navigator'>
            <div className='inner'>
                {/* logo */}
                <div className='inner__logo-box'>
                    <img className='navigator__logo' src={logo} alt="logo" />
                    <span className='navigator__title'>南海区劳动争议调解分单管理平台（政府端）</span>
                </div>

                {/* 列表 */}
                <div className='navigator__list'>{
                    navList.filter(e => e.display).map(e => {
                        return (
                            <Link className={`navigator__list__item ${path === e.path ? 'active' : ''}`} to={e.path} key={e.path}>
                                <Badge offset={[-10, 10]} count={e.badge} overflowCount={99}>
                                    <img className='navigator__list__item__img' src={e.icon} alt={e.name} />
                                </Badge>
                                <div className='navigator__list__item__title'>{e.name}</div>
                            </Link>
                        )
                    })
                }</div>
            </div>
        </div>
    );
}
export default NavigatorBar;